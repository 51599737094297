// Chakra React Imports
import React from "react";
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor } from "../../../../constants";

function TabOneSubHelper() {
	return (
		<Tabs w="100%" h="100%" borderBottom={"0px"} mx="auto">
			<TabList border="0px" w="100%" h="8%">
				<Tab
					_selected={{
						color: defaultThemeColor,
						fontWeight: 600,
						borderBottomColor: defaultThemeColor,
					}}
					fontSize="16px"
					px="6"
					py="2"
				>
					Admin Content
				</Tab>
				<Tab
					_selected={{
						color: defaultThemeColor,
						fontWeight: 600,
						borderBottomColor: defaultThemeColor,
					}}
					fontSize="16px"
					px="6"
					py="2"
				>
					Team Content 
				</Tab>
			</TabList>
			<TabPanels mt="4%" h="91%">
				<TabPanel p="0" h="100%">
					<Text ml={5}>
					<ul>
						<li>Update Personal Profile</li>
						<li>Update Business Profile</li>
						<li>Team Roles and Configuration</li>
						<li>Add & Manage Team Members</li>
						<li>Add Your Business Customers</li>
						<li>Add LingoCard and Assign to Customers</li>
						<li>Add LingoLink and Assign to Customers</li>
						<li>Using LingoLink</li>
						<li>Using DocumentAI</li>
					</ul>
					<ul>
						<li>1. Difference between Premium and Basic AI </li>
					</ul>
					</Text>
				</TabPanel>
				<TabPanel p="0" h="100%">
					<Text ml={5}>
					<ul>
						<li>Contact Team Members</li>
						<li>Edit Business Customers</li>
						<li>Assign Card to Customers</li>
						<li>Assign LingoLink to Customers</li>
						<li>LingoLink Management</li>
						<li>Using DocumentAI</li>
					</ul>
					</Text>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
}

export default TabOneSubHelper;
