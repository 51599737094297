import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { MdUpload } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Button,
	chakra,
	shouldForwardProp,
	Spacer,
	Spinner,
	Checkbox,
	Text,
	Flex,
	Box,
	Input,
	Stack,
	Icon,
	FormControl,
	useToast,
	useColorModeValue,
} from "@chakra-ui/react";
import {
	AiOutlineStop,
	AiFillAudio,
	AiOutlineClockCircle,
	AiOutlineDownCircle,
	AiFillClockCircle,
} from "react-icons/ai";

import { useReactMediaRecorder } from "react-media-recorder";
import axios from "../../../../../services/axios";
import Card from "../../../../../components/card/Card.js";
import InputField from "../../../../../components/fields/InputField";
import Multiselect from "multiselect-react-dropdown";
import StopWatch from "../../../../../utils/FullTimer.js";
import { motion, isValidMotionProp } from "framer-motion";

import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { getAudioContentUrl, addNewContentWorkflow } from "../../../../../services/moduleServices.js";
import { transcriptionLanguages } from "../../../../../utils/TranscriptionLanguage.js";
import { translationLanguages } from "../../../../../utils/TranslationLanguages";

//
// https://stackoverflow.com/questions/73712274/how-to-upload-a-recorded-audioblob-to-server-reactjs
//

export default function UploadAudio(props) {
	const navigate = useNavigate();
	const { state } = useLocation();
	const dispatch = useDispatch();
	const toast = useToast();

	const projectUuid = props.projectUuid ? props.projectUuid : null;
	const [contentUuid, setContentUuid] = useState(null);

	const { used, total, ...rest } = props;
	const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
	const brandColor = useColorModeValue("brand.500", "white");
	const textColorSecondary = "gray.400";

	const [loading, isLoading] = useState(true);

	const [showRecorder, isShowRecorder] = useState(true);
	const [audioFileName, setAudioFileName] = useState(null);
	const [audioDetails, setAudioDetails] = useState(null);
	const [uploadStatus, setUploadStatus] = useState();
	const [uploadStatusLoading, setUploadStatusLoading] = useState(false);
	const [contentTitle, setContentTitle] = useState(null);
	const [sourceLanguage, setSourceLanguage] = useState([]);
	const [srcLanguagesData, setSrcLanguagesData] = useState([]);
	const [allLanguages, setAllLanguages] = useState([]);
	const [allLanguagesData, setAllLanguagesData] = useState([]);
	const [transTargetLangs, setTransTargetLangs] = useState(false);
	const [proofreadingAdded, isProofreadingAdded] = useState(false);
	const [resultResponseAdded, isResultResponseAdded] = useState(false);

	const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = useReactMediaRecorder({
		audio: true,
		blobPropertyBag: { type: "audio/mpeg" }, ///audio/mp3
	});

	const [timer, setTimer] = useState(0);
	const [storedTimer, setStoredTimer] = useState(0);
	const [isRunning, setIsRunning] = useState(false);

	let timeInterval = useRef(null);

	const handleTimerStart = () => {
		if (isRunning) return;
		setIsRunning(true);
		timeInterval.current = setInterval(() => {
			setTimer(prev => prev + 1);
		}, 1000);
	};

	const handleTimerPause = () => {
		if (!isRunning) return;
		setIsRunning(false);
		clearInterval(timeInterval.current);
	};

	const handleTimerReset = () => {
		setIsRunning(false);
		clearInterval(timeInterval.current);
		setTimer(0);
	};

	const formatTime = timer => {
		const minutes = Math.floor(timer / 60000)
			.toString()
			.padStart(2, "0");
		const seconds = Math.floor((timer / 1000) % 60)
			.toString()
			.padStart(2, "0");
		// const milliseconds = (timer % 1000).toString().padStart(3, "0");
		return { minutes, seconds }; //, milliseconds };
	};

	const { minutes, seconds } = formatTime(timer); // milliseconds

	const ChakraBox = chakra(motion.div, {
		/**
		 * Allow motion props and non-Chakra props to be forwarded.
		 */
		shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
	});

	function postObjectData(s3Data, selectedFile, contentId) {
		// dispatch(setProfileLogoFlag(false));
		const { url, fields } = s3Data;
		const payload = new FormData();

		// add all the other fields
		Object.entries(fields).forEach(([key, val]) => {
			payload.append(key, val);
		});
		// payload.append("Content-Type", "image/jpeg");
		// This must be the last line in the payload otherwise 412 error may occur
		// this is the file blob, eg from <input type="file">
		payload.append("file", selectedFile);

		// No need to manually set content-type header, your browser knows what to do
		// const { data: result } = axios.post(url, payload);
		// https://stackoverflow.com/questions/46656474/axios-remove-headers-authorization-in-1-call-only
		var ax_instance = axios.create();
		delete ax_instance.defaults.headers.common["Authorization"];
		const { data: result } = ax_instance
			.post(url, payload)
			.then(res => {
				isLoading(false);
				if (res?.data?.length > 0) {
					setUploadStatus("File is uploaded successfully..");
					createNewWorkflow(contentId);
				} else {
					setUploadStatus("File is uploaded successfully...");
					createNewWorkflow(contentId);
				}
			})
			.catch(err => {
				isLoading(false);
				setUploadStatus("Upload failed with Error :" + err);
			});
	}

	const createNewWorkflow = contentId => {
		if (!transTargetLangs) {
			setAllLanguages(["none"]);
		}
		if (sourceLanguage.length > 0) {
			let objData = {
				project_uuid: projectUuid,
				content_uuid: contentId,
				code: "Transcription",
				source_language: sourceLanguage,
				languages: transTargetLangs ? allLanguages : ["none"],
				priority: false, //priortyAdded,
				proofreading: proofreadingAdded,
				attachment: resultResponseAdded,
			};
			isLoading(true);
			addNewContentWorkflow(objData)
				.then(res => {
					isLoading(false);
					if (res?.result && res?.data?.length > 0) {
						if (res?.data[0]?.result === "done") {
							isLoading(false);
							toast(toastFunctionToaster("Workflow is created successfully", "success"));
							isShowRecorder(true);
							//props?.closePanelHandler();
						} else if (res?.data[0]?.result === "dup") {
							//isLoading(false);
							//setErrorMessage("You already have a workflow added with same target languages.");
							toast(
								toastFunctionToaster(
									"You already have a workflow added with same target languages.",
									"warning"
								)
							);
						} else {
							toast(toastFunctionToaster("Unable to complete the workflow", "error"));
						}
					} else {
						toast(toastFunctionToaster("Unable to complete the workflow", "error"));
					}
				})
				.catch(err => {
					isLoading(false);
					toast(toastFunctionToaster("Unable to complete the worflow", "error"));
				});
		}
	};

	const recordedAudioInfo = async () => {
		const audioBlob = await fetch(mediaBlobUrl).then(r => r.blob());
		const audiofile = new File([audioBlob], "audiofile.mp3", {
			type: "audio/mpeg",
		});
		setAudioDetails(audiofile);
	};

	const uploadRecordedAudio = async () => {
		const audioBlob = await fetch(mediaBlobUrl).then(r => r.blob());
		const audiofile = new File([audioBlob], audioFileName, {
			type: "audio/mpeg",
		});
		// const formData = new FormData();
		// formData.append("file", audiofile);
		setUploadStatusLoading(true);
		if (audiofile) {
			let objData = {
				project_uuid: projectUuid,
				content_choice: "audio",
				file_name: audiofile.name,
				file_type: audiofile.type,
			};
			setUploadStatus("Getting upload url for the selected file..");

			const response = await getAudioContentUrl(objData);

			setUploadStatusLoading(false);
			if (response && response.data && response.data.length > 0) {
				if (response?.data[0]?.contentUuid && response?.data[0]?.signedPackage?.signedUrl) {
					setUploadStatusLoading(false);
					setContentUuid(response?.data[0]?.contentUuid);
					const finalUrl = response.data[0].signedPackage.signedUrl;
					setUploadStatus("Trying to upload the selected file..");
					const s3UploadResult = await postObjectData(finalUrl, audiofile, response?.data[0]?.contentUuid);
				} else {
					setUploadStatusLoading(false);
					setUploadStatus("Unable to get content object and upload signed url..");
				}
			} else {
				setUploadStatusLoading(false);
				setUploadStatus("Unable to get the upload signed url..");
			}
		}
	};

	const handleSelect = selectedList => {
		if (selectedList.length) {
			let languagesArr = [];
			selectedList?.map(data => {
				languagesArr?.push(data?.code);
			});
			setAllLanguages(languagesArr);
			setAllLanguagesData(selectedList);
		}
	};

	const handleSingleSelect = selectedList => {
		if (srcLanguagesData.length === 0) {
			if (selectedList.length) {
				let languagesArr = [];
				selectedList?.map(data => {
					languagesArr?.push(data?.code);
				});
				setSourceLanguage(languagesArr);
				setSrcLanguagesData(selectedList);
			}
		} else {
		}
	};

	return (
		<Card {...rest} w={"100%"} mb="20px" align="center" p="20px">
			{projectUuid ? (
				<Flex w={"100%"} p={2} direction={"column"}>
					<Flex w={"100%"}>
						{isRunning && timer > 0 ? (
							<Text>{timer} Seconds of audio collected..</Text>
						) : (
							<Text color={"gray.200"}>{"Recording stopped.."}</Text>
						)}
						{/* {isRunning ? 'yes': 'no'}{" - "}{timer > 0 ? timer : '0'}{" - "}{storedTimer > 0 ? storedTimer : '0'} */}
						<Spacer />
						{/* <Button onClick={clearBlobUrl}>Reset</Button> */}
					</Flex>
					{showRecorder ? (
						<Flex w={"100%"} direction={"column"}>
							<Flex w={"800px"}>
								<audio
									src={mediaBlobUrl}
									controls={true}
									autoPlay={false}
									onStop={recordedAudioInfo}
									loop={false}
								/>
							</Flex>
							<Flex w={"800px"} mt={2}>
								{status != "recording" ? (
									<Button
										bg={"green.100"}
										onClick={() => {
											startRecording();
											// handleTimerResetEx();
											handleTimerStart();
										}}>
										<Icon as={AiFillAudio} mr={1} />
										Start Recording
									</Button>
								) : null}
								{status === "recording" ? (
									<Flex w={"350px"}>
										<Button
											bg={"blue.100"}
											onClick={() => {
												stopRecording();
												setStoredTimer(timer);
												handleTimerReset();
												isShowRecorder(false);
											}}>
											<ChakraBox
												animate={{
													rotate: [
														0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360,
													],
												}}
												transition={{
													duration: 12,
													ease: "easeInOut",
													repeat: Infinity,
													repeatType: "loop",
												}}>
												<Icon mt={1} h={6} w={6} as={AiOutlineClockCircle} />
											</ChakraBox>
											<Text w={"30px"}>{minutes}</Text>
											<Text>{":"}</Text>
											<Text w={"30px"}>{seconds}</Text>
											<Text ml={5}>Click to Stop Recording</Text>
										</Button>
									</Flex>
								) : null}
							</Flex>
							<Flex w={"100%"} mt={2} pl={2}>
								{/* {status === 'stopped' ?<Text>Status: Recording is stopped now...</Text>:null} */}
								{status === "recording" ? (
									<Text>Status: Recording is in progress now, please continue speaking..</Text>
								) : (
									<Text>
										Status: Recording is stopped now. Use "Start Recording" to start recording
									</Text>
								)}
							</Flex>
						</Flex>
					) : null}
					<Flex w={"100%"} mt={2} pl={2}>
						{mediaBlobUrl && !showRecorder ? (
							<Flex w={"100%"} mt={2} direction={"column"}>
								<Flex w={"100%"} rounded={"lg"} borderWidth={"0.5px"} borderColor={"gray.200"} p={2}>
									<Flex
										w={"50%"}
										rounded={"md"}
										direction={"column"}
										borderWidth={"0.5px"}
										borderColor={"gray.200"}
										p={2}>
										<Flex w={"300px"}>
											<Text>Recording:</Text>
											<Text ml={2}>{Math.floor(storedTimer / 60)}</Text>
											<Text>{":"}</Text>
											<Text>{Math.floor(storedTimer % 60)}</Text>
										</Flex>
										<Flex w={"100%"}>
											<InputField
												mb="0"
												me="30px"
												id="audio_file"
												label="Audio File Name"
												value={audioFileName}
												name="audio_file"
												required="true"
												borderRadius="5px"
												onChange={e => {
													setAudioFileName(e.target.value);
												}}
												placeholder="Enter Audio File name"
												w="100%"
												maxlength={100}
											/>
										</Flex>
										<Flex w={"100%"}>
											{audioDetails ? <Text>{JSON.stringify(audioDetails)}</Text> : null}
										</Flex>
									</Flex>
									<Flex
										w={"50%"}
										direction={"column"}
										rounded={"md"}
										borderWidth={"0.5px"}
										borderColor={"gray.200"}
										p={2}>
										<Flex
											w={"100%"}
											direction={"column"}
											p={2}
											rounded={"md"}
											borderLeftWidth={"0.5px"}
											borderLeftColor={"gray.200"}
											mb={2}>
											<Text>
												Source Audio Languages (
												<b>{transcriptionLanguages.length} Supported Languages</b>)
											</Text>
											<Multiselect
												// selectionLimit={2}
												// singleSelect={true}
												w={"150px"}
												options={transcriptionLanguages}
												selectedValues={srcLanguagesData}
												onSelect={selectedList => handleSingleSelect(selectedList)}
												onRemove={selectedList => handleSingleSelect(selectedList)}
												displayValue="name"
												placeholder="Select your source language"
											/>
										</Flex>
										<Flex
											w={"100%"}
											direction={"column"}
											p={2}
											rounded={"md"}
											borderLeftWidth={"0.5px"}
											borderLeftColor={"gray.200"}
											mb={2}>
											<Flex w={"100%"} mt={1}>
												<Text>
													Do You want to translated above transcription to other languages?{" "}
												</Text>
												<Checkbox
													ml={2}
													isChecked={transTargetLangs}
													onChange={() => setTransTargetLangs(!transTargetLangs)}></Checkbox>
											</Flex>
											{transTargetLangs ? (
												<Flex w={"100%"} direction={"column"}>
													<Text mt={2}>
														Select all Target Languages (
														<b>{translationLanguages.length} Supported Languages</b>)
													</Text>
													<Multiselect
														w={"200px"}
														options={translationLanguages}
														selectedValues={allLanguagesData}
														onSelect={selectedList => handleSelect(selectedList)}
														onRemove={selectedList => handleSelect(selectedList)}
														displayValue="name"
														placeholder="Select your target languages (upto 5)"
													/>
												</Flex>
											) : null}
										</Flex>
										<Flex w={"100%"} direction={"column"} mt={2}>
											<Stack spacing={5} direction="row">
												<Checkbox
													isChecked={proofreadingAdded}
													onChange={e => isProofreadingAdded(!proofreadingAdded)}
													colorScheme="green">
													Add Proofreading into the transcription workflow.
												</Checkbox>
											</Stack>
										</Flex>
										<Flex w={"100%"} direction={"column"} mt={2}>
											<Stack spacing={5} direction="row">
												<Checkbox
													isChecked={resultResponseAdded}
													onChange={e => isResultResponseAdded(!resultResponseAdded)}
													colorScheme="green">
													Add transcription results into the email.
												</Checkbox>
											</Stack>
										</Flex>
									</Flex>
								</Flex>
								{audioFileName && srcLanguagesData?.length > 0 ? (
									<Flex w={"100%"} borderWidth={"0.5px"} borderColor={"gray.200"} p={2}>
										<Button w={"200px"} bg={"green.100"} onClick={uploadRecordedAudio}>
											<Icon as={AiFillAudio} mr={1} />
											Upload Audio
										</Button>
										<Button
											bg={"red.100"}
											ml={1}
											onClick={() => {
												setAudioFileName(null);
												clearBlobUrl();
												handleTimerReset();
												setSrcLanguagesData([]);
											}}>
											Reset
										</Button>
									</Flex>
								) : (
									<Flex w={"100%"} borderWidth={"0.5px"} borderColor={"gray.200"} p={2}>
										<Button w={"200px"} bg={"gray.100"} disabled>
											<Icon as={AiOutlineStop} mr={1} /> Upload Audio
										</Button>
										<Button
											bg={"red.100"}
											ml={1}
											onClick={() => {
												setAudioFileName(null);
												clearBlobUrl();
												setSrcLanguagesData([]);
												handleTimerReset();
												isShowRecorder(true);
											}}>
											Reset
										</Button>
									</Flex>
								)}
								<Flex w={"100%"}>
									<Text color="green">{uploadStatus}</Text>
								</Flex>
							</Flex>
						) : null}
					</Flex>
				</Flex>
			) : (
				<Flex w={"100%"} p={2} align={"start"}>
					<Text>Bad context...Please retry.</Text>
				</Flex>
			)}
		</Card>
	);
}
