// React Imports
import React,{useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import { Flex, 
	Spacer,
	Text, Button, Box, Spinner, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react";

// Component Imports
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useYellowButton } from "../../../theme/globalColorTheme";
import { toastFunctionToaster } from "../../../utils/toastFunction";

import Card from "../../../components/card/Card";

// Api Imports
import { fetchSubscriberTranscript, fetchSubscriberTranscriptData, lingoEmailTranscript } from "../../../services/accountServices";

function LinkSubscriberTranscription() {
	const toast = useToast()
	const { state } = useLocation();
	const navigate = useNavigate()

	const linkDetails = state?.linkItem
	const linkId = linkDetails?.uuid;
	const accountId = state?.accountId;
	const locationId = state?.locationId;
	const yellowBgButton = useYellowButton();
	const [invalidEmail, setInvalidEmail] = useState([]);
	const [emailLoader, setEmailLoader] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [resultLoader, setResultLoader] = useState(false);
	const [transcriptData, setTranscriptData] = useState([]);
	const [skillsKeyword, setSkillsKeyword] = useState([]);
	const [resultTransctipt, setResultTranscript] = useState([]);
	const [transcriptLoader, setTranscriptLoader] = useState(false);
	const [selectedTranscript,setSelectedTranscript] = useState(null);
	console.log(state)

	useEffect(()=> {
		collectSubscriberTranscription()
	},[])


	useEffect(()=>{
		console.log(transcriptData)
	},[transcriptData])

	const collectSubscriberTranscription = () => {
		setTranscriptLoader(true);
		let obj = {
			"linkUuid": linkId,
			"link_account_uuid" : accountId,
			"link_location_uuid": locationId,
		}
		fetchSubscriberTranscript(obj).then((res)=>{
			setTranscriptLoader(false);
			if (res?.result) {
				if (res?.data?.length) {
					setTranscriptData(res?.data)
					toast(toastFunctionToaster(res.message, "success"));
				}
			}
			else {
				toast(toastFunctionToaster(res.message, "error"));
			}
		}).catch((err)=>{
			setTranscriptLoader(false);
			toast(toastFunctionToaster(err.message, "error"));
		})
	}

	const getTransctiptData = (transcriptId) => {
		setResultLoader(true)
		let obj = {
			"linkUuid": linkId,
			"link_account_uuid" : accountId,
			"link_location_uuid": locationId,
			"transcript_uuid": transcriptId
		}
		fetchSubscriberTranscriptData(obj).then((res)=>{
			setResultLoader(false)
			if (res.result) {
				setResultTranscript(res?.data[0]?.transcript_text)
				toast(toastFunctionToaster(res.message, "success"));
			}
			else {
				toast(toastFunctionToaster(res.message, "error"));
			}
		}).catch((err)=>{
			if (err) {
				setResultLoader(false)
				toast(toastFunctionToaster(err.message, "error"));
			}
		})
	}

	const renderTranscriptList = () => {
		if (transcriptData?.length) {
			return transcriptData.map((data, index) => (
				<Box mb="4" key={index}>
					<Flex key={index}  p="2" borderRadius={'5px'} borderWidth={'1px'} alignItems={'center'} justifyContent={'space-between'}>
						<Flex>
							<Text>
								{data?.datetime_created}
							</Text>
							<Text ml="4" fontWeight={'bold'}>
								{data?.transcript_languages}
							</Text>
						</Flex>
						<Button size="sm" bg={yellowBgButton} borderRadius={'5px'} onClick={()=>{
							getTransctiptData(data?.transcript)
							setSelectedTranscript(index)
							}} 	isDisabled={resultLoader ? true : false}> Get Transcript</Button>
					</Flex>
					{ resultTransctipt?.length && selectedTranscript == index ? (
						renderTranscriptData()
					) : null }
				</Box>
			));
		} else {
			return <Text w={'100%'} p={2} bg={'yellow.100'} color="#gray.800" fontSize={"16px"} >There is no transcripts data for select LingoLink!!</Text>;
		}
	};

	const renderTranscriptData = () => {
		return (
		<>
		{resultLoader ? (
			<Flex alignItems="center" justifyContent="center" h="20vh">
				<Spinner size="lg" />
			</Flex>
			):(
			<Box mb="4" p="2" borderRadius={'5px'} borderWidth={'1px'} borderTop={'0px'} h='200px' overflow={'hidden'} overflowY={'auto'}
			css={{
				'&::-webkit-scrollbar': {
					width: '4px',
				},
				'&::-webkit-scrollbar-track': {
					width: '6px',
				},
				'&::-webkit-scrollbar-thumb': {
					background: 'transparent',
					borderRadius: '24px',
				},
			}}>
				<Flex justifyContent={'space-between'}>
					<Text>{renderTranscriptJson()}</Text>
					{resultTransctipt?.length?(
						<Button size="sm" colorScheme="yellow" borderRadius={'5px'} onClick={()=>{ onOpen()} }>Email</Button>
					):null}
				</Flex>
			</Box> )}
		</>
		)
	}

	const renderTranscriptJson = () => {
		if(JSON.parse(resultTransctipt)) {
			const transcriptJson = JSON.parse(resultTransctipt)
			console.log(transcriptJson)
			return transcriptJson?.map((transcript, index)=>{
				console.log(transcript)
				return (
					<>
					<Flex key={index} alignItems={'center'}>
						<Icon as={AiFillCaretRight}/>
						<Text>{transcript?.language} :</Text>
						<Text ml="2">{transcript?.text}</Text>
					</Flex>
					{transcript?.communication_polyglots?
					(
					<Flex key={index} alignItems={'center'} ml="3">
						<Icon as={AiFillCaretRight}/>
						<Text>{transcript?.communication_polyglots?.[0]?.language} :</Text>
						<Text ml="2">{transcript?.communication_polyglots?.[0]?.text}</Text>
					</Flex>):null}
					</>
				)
			})
			
		} else {
			<Text>No Data Available|</Text>
		}
		console.log(JSON.parse(resultTransctipt))
	}

	const onTagChange = tags => {
		setSkillsKeyword(tags);
	};

	const validateEmails = emails => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emails.filter(email => !emailRegex.test(email));
	};

	useEffect(() => {
		setInvalidEmail(validateEmails(skillsKeyword));
	}, [skillsKeyword]);

		// Todo:Send Transcript Data By Mail
	const mailTranscriptData = () => {
		const transcriptJson = JSON.parse(resultTransctipt);
		const transctiptArr = []
		transcriptJson?.map((data)=>{
			let obj = {
				language:data?.language,
				text:data?.text
			}
			transctiptArr.push(obj)
		})
		setEmailLoader(true);
		const letObj = {
			linkKey: linkDetails?.key,
			languages: `${transcriptData[0]?.transcript_languages}`,
			transcript_text: JSON.stringify(transctiptArr),
			emails: skillsKeyword.join(", "),
		};
		// Api need to be added
	}

	const backToLingolinks = () => {
		navigate("/subscriber/lingolinklisting", {
			state: {
				linkId:linkId,
			},
		});
	};

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			<Card p="3" alignItems="center" rounded={'md'}>
				<Flex w={'100%'} direction={'row'} alignItems="center">
					<Text fontWeight={"600"} fontSize={"16px"} justifyContent={'center'}>
						LingoLink Transcriptions
					</Text>
					<Spacer />
					<Button
						size={'sm'} rounded={'lg'}
						bg={'blue.100'}
						onClick={ () => backToLingolinks()}
						>
						Back to LingoLinks
					</Button>
				</Flex>
			</Card>
			<Card p="1" rounded={'md'} mt={1}>
				{transcriptLoader ? (
					<Flex alignItems="center" justifyContent="center" h="50vh">
						<Spinner size="lg" />
					</Flex>
					) : renderTranscriptList()
				}
			</Card>
			{/* Popup Modal */}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Send Email</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Please use enter to multiple emails in the textbox.</Text>
						<TagsInput value={skillsKeyword} onChange={onTagChange} inputProps={{ placeholder: "Enter Email" }} />
						<Flex flexWrap="wrap" alignItems={"center"} mt="2">
							{invalidEmail.map((email, index) => (
								<Text p="1" mr="1" bg="red.100" color="#000" size="sm" key={index}>
									{email}
								</Text>
							))}
							{invalidEmail?.length ? <Text>Not Valid Email </Text> : null}
						</Flex>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="green"
							mr={3}
							onClick={() => {
								mailTranscriptData();
							}}
							isLoading={emailLoader ? true : false}
							isDisabled={invalidEmail?.length > 0 || !skillsKeyword?.length ? true : false}
						>
							Send Mail
						</Button>
						<Button colorScheme="blue" onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}

export default LinkSubscriberTranscription;