import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import { useLocation, useNavigate, location } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import {
  extendTheme,
  Container,
  Heading,
  Icon,
  Button,
  Box,
  Flex,
  Fade,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Link,
  Text,
  Input,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  Center,
} from "@chakra-ui/react";
// import theme from "./theme";
// import HeroCarousel from "./HeroCarausel";
import { FaPlus, FaArrowRightArrowLeft, FaBilibili, FaRocketchat, 
  FaArrowsDownToPeople, FaArrowsTurnToDots, FaCircleDot, FaChevronDown,
  FaAlignJustify } from "react-icons/fa6";

import WaitListingComp from "../../komponents/landinghome/WaitListMe";

// Image Imports
import lingologo from "../../assets/landingPageImg/kb_logo.png";
import lsplogo from "../../assets/landingPageImg/lspcloud_logo-wide.png";

import banner from "../../assets/landingPageImg/lingo-hero.png";
import hubridappbanner from "../../assets/landingPageImg/hybrid-app-banner.png";
import audioanalytics from "../../assets/landingPageImg/audio-analytics.png";

import contentImg1 from "../../assets/landingPageImg/content/lingo-device-1.png";
import contentImg2 from "../../assets/landingPageImg/content/lingo-device-2.png";
import contentImg3 from "../../assets/landingPageImg/content/lingo-device-3.png";
import contentBanner from "../../assets/landingPageImg/lingolet-banner.jpg";
import chatContent1 from "../../assets/landingPageImg/lingolet-services-1.png";
import chatContent2 from "../../assets/landingPageImg/lingolet-services-2.png";
import chatContent3 from "../../assets/landingPageImg/lingolet-services-3.png";
import chatContent4 from "../../assets/landingPageImg/lingolet-services-4.png";
import downArrow from "../../assets/landingPageImg/kb_arrow_down.png";

import boostlingo from "../../assets/landingPageImg/boostlingo-logo.png";
import mls from "../../assets/landingPageImg/MLT_H_623_175.png";

import gdprLogo from "../../assets/landingPageImg/GDPR-compliant.png";
import hippalogo from "../../assets/landingPageImg/hipaa-compliance.png";
import hitrustLogo from "../../assets/landingPageImg/HITRUST-CSF-Certified.png";
import soc2Logo from "../../assets/landingPageImg/SOC2_Logo_Revised_3__591b2ae4dbc8a.png";
import naatiLogo from "../../assets/landingPageImg/naati-certified-translation.png";
import lingoletAtCes from "../../assets/marketing/lingolet-at-ces.png";

import LingoletFooter from "./LingoletFooter";

function LandingPage() {
  const navigate = useNavigate();
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const whyChooseTargetDiv = useRef(null);
  const featureTargetDiv = useRef(null);
  const waitlistForm = useRef(null);
  const heroBlocks = ['main', 'hybrid', 'ai'];
  const [heroBlock, setHeroBlock] = useState('main');
  
  const current_logo = window.location.hostname.includes('lspcloud') ? lsplogo : lingologo;


  const contentArr = [
    {
      heading: "Lingolet Interpreter Video",
      subheading:
        "Lingolet Interpreter Video for OPI & VRI. Click to select languages. See participants via video. HD mic & speakers for clear sound.",
      img: contentImg1,
    },
    {
      heading: "Lingolet Interpreter Tablet",
      subheading:
        "Lingolet Interpreter Tablet for OPI & VRI. Click to select languages. See participants via video. HD mic & speakers for clear sound.",
      img: contentImg2,
    },
    {
      heading: "Lingolet Interpreter Conference",
      subheading:
        "Lingolet Interpreter Conference for OPI. Place on a conference table for multiple participants. HD mic & speakers for clear sound.",
      img: contentImg3,
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const setupScroller = () => {
    if (currentIndex >= heroBlocks.length-1){
      setHeroBlock(heroBlocks[currentIndex]);
      return setCurrentIndex((currentIndex => currentIndex * 0));
    }
    setHeroBlock(heroBlocks[currentIndex]);
    return setCurrentIndex((currentIndex) => currentIndex+1);
  }

  useEffect(() => {
    const interval = setInterval(() => {setupScroller()}, 3000);
    return () => clearInterval(interval)
  }, [currentIndex]);

  const renderContent = contentArr?.map((content, index) => (
    <Flex mb="16" flexDirection={index % 2 === 1 ? "row-reverse" : ""}>
      <Box ml={index % 2 === 1 ? "6" : ""} mr={index % 2 === 0 ? "6" : ""}>
        <Heading
          fontSize={{
            base: "",
            md: "",
            xl: "",
            sm: "20px",
          }}
          fontWeight={"500"}
          mb="6"
        >
          {content?.heading}
        </Heading>
        <Text
          fontSize={{
            base: "18px",
            md: "18px",
            xl: "18px",
            sm: "14px",
          }}
          fontWeight={"500"}
        >
          {content?.subheading}
        </Text>
      </Box>
      <Box>
        <Image src={content?.img}></Image>
      </Box>
    </Flex>
  ));

  const launchPage = (url) => {
    navigate(url, {
        state: {
            sourcePage:'cesmediakit',
        },
    });
  };
  // Todo:Scroll to targeted div
  const scrollToDiv = (divType) => {
    if (divType === "devices") {
      if (whyChooseTargetDiv.current) {
        ReactDOM.findDOMNode(whyChooseTargetDiv.current).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    if (divType === "services") {
      if (featureTargetDiv.current) {
        ReactDOM.findDOMNode(featureTargetDiv.current).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    if (divType === "form") {
      if (waitlistForm.current) {
        ReactDOM.findDOMNode(waitlistForm.current).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  return (
    <>
      <Box bg="#fff" color="#000">
        <Box bg="#fff">
          <Flex
            w={{
              base: "50%",
              md: "90%",
              xl: "50%",
              sm: "100%",
            }}
            mx="auto"
            justifyContent={"space-between"}
            alignItems={"center"}
            h="12vh"
          >
            <Box>
              <Image
                w={{
                  base: "50%",
                  md: "50%",
                  xl: "50%",
                  sm: "50%",  
                }}
                src={current_logo}
              ></Image>
            </Box>
            <Flex
              alignItems={"center"}
              fontSize={{
                base: "14px",
                md: "14px",
                xl: "14px",
                sm: "10px",
              }}
              bg="white"
            >
              <Text
                m="0"
                mr={1}
                fontWeight={"bold"}
                cursor={"pointer"}
                onClick={() => {
                  scrollToDiv("devices");
                }}
                color="#000"
              >
                Devices
              </Text>
              {/* <Text
                m="0"
                fontWeight={"bold"}
                ml="5"
                cursor={"pointer"}
                color="#000"
                onClick={() => {
                  scrollToDiv("services");
                }}
              >
                Services
              </Text> */}
              <Menu bg={'gray.100'}>
                <MenuButton 
                  transition='all 0.2s'
                  borderRadius='md'
                  borderWidth='1px'
                  _hover={{ bg: 'blue.100' }}
                  _focus={{ boxShadow: 'outline' }}
                  as={Button} 
                  size={{
                    base: "sm",
                    md: "sm",
                    xl: "sm",
                    sm: "xs",
                  }}
                  rightIcon={<FaChevronDown />}>
                  Products & Services
                </MenuButton>
                <MenuList >
                  <MenuItem bg={'gray.100'} _hover={{'bg': 'blue.100'}} onClick={() => {
                      scrollToDiv("services");
                    }}>Services
                  </MenuItem>
                  <MenuItem bg={'gray.100'}  _hover={{'bg': 'blue.100'}} onClick={() => launchPage("/hybridapp")}>
                    Hybrid Translation App
                  </MenuItem>
                </MenuList>
              </Menu>
              <Button
                ml="5"
                size={{base: "sm",
                  md: "sm",
                  xl: "sm",
                  sm: "xs",}}
                colorScheme="twitter"
                onClick={() => {
                  scrollToDiv("form");
                }}
              >
                Request a Demo
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Box position="relative">
          <Box>
            {heroBlock === 'main' ?<Image w={'100%'} src={banner} />:null}
            {heroBlock === 'hybrid' ?<Image w={'100%'} src={hubridappbanner} />:null}
            {heroBlock === 'ai' ?<Image w={'100%'} src={audioanalytics} />:null}            
          </Box>
          {heroBlock === 'main'
          ?<Fade initialScale={0.5} in={heroBlock === 'main'}><Box w={'100%'} align={'center'}>
            <Box
              position="absolute"
              top={{
                base: "15%",
                md: "15%",
                xl: "15%",
                sm: "5%",
              }}
              w="100%"
              textAlign={"center"}
              align={'center'}
            >
              <Box w={'100%'} align={"center"} >
                <Image 
                  _hover={{cursor:'pointer'}}
                  onClick={() => launchPage("/cesmediakit")} src={lingoletAtCes} />
              </Box>
              <Text
                mt={1}
                color="#fff"
                fontSize={{
                  base: "46px",
                  md: "46px",
                  xl: "46px",
                  sm: "20px",
                }}
              >
                Lingolet Smart AI Devices Connect You to Certified Interpreters
              </Text>
              <Text
                color="#fff"
                fontSize={{
                  base: "44px",
                  md: "44px",
                  xl: "44px",
                  sm: "18px",
                }}
              >
                Any language, Anytime, Anywhere
                <Text as="span" fontWeight={"bold"} ml="2">
                  Human and AI
                </Text>
              </Text>
              <Text
                color="#fff"
                fontSize={{
                  base: "24px",
                  md: "24px",
                  xl: "24px",
                  sm: "14px",
                }}
                mt="4"
              >
                Lingolet devices let you find interpreters in 300+ languages 24/7.
              </Text>
              <Button
                _hover={{ boxShadow: "none", background: "none", bg:"gray.500" }}
                variant="outline"
                color="#fff"
                bg={'gray.800'}
                fontWeight={"normal"}
                mt={{
                  base: "mt",
                  md: "mt",
                  xl: "mt",
                  sm: "4",
                }}
                size={{
                  base: "md",
                  md: "md",
                  xl: "md",
                  sm: "sm",
                }}
                onClick={() => {
                  scrollToDiv("form");
                }}
              >
                Request a Demo
              </Button>
            </Box>
            </Box></Fade>
            :null}
            {heroBlock === 'hybrid'
            ?<Fade initialScale={0.1} in={heroBlock === 'hybrid'}><Box w={'100%'} align={'center'}>
                <Box
                  bg={'blue.200'} p={2} rounded={'lg'}
                  onClick={() => launchPage("/hybridapp")}
                  position="absolute"
                  left={{
                    base: "10%",
                    md: "10%",
                    xl: "10%",
                    sm: "10%",
                  }}
                  bottom={{
                    base: "10%",
                    md: "13%",
                    xl: "13%",
                    sm: "18%",
                  }}
                  cursor="pointer"
                >
                <Text fontSize={{
                  base: "xl",
                  md: "xl",
                  xl: "xl",
                  sm: "xs"}}>Learn More about Hybrid App</Text>
              </Box>        
            </Box></Fade>:null}
            {heroBlock === 'ai'
            ?<Fade initialScale={0.1} in={heroBlock === 'ai'}><Box w={'100%'} align={'center'}>
                <Box
                  bg={'red.200'} p={2} rounded={'lg'}
                  position="absolute"
                  left={{
                    base: "50%",
                    md: "50%",
                    xl: "50%",
                    sm: "10%",
                  }}
                  bottom={{
                    base: "10%",
                    md: "13%",
                    xl: "13%",
                    sm: "18%",
                  }}
                  cursor="pointer"
                >
                <Text fontSize={{
                  base: "xl",
                  md: "xl",
                  xl: "xl",
                  sm: "xs"}}>Coming soon</Text>
              </Box>                
            </Box></Fade>:null}
          <Box
            position="absolute"
            left={{
              base: "50%",
              md: "50%",
              xl: "50%",
              sm: "45%",
            }}
            bottom={{
              base: "3%",
              md: "3%",
              xl: "3%",
              sm: "8%",
            }}
            cursor="pointer"
          >
            <Icon as={FaCircleDot} 
              h={heroBlock === 'main'? 6: 4} w={heroBlock === 'main'? 6: 4}
              onClick={() => setHeroBlock('main')} color={'red.400'} mr={5} />
            <Icon 
              h={heroBlock === 'hybrid'? 6: 4} w={heroBlock === 'hybrid'? 6: 4}
              as={FaCircleDot} onClick={() => setHeroBlock('hybrid')} color={'red.400'} mr={5}/>
            <Icon 
              h={heroBlock === 'ai'? 6: 4} w={heroBlock === 'ai'? 6: 4}
              mt={heroBlock === 'ai' ? 10 : 0}
              as={FaCircleDot} onClick={() => setHeroBlock('ai')} color={'red.400'} mr={5} />
          </Box>
          {/* <Box
            position="absolute"
            left={{
              base: "50%",
              md: "50%",
              xl: "50%",
              sm: "45%",
            }}
            bottom={{
              base: "-5%",
              md: "-5%",
              xl: "-5%",
              sm: "-10%",
            }}
            onClick={() => {
              scrollToDiv("devices");
            }}
            cursor="pointer"
          >
            <Image
              w={{
                base: "70%",
                md: "70%",
                xl: "70%",
                sm: "50%",
              }}
              src={downArrow}
            ></Image>
          </Box> */}
        </Box>
        {/* End of Hero Panel */}
        <Box
          borderTopWidth={'1px'} borderTopColor={'gray.200'}
          pt={'10px'}
          w={{
            base: "50%",
            md: "90%",
            xl: "50%",
            sm: "90%",
          }}
          mx="auto"
          my="12"
          bg="#fff"
        >
          <Heading
            textAlign={"center"}
            my="50px"
            fontSize={{
              base: "20px",
              md: "20px",
              xl: "50px",
              sm: "20px",
            }}
            fontWeight={"normal"}
            ref={whyChooseTargetDiv}
          >
            Lingolet{" "}
            <Text as="span" fontWeight={"bold"}>
              Family 
            </Text>
            {' '}of IoT Devices
          </Heading>
          <Box>{renderContent}</Box>
        </Box>
        <Box
          backgroundImage={`url(${contentBanner})`}
          backgroundSize="cover"
          backgroundPosition="center"
          padding="40px"
          position={"relative"}
          fontSize={{
            base: "30px",
            md: "30px",
            xl: "30px",
            sm: "18px",
          }}
        >
          <Text
            color="gray.600"
            w={{
              base: "50%",
              md: "90%",
              xl: "50%",
              sm: "90%",
            }}
            mx="auto"
            textAlign={"center"}
          >
            Lingolet makes
            <Text as="span" fontWeight={"bold"} ml="2">
            IoT hardware
            </Text>{" "}
            solutions for on-demand interpretation services. 
            These can be used in healthcare, legal, education, business, and more. 
            Lingolet devices let you find interpreters in 300+ languages 24/7.
          </Text>
          <Box
            position="absolute"
            bottom={{
              base: "-15%",
              md: "-15%",
              xl: "-15%",
              sm: "-10%",
            }}
            color="#000"
            left={{
              base: "50%",
              md: "50%",
              xl: "50%",
              sm: "45%",
            }}
            onClick={() => {
              scrollToDiv("services");
            }}
            cursor="pointer"
          >
            <Image
              w={{
                base: "70%",
                md: "70%",
                xl: "70%",
                sm: "50%",
              }}
              src={downArrow}
            ></Image>
          </Box>
        </Box>
        <Box
          w={{
            base: "50%",
            md: "90%",
            xl: "50%",
            sm: "90%",
          }}
          mx="auto"
          textAlign={"center"}
          my="16"
          ref={featureTargetDiv}
          bg="#fff"
        >
          <Text
            fontSize={{
              base: "35px",
              md: "35px",
              xl: "35px",
              sm: "18px",
            }}
          >
            Lingolet {" "}
            <Text as="span" fontWeight={"bold"}>
            Services and Features{" "}
            </Text>
            for global enterprises and business
          </Text>
          <Flex mt="12" flexWrap={"wrap"}>
            <Box
              mr={{
                base: "5",
                md: "5",
                xl: "5",
                sm: "0",
              }}
              w={{
                base: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
              }}
            >
              <Image borderRadius={"20px"} src={chatContent1}></Image>
            </Box>
            <Box
              textAlign={"left"}
              w={{
                base: "45%",
                md: "45%",
                xl: "45%",
                sm: "100%",
              }}
              mt="3rem"
            >
              <Heading fontSize={"26px"} fontWeight={"normal"} mb="4">
                Certified Interpreters
              </Heading>
              <Text fontSize={"16px"}>
              Our technology connects you to the best interpreter while ensuring confidentiality and privacy. The Lingolet Network provides certified interpreters in over 200 languages, with strong support for the top 40 languages. This network is designed to meet the language needs of healthcare organizations.
              </Text>
              <Text pl={5} mt={5}  fontWeight={"500"}>
              <ul>
                <li>Interpreters' experience</li>
                <li>Technology readiness</li>
                <li>File encryption</li>
                <li>Communication encryption</li>
              </ul>
              </Text>
              <Text mt={5}>
              Our platform is cloud-based and highly scalable. The application is designed and managed in alignment with the best security practices, a variety of IT security standards, and industry-specific standards, including HIPAA and the Cloud Security Alliance.
              </Text>
            </Box>
          </Flex>
          <Flex mt="12" flexWrap={"wrap"}>
            <Box
              textAlign={"left"}
              w={{
                base: "45%",
                md: "45%",
                xl: "45%",
                sm: "100%",
              }}
            >
              <Heading fontSize={"26px"} fontWeight={"normal"} mb="4">
                Lingolet Hybrid App
              </Heading>
              <Text fontSize={"16px"} fontWeight={"500"}>
                Lingolet Hybrid App is AI language technology plus humans via an app and web applications. The Lingolet Hybrid platform is the most complete and innovative interpretation language solution on the market today.
              </Text>
              <Text pl={5} mt={5}  fontWeight={"500"}>
              <ul>
                <li>Interpretation with 4,000+ certified interpreters in 300+ languages. Choose language pairs, audio or video, gender, and industries. No appointment needed. Connect within 30 seconds 24/7 worldwide.</li>
                <li>Use our AI Interpreter. Under one-second latency and 98% accuracy.</li>
                <li>Transcription with closed caption text during meetings, webinars, lectures, or live events in 12 languages. You can add human proofreading.</li>
                <li>Audio Transcription in 12 languages with AI speech recognition. You can add human proofreading.</li>
                <li>On-demand ASL for the deaf community.</li>
              </ul>
              </Text>
            </Box>
            <Box
              ml="5"
              w={{
                base: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
              }}
            >
              <Image src={chatContent2}></Image>
            </Box>
          </Flex>
          <Flex mt="12" flexWrap={"wrap"}>
          <Box
              ml="5"
              w={{
                base: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
              }}
            >
              <Image src={chatContent3}></Image>
            </Box>

            <Box
              textAlign={"left"}
              w={{
                base: "45%",
                md: "45%",
                xl: "45%",
                sm: "100%",
              }}
            >
              <Heading fontSize={"26px"} fontWeight={"normal"} mb="4">
                Lingolet Interpreter Tablet
              </Heading>
              <Text fontSize={"16px"}>
                The Lingolet Interpreter Tablet makes it easy to connect to an interpreter. The device supports voice-based over-the-phone interpretation (OPI) and video-based interpretation (VRI). All participants can see each other in the video screen. Useful for industries such as
              </Text>
              <Text pl={5} mt={5}  fontWeight={"500"}>
                <ul>
                  <li>Medical and Healthcare</li>
                  <li>Global Business/Enterprise/Professional Organizations</li>
                  <li>Education (K-12, Higher Education)</li>
                  <li>Legal / Judicial</li>
                  <li>Government (Federal, State)</li>
                </ul>
              </Text>
              <Text fontSize={"16px"} fontWeight={"500"}>
              The Lingolet Interpreter Tablet fits in a rolling stand (optional) that can be moved to any room. Use the screen to select languages and make a connection for an audio or video call. Lingolet Interpreter Tablet has a built-in microphone and speakers so everyone can listen.              
              </Text>
            </Box>
          </Flex>
          <Flex mt="12" flexWrap={"wrap"}>
            <Box
              textAlign={"left"}
              w={{
                base: "45%",
                md: "45%",
                xl: "45%",
                sm: "100%",
              }}
            >
              <Heading fontSize={"26px"} fontWeight={"normal"} mb="4">
              Lingolet Interpreter Conference
              </Heading>
              <Text fontSize={"16px"} fontWeight={"500"}>
              The Lingolet Interpreter Conference enables people to communicate with an interpreter. The device supports voice-based over-the-phone interpretation (OPI). Click to select desired languages and start a call. The device has HD audio with high-quality microphones and speakers to ensure clear communication for all participants.
              </Text>
              <Text pl={5} mt={5}  fontWeight={"500"}>
                <ul>
                  <li>Medical and Healthcare</li>
                  <li>Global Business/Enterprise/Professional Organizations</li>
                  <li>Education (K-12, Higher Education)</li>
                  <li>Legal / Judicial</li>
                  <li>Government (Federal, State)</li>
                </ul>
              </Text>
              <Text fontSize={"16px"} fontWeight={"500"}>
              The most popular languages can be displayed on the home screen to be able to quickly make a selection and get connected through an audio or video call. The built-in microphone and speakers enhance the quality of the call, which is essential for effective communication.
              </Text>
            </Box>
            <Box
              mt={{
                base: "0",
                md: "0",
                xl: "0",
                sm: "5",
              }}
              ml={{
                base: "5",
                md: "5",
                xl: "5",
                sm: "0",
              }}
              w={{
                base: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
              }}
            >
              <Image src={chatContent4}></Image>
            </Box>
          </Flex>
        </Box>

        <Box
          backgroundImage={`url(${contentBanner})`}
          backgroundSize="cover"
          backgroundPosition="center"
          padding="40px"
          position={"relative"}
          fontSize={{
            base: "30px",
            md: "30px",
            xl: "30px",
            sm: "18px",
          }}
        >
            <Text
              color="gray.700"
              w={{
                base: "50%",
                md: "90%",
                xl: "50%",
                sm: "90%",
              }}
              mx="auto"
              textAlign={"center"}
            >
              Lingolet
              <Text as="span" fontWeight={"bold"} ml="2">
              Partners
              </Text>{" "}
            </Text>
            <Box w={'100%'} align={'center'}>
                <Image src={boostlingo} alignSelf={'center'} />
                <Image src={mls} alignSelf={'center'}  />
            </Box>
            <Text
              mt={'10'}
              color="gray.700"
              w={{
                base: "50%",
                md: "90%",
                xl: "50%",
                sm: "90%",
              }}
              mx="auto"
              textAlign={"center"}
            >
              <Text as="span" fontWeight={"bold"} ml="2">
              Compliance
              </Text>{" "}
            </Text>
            <Flex w={'100%'} align={"center"}>
                <Image src={naatiLogo} />
                <Image src={hippalogo} />
                <Image src={hitrustLogo} />
                <Image src={soc2Logo} />
                <Image src={gdprLogo} />
            </Flex>
        </Box>

        <Box
          w={{
            base: "50%",
            md: "90%",
            xl: "50%",
            sm: "90%",
          }}
          mx="auto"
          textAlign={"center"}
          my="16"
          ref={featureTargetDiv}
          bg="#fff"
        >
          <Box
            p="6"
            sx={{
              background: "linear-gradient(to bottom right, #A6A6A6, #00FFFF)",
            }}
            borderRadius={"20px"}
            mt="12"
            ref={waitlistForm}
          >
            <Box>
              <WaitListingComp />
            </Box>
          </Box>
        </Box>
        <LingoletFooter />
        <Box bg="#fff">
          <Text color="#000" textAlign={"center"} p="8">
            &copy; Copyright 2024 Lingolet AI, Inc. All rights reserved.
          </Text>
        </Box>
      </Box>
    </>
  );
}

export default LandingPage;
