// Menu Actions
export const SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE";
export const SET_NOTIFICATION_STATUS_COLOR = "SET_NOTIFICATION_STATUS_COLOR";
export const SET_BUSINESS_INFO_STATUS = "SET_BUSINESS_INFO_STATUS";
export const SET_EXPAND_MIN_SIDEMENU = "SET_EXPAND_MIN_SIDEMENU";
export const SET_BANNER_LOGO = "SET_BANNER_LOGO";
export const SET_PROFILE_LOGO = "SET_PROFILE_LOGO";
export const SET_BUSINESS_WIZARD_STEPPER = "SET_BUSINESS_WIZARD_STEPPER";
export const SET_JOB_UUID = "SET_JOB_UUID";
export const SET_JOB_WIZARD_STATUS = "SET_JOB_WIZARD_STATUS";
export const CUSTOM_BREADCRUM = "CUSTOM_BREADCRUM";
export const SET_ACCOUNT_UPLOAD_FLAG = "SET_ACCOUNT_UPLOAD_FLAG"
export const SET_PARENT_LOGO = "SET_PARENT_LOGO"
export const SET_HOME_ROUTE="SET_HOME_ROUTE"
