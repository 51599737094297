import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Chakra Import
import {
    Box,
    Flex,
    Image,
    Text,
    Icon,
    Avatar,
    Switch,
    Spacer,
    Heading,
    Spinner,
    useToast,
    useDisclosure,
    useColorModeValue
} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import {
    AiFillLeftCircle,
    AiFillRightCircle,
    AiOutlinePlus,
    AiOutlineHolder,
} from "react-icons/ai";

// Api Call Imports
import { fetchLocationInfo, fetchLocationDetails, manageLocationStatus } from "../../../services/accountServices";

// Component Imports
import AddUpdateLocation from "./AddUpdateLocation";
import { useBrandBg } from "../../../theme/globalColorTheme";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function LocationsListing() {
    const toast = useToast();
    const brandBg = useBrandBg();
    const navigate = useNavigate();
    const [loading, isLoading] = useState(true);
    const [accountUuid, setAccountUuid] = useState(null);
    const [accountInfo, setAccountInfo] = useState([]);

    const [activeCount, setActiveCount] = useState(0)
    const [closedCount, setClosedCount] = useState(0)
    const [locationList, setLocationList] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedLocationData, setSelectedLocationData] = useState({})
    const [currentSelection, setCurrentSelection] = useState('active');
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const textColor = useColorModeValue("secondaryGray.700", "white");
    const pillButtonBgHover = useColorModeValue("blue.400", "red.200");
    const pillButtonBgSelected = useColorModeValue("blue.300", "red.400");
    const pillButtonTextColorHover = useColorModeValue("secondaryGray.200", "gray.700");

    //   Todo:Fetch all location details
    useEffect(() => {
        collectLocationInfo();
        collectLocationList();
    }, [accountUuid]);

    const collectLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'location',
        };
        fetchLocationInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setAccountUuid(res?.data[0]?.uuid);
                    setAccountInfo(res?.data[0]?.info);
                } else {
                    isLoading(false);
                    setAccountUuid([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setAccountUuid([]);
            });
    };

    const collectLocationList = (currentSelection) => {
        if (accountUuid != null) {
            isLoading(true);
            let paramObj = {
                "link_account_uuid": accountUuid,
                key: currentSelection,
                sub_location: true,
                type: 'full',
                records_per_page: 0,
                page_id: 0,
            };
            fetchLocationDetails(paramObj)
                .then(res => {
                    isLoading(false);
                    if (res?.data[0]?.total_records) {
                        isLoading(false);
                        setLocationList(res?.data[0]?.records);
                        setActiveCount(res?.data[0]?.active_locations ? res?.data[0]?.active_locations : 0);
                        setClosedCount(res?.data[0]?.closed_location ? res?.data[0]?.closed_location : 0);                
                    } else {
                        isLoading(false);
                        setLocationList([]);
                    }
                })
                .catch(err => {
                    isLoading(false);
                    setLocationList([]);
                });
        }
    };

    // Todo:Update Location Status
    const uploadLocationStatus = (locationData) => {
        if (locationData?.link_location_uuid != null && locationData?.link_account_uuid !== null) {
            let objBody = {
                "link_location_uuid": locationData?.link_location_uuid,
                "link_account_uuid": locationData?.link_account_uuid
            };
            manageLocationStatus(objBody)
                .then(res => {
                    if (res?.result) {
                        collectLocationList(currentSelection);
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {

                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch(err => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {
        }
    };

    // Todo:Render Location Details
    const renderLocationListing =
        locationList !== null && locationList?.length > 0 ? (
            locationList?.map((item, index) => (
                <Flex
                    w={"100%"}
                    key={index}
                    rounded={"md"}
                    align={"center"}
                    p={1}
                    mt={1}
                    borderWidth={"0.05px"}
                    borderColor={item?.is_enabled ? "green.300" : "red.200"}>
                    <Flex
                        onClick={() => {
                            navigate("/account/locations/subscribers", {
                                state: {
                                    accountUuid: accountUuid,
                                    locationDetails: item,
                                    accountInfo: accountInfo
                                },
                            });
                        }}
                        _hover={{ cursor: "pointer", bg: "blue.100" }}
                        rounded={"md"}
                        px={1}
                        w={"100%"}
                    >
                        <Box minW={"100px"} maxW={'100px'} align='center'>
                            <Avatar w={'80px'} h={'80px'} src={item?.link_location_avatar?.length ? item?.link_location_avatar : null} />
                        </Box>
                        <Flex w={'100%'} direction={'column'}>
                            <Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'}>
                                <Text fontWeight={'bold'}>
                                    {item?.link_location_title}
                                </Text>
                                <Text w="100%">{item?.link_location_name}</Text>
                                <Text w="100%" fontSize={'sm'}>{item?.link_location_title}</Text>
                            </Flex>
                            <Flex minW={"100px"} p={1} ml="2" pl="2" direction={"column"} flexWrap={'wrap'}>
                                <Text>
                                    {item?.link_location_address1}
                                </Text>
                                <Text w="100%">{item?.link_location_address2}</Text>
                                <Text w="100%" fontSize={'sm'}>{item?.link_location_country},{item?.link_location_state},{item?.link_location_city}</Text>
                            </Flex>
                            <Flex minW={"100px"} p={1} ml="2" pl="2" direction={"column"}>
                                {item?.sub_locations && item?.sub_locations?.length > 0
                                ?<Text>{item?.sub_locations?.length} Sub Locations</Text>
                                :<Text>No Sub Locations</Text>}
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex
                        px={2}
                        rounded={'md'}
                        direction={'column'}
                        alignItems={"center"}
                        borderWidth={"1px"}
                        borderColor={"gray.400"}
                        minW={"100px"}
                        align={"end"}>
                            <Heading>
                                {item?.subscriber_count}
                            </Heading>
                            <Text>
                            Subscribers
                            </Text>
                        
                    </Flex>
                    <Flex
                        px={2}
                        alignItems={"center"}
                        // borderLeftWidth={"1px"}
                        // borderLeftColor={"gray.400"}
                        minW={"100px"}
                        align={"end"}>
                        <Switch
                            onChange={() => {
                                uploadLocationStatus(item);
                            }}
                            isChecked={item?.is_enabled ? true : false}
                            colorScheme="green"
                        />
                        <Icon
                            as={AiOutlineHolder}
                            ml={1}
                            p={1}
                            bg={"gray.200"}
                            rounded={"md"}
                            fontSize={"24px"}
                            cursor={"pointer"}
                            onClick={() => {
                                onOpen();
                                setSelectedLocationData(item)
                            }}
                        />
                    </Flex>
                </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    You do not have any location added.
                </Heading>
            </Flex>
        );

    // Todo:Return Active Count
    // useEffect(() => {
    //     if (locationList?.length) {
    //         const returnActiveCount = locationList?.filter((data) => { return data?.is_enabled === true })
    //         setActiveCount(returnActiveCount?.length)
    //     }
    // }, [locationList])

    // Todo:Close Drawer
    const handleClose = () => {
        onClose();
        setSelectedLocationData({})
    }

    const fetchLocationData = (data) => {
        if (data) {
            onClose();
            collectLocationList(currentSelection);
        }
    }

    const renderAccountInfo =
    accountInfo !== null && accountInfo?.length > 0 ? (
        accountInfo?.map((accountData, index) => (
            <Flex w={'100%'} bg={brandBg} rounded={'md'} h={'100%'} align={'center'}>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                        <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                    </Box>
                    {accountData?.link_account_logo
                        ? <Image src={accountData?.link_account_logo} w={100} h={100} /> : null}
                </Flex>
                <Flex w={"110px"}>
                    <Text bg='blue.100' p={1} rounded={'md'} as="span" ml="3">{accountData?.type}</Text>
                </Flex>
                <Flex w={'50%'} direction={'column'}>
                    <Text ml={3} fontSize={"xs"}>
                        Account Details
                    </Text>
                    <Text as="span" ml="3">{accountData?.name}</Text>
                    <Text as="span" ml="3">{accountData?.title}</Text>
                    <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                </Flex>
                <Flex w={'50%'}>
                    {accountData?.apps != null
                    ?<Text>Supported Apps: {accountData?.apps}</Text>
                    :<Text>No Apps</Text>}
                </Flex>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                    {accountData?.logo ? <Image src={accountData?.logo} h={100} /> : null}
                    </Box>
                </Flex>

            </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to collect Account Info
                </Heading>
            </Flex>
        );



    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1} h={'100px'}>
                    {renderAccountInfo}
                </Card>
                <Card p={1} mt={1} mx={3} alignItems="center" rounded={'md'}>
                    <Flex w={'100%'} align={'center'} direction={'row'} maxH={'60px'}>
                        <Text fontWeight={"500"} fontSize={"18px"} >
                            Locations {locationList?.length > 0 ? locationList?.length : 0} ({currentSelection})
                        </Text>
                        <Spacer />
                        <Flex minW={'480px'} maxW={'480px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
                            <Text
                                minW={'70px'} maxW={'70px'}
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    setCurrentSelection('active');
                                    collectLocationList('active');
                                }}
                                p={2} roundedLeft={'md'}
                                color={currentSelection === 'active' ? 'white' : textColor}
                                bg={currentSelection === 'active' ? pillButtonBgSelected : pillButtonBg}>
                                Active
                            </Text>
                            <Text
                                minW={'80px'} maxW={'80px'}
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    setCurrentSelection('closed');
                                    collectLocationList('closed');
                                }}
                                color={currentSelection === 'closed' ? 'white' : textColor}
                                p={2}
                                bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
                                Closed
                            </Text>
                            <Text
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    collectLocationList(currentSelection);
                                }}
                                color={currentSelection === 'refresh' ? 'white' : textColor}
                                p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
                                pr={5}>Refresh
                            </Text>
                            <Text
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    onOpen();
                                }}
                                roundedRight={'md'}
                                color={currentSelection === 'new' ? 'white' : textColor}
                                p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
                                pr={5}>
                                <Icon pt={1} as={AiOutlinePlus} mr={1} />
                                Add New Location
                            </Text>
                        </Flex>
                    </Flex>
                </Card>
                <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1}>
                    <Flex h="70vh">
                        <Box
                            w={!configurationCollapseFlag ? "95%" : "85%"}
                            bg={brandBg}
                            p={1}
                            borderRadius={"10px"}
                            overflow="hidden">
                            <Flex w={"100%"} direction={"column"}>
                                {loading ? (
                                    <Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="50vh">
                                        <Spinner size={"xl"} />
                                    </Flex>
                                ) : (
                                    <Flex w={"100%"} direction={"column"}>
                                        <Flex
                                            direction={"column"}
                                            w={"100%"}
                                            h="calc(100vh - 200px)"
                                            overflow="auto"
                                            borderTopWidth="0px"
                                            css={{
                                                "&::-webkit-scrollbar": {
                                                    width: "4px",
                                                },
                                                "&::-webkit-scrollbar-track": {
                                                    width: "6px",
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    borderRadius: "24px",
                                                },
                                            }}>
                                            {renderLocationListing}
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        </Box>
                        {configurationCollapseFlag ? (
                            <Flex w="15%">
                                <Box
                                    w="100%"
                                    position="relative"
                                    p="3"
                                    borderRadius="15px"
                                    bg={brandBg}
                                    ml="2">
                                    <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                        <AiFillRightCircle
                                            cursor="pointer"
                                            size="20px"
                                            onClick={() => {
                                                setConfiguationCollapseFlag(!configurationCollapseFlag);
                                            }}
                                        />
                                    </Box>
                                    <Box w="100%" h="95%">
                                        <Text fontWeight={'600'} >Location Stats</Text>
                                        <Card mt='10px' borderRadius='10px'>
                                            <Text textAlign={'center'} fontWeight={'600'} color="green.500">Active Locations</Text>
                                            <Heading>
                                            <Text textAlign={'center'} fontWeight={'600'}>{activeCount}</Text>
                                            </Heading>
                                        </Card>
                                        <Card mt='10px' borderRadius='10px'>
                                            <Text textAlign={'center'} fontWeight={'600'} color="red.500">Disable Locations</Text>
                                            <Heading>
                                                <Text textAlign={'center'} fontWeight={'600'}>{closedCount}</Text>
                                            </Heading>
                                        </Card>
                                    </Box>
                                </Box>
                            </Flex>
                        ) : (
                            <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                                <Box w={"100%"} h="100%">
                                    <AiFillLeftCircle
                                        cursor="pointer"
                                        onClick={() => {
                                            setConfiguationCollapseFlag(!configurationCollapseFlag);
                                        }}
                                        size="20px"
                                    />
                                    <Text
                                        fontSize="16px"
                                        mt="2"
                                        fontWeight='bold'
                                        css={{
                                            writingMode: "vertical-lr",
                                            textOrientation: "mixed",
                                        }}>
                                        Location Stats
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Card>
            </Box>
            <AddUpdateLocation isOpen={isOpen} 
                accountUuid={accountUuid}
                close={handleClose} callList={fetchLocationData} 
                    locationData={selectedLocationData} />
        </>
    );
}

export default LocationsListing;
