import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Spacer,
  Icon,
  Button,
  FormControl,
  FormLabel,
  PinInput,
  PinInputField,
  Input,
  VStack, Heading, Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  useDisclosure,
  useColorModeValue,
  Spinner,
  Badge
} from "@chakra-ui/react";
import {
    AiOutlineCheckCircle,
    AiOutlineCopy,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiFillLeftCircle, AiFillRightCircle,
    AiOutlineSync, AiTwotoneAudio,
    AiOutlineVideoCamera
  } from "react-icons/ai";
import moment from "moment";
import { formatTime, formatSeconds} from '../../../utils/TimeFunctions';
import Card from "../../../components/card/Card";

// Api Call Imports
import { fetchAccLocInfo, 
    fetchSubLingoMiniList, 
    getSubscriberCallHistory,
    calculateSubscriberCalls } from "../../../services/accountServices";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useBlackWhiteBg } from "../../../theme/globalColorTheme";
import { useBrandBg, useGradientColor, useBlueButton, useRedButton } from "../../../theme/globalColorTheme";

// Components
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";

const LinkSubscriberCalls = (props) => {
    const toast = useToast();
    const { state } = useLocation();
	const linkDetails = state;
    const navigate = useNavigate();
    const btnRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, isLoading] = useState(false);
    const [clickHappened, isClickHappened] = useState(false);
    const linkStateKey = linkDetails?.linkKey ? linkDetails?.linkKey : null;
	const linkStateUuid = linkDetails?.linkUuid ? linkDetails?.linkUuid : null;
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const [linkId, setLinkId] = useState(linkStateUuid ? linkStateUuid : "");
	const blackWhiteBg = useBlackWhiteBg()

    const currentHost =  window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";

    const textColor = useColorModeValue("secondaryGray.900", "white");
    // const textColor = useColorModeValue("secondaryGray.700", "white");
    const textColorSecondary = "gray.400";
    const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHover = useColorModeValue("blue.400", "red.200");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const pillButtonBgSelected = useColorModeValue("blue.300", "red.400");
	const [selectedValues, setSelectedValues] = useState(['human', 'basicai']);
	const pillButtonTextColorHover = useColorModeValue("secondaryGray.200", "gray.700");

	const [currentSelection, setCurrentSelection] = useState('Active');
	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blueButtonBg = useBlueButton();
	const redButtonBg = useRedButton();

	const [linkChoice, setLinkChoice] = useState("active");
	const [accountUuid, setAccountUuid] = useState("");
	const [locationUuid, setLocationUuid] = useState("");
	const [subscriberId, setSubsriberId] = useState("");
    
    const [linkSessions, setLinkSessions] = useState([]);
    const [linkreport, setLinkReport] = useState([]);    
    const [billAmount, setBillAmount] = useState(0.0);    
    const [linkUrl, setLinkUrl] = useState(null);  
	const [subscriberLinks, setSubscriberLinks] = useState([]);

    	//Fetch Parent Account and Location details
	useEffect(() => {
		collectAccountLocationInfo();
	}, []);

	const collectAccountLocationInfo = () => {
		isLoading(true);
		let paramObj = {
			key: 'subscriber',
		};
		fetchAccLocInfo(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.status) {
					isLoading(false);
					setAccountUuid(res?.data[0]?.accUuid)
					setLocationUuid(res?.data[0]?.locUuid)
                    collectAllSubscriberLinks(linkChoice,res?.data[0]?.accUuid,res?.data[0]?.locUuid);
				} else {
					isLoading(false);
					setAccountUuid("")
					setLocationUuid("")
					setSubsriberId("")
				}
			})
			.catch(err => {
				isLoading(false);
				setAccountUuid("")
				setLocationUuid("")
				setSubsriberId("")
			});
	};

	const collectAllSubscriberLinks = (choice,accountId,locationId) => {
		if (accountUuid || accountUuid != null && locationId || locationUuid !== null) {
			isLoading(true);
			let paramObj = {
				link_account_uuid: accountUuid ? accountUuid : accountId,
				link_location_uuid: locationUuid ? locationUuid : locationId,
				filter_key: "string",
				filter_value: choice,
				records_per_page: 0,
				page_id: 0
			};
			fetchSubLingoMiniList(paramObj)
				.then(res => {
					isLoading(false);
					if (res?.data[0]?.records) {
						setSubscriberLinks(res?.data[0]?.records);
                        if (linkStateKey?.length && linkStateUuid?.length) {
							isClickHappened(true);
							getLinkCalls(linkStateUuid,linkStateKey,accountId,locationId);
						}
					} else {
						setSubscriberLinks([]);
					}
				})
				.catch(err => {
					isLoading(false);
                    setSubscriberLinks([]);
				});
		}
	};

    const renderAllLinks = () => {
        if (subscriberLinks?.length > 0){
            return(
                subscriberLinks.map((linkItem, index) => {
                    return(
                        <Flex 
                            onClick={() => {
                                isClickHappened(true);
                                getLinkCalls(linkItem?.uuid, linkItem?.key);
                                setLinkId(linkItem?.uuid);
                            }}
                            py={2} align={'center'}
                            color={'blue.600'} 
                            _hover={{cursor:'pointer', bg:'blue.200', color:'gray.700'}}
                            w={'100%'} bg={linkId === linkItem?.uuid ? "blue.300" : "blue.100"} mt={1} rounded={'md'}>
                            <Icon as={AiOutlineLink} h={5} w={5} mx={2}/>
                            <Flex w={'100%'} direction={'column'}>
                                <Text>{linkItem?.title}</Text>
                                <Text fontSize={'sm'} key={index}>{currentHost + linkItem?.url}</Text>
                            </Flex>
                        </Flex>
                    )
                })
            )
        }
    }

    const getLinkCalls = (linkUuid, linkKey, accountId, locationId) => {
        if (accountUuid || accountId != null && locationUuid || locationId !== null && linkUuid != null && linkKey != null){
            isLoading(true);
            let paramObj = {
                link_account_uuid: accountUuid ? accountUuid : accountId,
				link_location_uuid: locationUuid ? locationUuid : locationId,
                linkUuid: linkUuid,
                linkKey: linkKey,
                records_per_page: 0,
                page_id: 0,
            };
            getSubscriberCallHistory(paramObj)
            .then((res) => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setLinkSessions(res?.data[0]?.sessions);
                    setLinkReport(res?.data[0]?.report);
                    setLinkUrl(res?.data[0]?.url);
                    calculateBilling(linkUuid, linkKey);
                } else {
                    isLoading(false);
                    setLinkSessions([]);
                    setLinkReport([]);
                    setLinkUrl(null);
                }
            })
            .catch((err) => {
                isLoading(false);
                setLinkSessions([]);
                setLinkReport([]);
                setLinkUrl(null);
            });
        }
    };

    const mergeBillingData = (billingData) => {
        let tempArray = linkSessions;
        for (let i=0;i<tempArray.length;i++){
            if (tempArray[i]?.calls?.length > 0){
                for (let x=0;x<tempArray[i].calls.length;x++){
                    if (tempArray[i]?.calls[x]?.uuid == billingData[i]?.calls[x]?.uuid){
                        console.log(billingData[i]?.calls[x]?.amount);
                        tempArray[i]?.calls[x]?.amount.push({value: billingData[i]?.calls[x]?.amount});
                    }
                }
            }
        }
        setLinkSessions(tempArray);
    }

    const calculateBilling = (linkUuid, linkKey) => {
        if (accountUuid != null && locationUuid !== null && linkUuid != null && linkKey != null){
            isLoading(true);
            let paramObj = {
                link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
                linkUuid: linkUuid,
                linkKey: linkKey
            };
            calculateSubscriberCalls(paramObj)
            .then((res) => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setBillAmount(res?.data[0]?.amount);
                    // mergeBillingData(res?.data[0]?.sessions);
                } else {
                    isLoading(false);
                }
            })
            .catch((err) => {
                isLoading(false);
            });
        }
    };

    const renderSessionCalls = (linkCalls) => {
        if (linkCalls.length > 0){
            return(
                linkCalls?.map((item, index) => {
                    return(
                        <Flex w={'100%'} key={index} bg={item?.type === 'audio' ? 'green.100' : 'blue.100'} p={2} rounded={'md'} borderWidth={'0.05px'} borderColor={'gray.200'}>
                            <Flex minW={'50px'}>
                                <Text >{index+1}</Text>
                            </Flex>
                            <Flex minW={'50px'}>
                                <Box w={'30px'} rounded={'md'} 
                                    bg={item?.type === 'audio' ? 'green.300' : 'blue.300'} align={'center'} pt={1}>
                                {item?.type === 'audio' ? <Icon h={5} w={5} as={AiTwotoneAudio} /> : <Icon as={AiOutlineVideoCamera} />}
                                </Box>
                            </Flex>
                            <Flex minW={'400px'} direction={'column'}>
                                <Text fontSize={'sm'}>
                                    {moment(item?.started).format("MMM DD,YYYY,HH:mm:ss a")}{' - '}{moment(item?.ended).format("MMM DD,YYYY,HH:mm:ss a")}
                                </Text>
                                <Text>From <b>{item?.source_language}</b> to <b>{item?.target_language}</b></Text>
                            </Flex>
                            <Flex minW={'100px'}>
                                <Text>{formatSeconds(Math.round(item?.duration_seconds,0))}</Text>
                            </Flex>
                            <Flex minW={'100px'}>
                                {item?.amount?.length > 0
                                ?<Text>{item?.amount[0]?.value ? '$ ' + parseFloat(item?.amount[0]?.value).toFixed(2) : '$ 0.0'}</Text>
                                :<Text>{'$ 0.0'}</Text>}
                            </Flex>
                            <Flex w={'100%'}>
                                <Text>{item?.agent_info}</Text>
                            </Flex>
                        </Flex>
                    )
                })
            )
        }
    }

    const renderSessions = linkSessions !== null && linkSessions?.length > 0
    ? linkSessions?.map((item, index) => (
        <Flex w={'100%'} key={index} 
            direction={'column'}
            p={2} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Flex w={'100%'}>
                <Flex w={'50px'}>
                    <Text >{index+1}</Text>
                </Flex>
                <Flex w={'100%'} direction={'column'}>
                    <Text fontSize={'sm'}>{moment(item?.created).format("MMM DD,YYYY,HH:mm:ss a")}</Text>
                    <Text fontWeight={500} color={'gray.400'}>{item?.session}</Text>
                </Flex>
                <Flex w={'150px'} direction={'column'}>
                    <Text>Total Calls: {item?.count}</Text>
                </Flex>            
                <Flex w={'250px'} direction={'column'}>
                    <Text>Call Duration: {formatSeconds(Math.round(item?.total,2))}</Text>
                </Flex>            

            </Flex>
            {item?.count > 0
            ?<Flex w={'100%'} direction={'column'} >
                {renderSessionCalls(item?.calls)}
            </Flex>:null}
        </Flex>
    ))
    :<Flex w={'100%'} h={'50px'}>
        <Text bg={'red.100'} py={3}>There are <b>no sessions</b> and <b>calls within those sessions</b> in this dynamic link</Text>
    </Flex>;

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4" >
            <Card w={"100%"} rounded={"md"} p="1px">
                <Flex h="85vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "80%"}
                        bg={brandBg}
                        p="3"
                        borderRadius={"10px"}
                        overflow="hidden">
                        <Card w={'100%'} >
                            {linkSessions?.length > 0
                            ?<Flex w={'100%'} direction={'column'}  align={'center'}>
                                <Flex w={'100%'}  direction={'column'}>
                                    <Flex
                                        w={'100%'}
                                        p="3"
                                        alignItems="center"
                                        borderBottomWidth="1px"
                                        justifyContent={"space-between"}
                                    >
                                        <Text fontWeight={"500"} fontSize={"18px"}>
                                            Dynamic Link Calls ({linkSessions?.length > 0 ? linkSessions?.length : null} Sessions)
                                        </Text>
                                        <Spacer />
                                    </Flex>
                                    {loading
                                    ?<Flex w={'100%'} p={10} direction={'column'} align={'center'}>
                                        <Spinner size={'xl'} />
                                    </Flex>
                                    :<Flex w={'100%'} direction={'column'}>
                                        <Flex 
                                            direction={'column'}
                                            w={'100%'}
                                            h="calc(100vh - 285px)"
                                            overflow="auto"
                                            borderTopWidth="0px"
                                            css={{
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                width: '6px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                borderRadius: '24px',
                                            },
                                            }}>
                                            {renderSessions}
                                        </Flex>
                                        {linkreport?.length > 0
                                        ?<Flex w={'100%'} rounded={'md'} h={'40px'} bg={'gray.300'} align={'center'}>
                                            <Box align={'start'}>
                                                <Text ml={1}>Sessions: <Badge>{linkreport[0]}</Badge></Text>
                                            </Box>
                                            <Box align={'start'} ml={2}>
                                                <Text ml={1}>Calls (in all sessions): <Badge>{linkreport[1]}</Badge></Text>
                                            </Box>
                                            <Box align={'start'} ml={2}>
                                                <Text ml={1}>Time used (in all sessions): <Badge>{formatSeconds(Math.round(linkreport[2],2))}</Badge></Text>
                                            </Box>
                                            <Spacer />
                                            {billAmount >= 0.0
                                            ?<Box align={'end'} ml={2}>
                                                <Text ml={1}>Charged $ Amount (in all sessions): <Badge bg={'green.200'}>${billAmount.toFixed(2)}</Badge></Text>
                                            </Box>:null}
                                            <Box 
                                                onClick={() => calculateBilling()}
                                                align={'end'} ml={2} mr={'1'}>
                                                <Button size={'xs'}>Calculate Amount</Button>
                                            </Box>
                                        </Flex>
                                        :<Flex py={2} w={'100%'} h={'30px'} bg={'gray.300'} align={'center'}>
                                            <Text ml={1}>No Report...</Text>
                                        </Flex>}
                                    </Flex>}
                                </Flex>
                            </Flex>
                            :<Flex w={'100%'} direction={'column'} align={'start'}>
                                {!clickHappened
                                ?<Text w={'100%'} p={2} bg={'red.100'} color="#gray.800" fontSize={"16px"} fontWeight={"normal"}>
                                    Please select a link from your right to get all the calls.
                                </Text>
                                :<Text w={'100%'} p={2} bg={'yellow.100'} color="#gray.800" fontSize={"16px"} fontWeight={"normal"}>
                                    There is no session data for select LingoLink!!
                                </Text>}
                            </Flex>}
                        </Card>
                    </Box>
                    {configurationCollapseFlag ? (
                        <Flex minW={"305px"} maxW={"605px"} overflow={"hidden"} overflowY={"auto"}
                        css={{
                            "&::-webkit-scrollbar": {
                                width: "4px",
                                height: "4px",
                            },
                            "&::-webkit-scrollbar-track": {
                                width: "4px",
                                height: "4px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "transparent",
                                borderRadius: "10px",
                            },
                        }}>
                            <Box
                                minW="300px"
                                maxW={"300px"}
                                position="relative"
                                p="3"
                                borderRadius="15px"
                                bg={brandBg}
                                ml="2">
                                <Text fontWeight={'500'}>LingoLinks</Text>
                                <Box w={'100%'} align={'center'}>
                                    <Flex minW={'170px'} maxW={'170px'} maxH={'50px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
                                        <Text
                                            minW={'70px'} maxW={'70px'}
                                            _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                            onClick={() => {
                                                setCurrentSelection('Active');
                                                collectAllSubscriberLinks("active")
                                            }}
                                            p={2} roundedLeft={'md'}
                                            color={currentSelection === 'Active' ? 'white' : textColor}
                                            bg={currentSelection === 'Active' ? pillButtonBgSelected : pillButtonBg}>
                                            Active
                                        </Text>
                                        <Text
                                            minW={'80px'} maxW={'80px'}
                                            _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                            onClick={() => {
                                                setCurrentSelection('closed');
                                                collectAllSubscriberLinks("closed")
                                            }}
                                            color={currentSelection === 'closed' ? 'white' : textColor}
                                            p={2} roundedRight={'md'}
                                            bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
                                            Closed
                                        </Text>
                                    </Flex>
                                </Box>
                                <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                    <AiFillRightCircle
                                        cursor="pointer"
                                        size="20px"
                                        onClick={() => {
                                            setConfiguationCollapseFlag(!configurationCollapseFlag);
                                        }}
                                    />
                                </Box>
                                <Box w="100%" h="90%" bg={blackWhiteBg} borderRadius={'10px'} mt="3" >
                                    {renderAllLinks()}
                                </Box>
                            </Box>
                        </Flex>
                    ) : (
                        <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}>
                                    LingoLinks
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
};

export default LinkSubscriberCalls;
