// React Chakra Imports
import Card from "../../../components/card/Card";
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    HStack,
    VStack,
  Spinner,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import { getEmailId } from "../../../utils/localStorageIndex";
import InviteLanguageAssociate from "./InviteLanguageAssociate";
import { LangAssociateDataHeader } from "./LangAssociateHeader";
import EditableLangAssociateList from "./EditableLangAssociateList";

// Services Import
import { fetchAllLanguageAssociatesPaginated } from "../../../services/businessServices";

function LanguageAssociateList() {
  const toast = useToast();
  const email = getEmailId();
  const [currentPage, setCurrentPage] = useState("view");
  const [tableData, setTableData] = useState([]);
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    fetchBusinessAssociatesDetails();
  }, []);

  const fetchBusinessAssociatesDetails = () => {
    isLoading(true);
    let objBody = {
      email: getEmailId(),
      team_only: true,
      records_per_page: 0,
      page_id: 0,
      records_length: 0,
    };
    fetchAllLanguageAssociatesPaginated(objBody)
      .then((res) => {
        isLoading(false);
        if (res.data.length > 0 && res.result === true) {
          setNoRecordFlag(false);
          setTableData(res.data[0].records);
        } else if (res.result === false) {
          toast({
            title: res?.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        } else {
          setNoRecordFlag(true);
          toast({
            title: res?.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        isLoading(false);
        setNoRecordFlag(true);
        toast({
          title: err?.message,
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const reRenderTableData = (data) => {
    fetchBusinessAssociatesDetails();
  };

  const reRenderAssociateList = (data) => {
    if (data) {
      setCurrentPage("view");
      fetchBusinessAssociatesDetails();
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px" p={5}>
        <HStack w={"100%"} justifyContent={"end"}>
          {currentPage === "view" ? (
            <Button onClick={() => setCurrentPage("invite")}>
              Invite Language Associate
            </Button>
          ) : null}
          {currentPage === "invite" ? (
            <Button
              onClick={() => {
                setCurrentPage("view");
                fetchBusinessAssociatesDetails();
              }}
            >
              Back to Language Associate
            </Button>
          ) : null}
        </HStack>
      </Card>
      {currentPage === "invite" ? (
        <Card px="0px" p={10} mt={2}>
          <InviteLanguageAssociate
            associateFlag={reRenderAssociateList}
            currentStatus={currentPage}
          />
        </Card>
      ) : null}
      {currentPage === "view" ? (
        <Card px="0px" p={10} mt={2}>
          <Box w={"100%"}>
            {loading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="10"
                h="50vh"
              >
                <Spinner size="xl" />
              </Flex>
            ) : (
              <>
                <VStack w={"100%"}>
                  <Card px="0px">
                    {loading ? (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        mt="10"
                        h="50vh"
                      >
                        <Spinner size="xl" />
                      </Flex>
                    ) : tableData && tableData.length > 0 ? (
                      <>
                        <EditableLangAssociateList
                          emailId={email}
                          tableData={tableData}
                          columnsData={LangAssociateDataHeader}
                          onDataReceived={reRenderTableData}
                        />
                      </>
                    ) : (
                      <Box w={"100%"} p={5}>
                        <Alert
                          borderRadius="8px"
                          status="error"
                          variant="subtle"
                        >
                          <Flex>
                            <AlertIcon />
                            <Flex direction="column">
                              <AlertTitle mr="12px">
                                Empty Language Associates List
                              </AlertTitle>
                              <AlertDescription>
                                There are no Language Associates!!
                              </AlertDescription>
                            </Flex>
                          </Flex>
                        </Alert>
                      </Box>
                    )}
                  </Card>
                </VStack>
              </>
            )}
          </Box>
        </Card>
      ) : null}
    </Flex>
  );
}

export default LanguageAssociateList;
