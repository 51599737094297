
import React, { useState, useEffect } from 'react'
import { Box, Text, Button, useToast } from "@chakra-ui/react"
import InputField from "../../../components/fields/InputField";
import { addSubscriber, updateSubscriber } from '../../../services/accountServices';


function AddUpdateSubcriber({ link_account_uuid, link_location_uuid, subscriberData, reListSubscriber }) {
    const toast = useToast()
    const [error, setError] = useState({});
    const [loading, isLoading] = useState(false)
    const [subsId, setSubsId] = useState('')
    const [inviteSubscriber, setInviteSubscriber] = useState({
        link_location_uuid: link_location_uuid,
        link_account_uuid: link_account_uuid,
        first_name: "",
        last_name: "",
        subscriber_email: "",
    });

    useEffect(() => {
        if (subscriberData !== null || subscriberData !== undefined) {
            let obj = {
                link_location_uuid: link_location_uuid,
                link_account_uuid: link_account_uuid,
                first_name: subscriberData?.first_name,
                last_name: subscriberData?.last_name,
                subscriber_email: subscriberData?.account_subscriber_email,
            }
            setInviteSubscriber(obj)
            setSubsId(subscriberData?.user_uuid)
        }
        else {
            setSubsId("")
        }
    }, [subscriberData])

    // Todo:Update Object 
    const onUpdateField = (e) => {
        const nextFormState = {
            ...inviteSubscriber,
            [e.target.name]: e.target.value,
        };
        setInviteSubscriber(nextFormState);
    };

    // Todo:Validate Form Field
    const validateFieldForm = () => {
        let fields = inviteSubscriber;
        let errors = {};
        let formIsValid = true;
        let emailPattern = new RegExp(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        );
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "*First Name is required.";
        }
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "*Last Name is required.";
        }
        if (!fields["subscriber_email"]) {
            formIsValid = false;
            errors["subscriber_email"] = "*Email is required.";
        } else if (!emailPattern.test(fields["subscriber_email"])) {
            formIsValid = false;
            errors["subscriber_email"] = "*Please enter valid email address.";
        }
        setError(errors);
        return formIsValid;
    };

    // Todo:Submit Data
    const collectSubscriberData = (event) => {
        event.preventDefault();
        if (validateFieldForm()) {
            isLoading(true);
            if (subsId) {
                inviteSubscriber.uuid = subsId; //Todo:Change key uuid as added in backend to update the data
                updateSubscriber(inviteSubscriber)
                    .then((res) => {
                        isLoading(false);
                        if (res.result) {
                            toast({
                                title: res.message,
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                        } else {
                            isLoading(false);
                            toast({
                                title: res.message,
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                            reListSubscriber(true)
                        }
                        resetData()
                    })
                    .catch((err) => {
                        isLoading(false);
                        if (err)
                            toast({
                                title:
                                    "Unable to invite account admin due to system error, please contact Lingolet support!!",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                        resetData()

                    });
            }
            else {
                addSubscriber(inviteSubscriber)
                    .then((res) => {
                        isLoading(false);
                        if (res.result) {
                            if (res?.data[0]?.status){
                                toast({
                                    title: res.message,
                                    status: "success",
                                    isClosable: true,
                                    duration: 3000,
                                });
                                reListSubscriber(true);
                            } else {
                                toast({
                                    title: res.message,
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                });
                            }
                        } else {
                            isLoading(false);
                            toast({
                                title: res.message,
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                            reListSubscriber(true)
                        }
                        resetData()
                    })
                    .catch((err) => {
                        isLoading(false);
                        if (err)
                            toast({
                                title:
                                    "Unable to invite subscriber due to system error, please contact Lingolet support!!",
                                status: "error",
                                isClosable: true,
                                duration: 3000,
                            });
                        resetData()
                    });
            }
        }
    };

    // Todo:Reset fields to invite account 
    const resetData = () => {
        let objData = {
            link_location_uuid: link_location_uuid,
            link_account_uuid: link_account_uuid,
            first_name: "",
            last_name: "",
            subscriber_email: "",
        }
        setInviteSubscriber(objData)
        setSubsId('')
        setError({})
    }

    return (
        <>
            <Text fontWeight={'600'}>{subsId ? 'Update' : 'Invite'} Subscriber</Text>
            <Box w="100%" p="10px" mt="3" bg="#fff" borderRadius={'10px'}>
                <Box w="100%">
                    <InputField
                        mb="0"
                        me="30px"
                        id="first_name"
                        label="First Name"
                        value={inviteSubscriber?.first_name}
                        name="first_name"
                        onChange={onUpdateField}
                        placeholder="Enter First Name"
                        w="100%"
                        required="true"
                        borderRadius={'10px'}
                    />
                    <Text fontSize="sm" color={"red"} mb="5" mt="1">
                        {error?.first_name}
                    </Text>
                </Box>
                <Box w="100%">
                    <InputField
                        mb="0px"
                        id="last_name"
                        label="Last Name"
                        value={inviteSubscriber.last_name}
                        name="last_name"
                        onChange={onUpdateField}
                        placeholder="Enter Last Name"
                        w="100%"
                        required="true"
                        borderRadius={'10px'}
                    />
                    <Text fontSize="sm" color="red" mb="5" mt="1">
                        {error?.last_name}
                    </Text>
                </Box>
                <Box w="100%">
                    <InputField
                        mb="0px"
                        id="subscriber_email"
                        label="Subscriber Email"
                        value={inviteSubscriber.subscriber_email}
                        name="subscriber_email"
                        onChange={onUpdateField}
                        placeholder="Enter Email"
                        w="100%"
                        required="true"
                        borderRadius={'10px'}
                        isDisabled={subsId ? true : false}
                    />
                    <Text fontSize="sm" color="red" mb="5" mt="1">
                        {error?.subscriber_email}
                    </Text>
                </Box>
                {subsId !== null && subsId !== undefined && subsId?.length ? (
                    <>
                        <Button
                            colorScheme="green"
                            mt="3"
                            size="sm"
                            borderRadius={"10px"}
                            onClick={(e) => {
                                collectSubscriberData(e);
                            }}
                            isDisabled={loading ? true : false}
                            isLoading={loading ? true : false}
                        >
                            Update Details
                        </Button>
                        <Button
                            colorScheme="red"
                            mt="3"
                            ml="3"
                            size="sm"
                            borderRadius={"10px"}
                            onClick={(e) => {
                                resetData(e);
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        colorScheme="green"
                        mt="3"
                        size="sm"
                        borderRadius={"10px"}
                        onClick={(e) => {
                            collectSubscriberData(e);
                        }}
                        isDisabled={loading ? true : false}
                        isLoading={loading ? true : false}
                    >
                        Invite Subscriber
                    </Button>)}
            </Box>
        </>
    )
}

export default AddUpdateSubcriber
