import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

// --------------------------------------------------------------
// Account and Location API
// --------------------------------------------------------------

export const fetchLocationInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocationInfo, data, config);
};

export const fetchLocationDetails = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocationList, data, config);
};

export const fetchLocationsAndSubs = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getLocListWithSubs, data, config);
};

export const manageLocationStatus = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageLocationStatus, data, config);
};

export const addNewLocation = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createNewLocation, data, config);
};

export const updateExistingLocation = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateExisingLocation, data, config);
};

export const uploadLocationAvtar = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadLocationAvtar, data, config);
};

export const fetchSubscriberList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubscriberList, data, config);
};

export const manageSubscriberStatus = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubscriberStatus, data, config);
};

export const addSubscriber = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addSubscriber, data, config);
};

export const updateSubscriber = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateSubscriber, data, config);
};

// --------------------------------------------------------------
// Subscriber API
// --------------------------------------------------------------
export const fetchAccLocInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAccLocInfo, data, config);
};
export const fetchSubPersonalInfo = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubPersonalInfo, data, config);
};

export const createSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createSubLingoLink, data, config);
};

export const fetchSubLingoList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubLingoList, data, config);
};
export const fetchSubLingoMiniList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubLingoMiniList, data, config);
};

export const disableSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().disableSubLingoLink, data, config);
};

export const resetSubLingoLinkCode = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().resetSubLingoLinkCode, data, config);
};

export const shareSubLingoLink = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().shareSubLingoLink, data, config);
};

export const getLinkSubscriberDesign = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().fetchSubLinkDesignData, data, config);
};

export const setupSubscriberLinkDesign = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().manageSubscriberLinkDesign, data, config);
};

export const uploadSubscriberLinkDesignImages = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().uploadSubscriberLinkDesign, data, config);
};

export const getSubscriberCallHistory = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubCallHistory, data, config);
};

export const calculateSubscriberCalls = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().calSubCalls, data, config);
};

export const subLingoKeyword = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().subLingoKey, data, config);
};

export const updateLinkFeatures = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addUpdateFeatures, data, config);
};

export const fetchSubscriberTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsriberTranscript, data, config);
};

export const fetchSubscriberTranscriptData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getSubsriberTranscriptData, data, config);
};

// --------------------------------------------------------------
// Events API
// --------------------------------------------------------------

export const fetchAccountEvents = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getAllAdminEvents, data, config);
};

export const fetchEventData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getEventList, data, config);
};

// --------------------
// Subscriber Dashboard Services
// --------------------
export const getSubDbClientLinkCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbSubLingoLinksCount, data, config);
};
export const getSubDbClientEventsCount = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().dbSubClientEventsCnt, data, config);
};

export const lingoEmailTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().lingoEmailTranscript, data, config);
};

export const saveEmailTranscript = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().saveLingoTranscript, data, config);
};

export const storeAccountSubApp = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().saveAccountSubApp, data, config);
};