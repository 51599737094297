import axios from 'axios';
import {
  getAuthToken,
  clearLocalStorage,
} from '../utils/localStorageIndex';

function setBaseUrl() {
  var baseURL;
  // console.log(process.env.REACT_APP_AWS_DEPLOYENT_TYPE);
  // if (process.env.REACT_APP_AWS_DEPLOYENT_TYPE === 'aws') {
  //   console.log(process.env.REACT_APP_BASE_URL_AWS);
  //   baseURL = process.env.REACT_APP_BASE_URL_AWS;
  // } else {
  //   console.log(process.env.REACT_APP_BASE_URL_LOCAL);
  //   baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
  // }
  if (!baseURL) {
    baseURL = 'http://localhost:9110/';
  }
  return baseURL;
}

function setBaseUrlFixed() {
  var baseURL = 'http://ec2-18-188-196-109.us-east-2.compute.amazonaws.com:8500/';
  return baseURL;
}

// Generate axios instance
const instance = axios.create({
  // baseURL: "https://lingolet.ai",
  // baseURL: "https://lspcloud.ai",
  baseURL: "https://languagedog.com",
  // baseURL: "https://lsa4all.com",
  // baseURL: setBaseUrl(),
  // baseURL: setBaseUrlFixed(),
});

instance.interceptors.request.use(
  async requestConfig => {
    let token = await getAuthToken(); // Retrieve the token
    if (token && token.trim().length > 0) {
      requestConfig.headers['Authorization'] = `Bearer ${token}`; // Use the token in the request headers
    }
    return requestConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response.status === 401) {
      clearLocalStorage();
      window.location = '/';
    } else if (error.response.status === 404) {
      window.location = '/';
    }
    throw error;
  }
);

export default instance;
