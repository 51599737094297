import React, { useState, useEffect } from "react";

// Chakra Import
import {
    Button,
    Box,
    Flex,
    Text,
    Icon,
    Image,
    Avatar,
    Switch,
    Spacer,
    Heading,
    Spinner,
    useToast,
    Checkbox,
} from "@chakra-ui/react";
import BasicAIBg from "../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { storeAccountSubApp } from "../../../services/accountServices";

function UpdateAccountAppFeature({selectedData,sourceFeatures,link_account_uuid,link_location_uuid,itemData,reRenderFeautureApp}) {
    const toast = useToast();
    const [selectedValues, setSelectedValues] = useState([]);
    const [updateLoader, setUpdateLoader] = useState(false);

    useEffect(() => {
        if (selectedData?.length) {
            const splitData = selectedData?.split(",");
            setSelectedValues(splitData);
        }
        else {
            setSelectedValues([]);
        }
    }, [selectedData]);

    // Todo:Render Feature Name By Code
    const renderFeatureName = (name) => {
        if (name === 'hi') {
            return 'Human Interpretation';
        } else if (name === 'bi') {
            return 'Basic AI';
        } else if (name === 'pi') {
            return 'Premium AI';
        } else if (name === 'di'){
            return 'Document AI';
        } else if (name === 'mi'){
            return 'Multichannel';
        }
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
        } else if (name === "mi") {
			return MultichannelBg;
		} else return;
	};

    const storeSubscriberAppData = () => {
        setUpdateLoader(false);
        let obj ={
            link_account_uuid:link_account_uuid,
            link_location_uuid:link_location_uuid,
            subscriber_email: itemData?.account_subscriber_email,
            link_apps: selectedValues?.join(',')
        }
        storeAccountSubApp(obj).then((res)=> {
            setUpdateLoader(false);
            if (res?.result) {
                toast(toastFunctionToaster(res.message, "success"));
                reRenderFeautureApp('reRender')
            } else {
                toast(toastFunctionToaster(res.message, "error"));
            }
        })
        .catch(err => {
            setUpdateLoader(false);
            toast(toastFunctionToaster(err.message, "error"));
        });
    }

    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        setSelectedValues(prevValues =>
            prevValues.includes(value)
                ? prevValues.filter(val => val !== value)
                : [...prevValues, value]
        );
    };

  return (
    <>
        {sourceFeatures?.map((appName, index) => {
             const isChecked = selectedValues.includes(appName);
            return (
                <Flex alignItems="center" px="2" py="1" borderWidth="1px" borderColor="#d7d7d7" borderRadius="5px" key={index} mr="2" my="1">
                    <Image mr="2" borderRadius="50px" h="20px" w="20px" src={renderFeatureImage(appName)} />
                    <Text mr="1" fontWeight="600" fontSize="14px">
                        {renderFeatureName(appName)}
                    </Text>
                    <Checkbox isChecked={isChecked} value={appName} onChange={handleCheckboxChange} />
                </Flex>
            );
        })}
        <Button isLoading={updateLoader ? true : false}  borderRadius="10px" colorScheme="green" size="sm" isDisabled={selectedValues.length === 0} onClick={() => storeSubscriberAppData()}>
            Update
        </Button>
    </>
  )
}

export default UpdateAccountAppFeature