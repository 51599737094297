import React, { useState } from 'react'
import { MdUpload } from "react-icons/md";
import axios from "../../../services/axios";

import {
    FormControl,
    Box,
    Text,
    Stack,
    Icon,
    Flex,
    Button,
    Input,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorModeValue
} from '@chakra-ui/react'

// Component Upload
import Card from "../../../components/card/Card.js";
// Api Imports
import { uploadLocationAvtar } from "../../../services/accountServices.js";

function LocationUpload({ locationInfo }) {
    console.log(locationInfo, "locationInfo")

    const [uploadStatus, setUploadStatus] = useState();
    const [uploadFile, setUploadFile] = useState();
    const [uploadValue, setUploadValue] = useState(null)
    const [uploadFileUrl, setUploadFileUrl] = useState();
    const brandColor = useColorModeValue("brand.500", "white");
    const [uploadStatusLoading, setUploadStatusLoading] = useState(false);

    function postObjectData(s3Data, selectedFile) {
        // dispatch(setAccountUploadFlag(false));
        const { url, fields } = s3Data;
        const payload = new FormData();

        // add all the other fields
        Object.entries(fields).forEach(([key, val]) => {
            payload.append(key, val);
        });
        payload.append("file", selectedFile);
        var ax_instance = axios.create();
        delete ax_instance.defaults.headers.common["Authorization"];
        const { data: result } = ax_instance
            .post(url, payload)
            .then((res) => {
                if (res?.data?.length > 0) {
                    setUploadStatus("File is uploaded successfully");
                    setUploadStatusLoading(false)

                } else {
                    setUploadStatus("File is uploaded successfully");
                    setUploadStatusLoading(false)


                }
            })
            .catch((err) => {
                setUploadStatus("Upload failed with Error :" + err);
                setUploadStatusLoading(false)
            });
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            setUploadFile(e.target.files[0]);
            setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    const uploadAccountData = async () => {
        setUploadStatusLoading(true);
        if (uploadFile) {
            let objData = {
                link_account_uuid: locationInfo?.link_account_uuid,
                link_location_uuid: locationInfo?.link_location_uuid,
                content_choice: 'string',
                file_name: uploadFile.name,
                file_type: uploadFile.type,
            };
            console.log(objData, "objData")
            setUploadStatus("Getting upload url for the selected file..");
            const response = await uploadLocationAvtar(objData);
            setUploadStatusLoading(false);
            if (response && response.data && response.data.length > 0) {
                setUploadStatusLoading(false);
                const finalUrl = response.data[0].signedPackage.signedUrl;
                setUploadStatus("Trying to upload the selected file..");
                const s3UploadResult = await postObjectData(finalUrl, uploadFile);
            } else {
                setUploadStatusLoading(false);
                setUploadStatus("Unable to get the upload signed url..");
            }
        }
    };

    return (
        <Accordion defaultIndex={[0]} allowToggle >
            <AccordionItem>
                <h2>
                    <AccordionButton _hover={{ bg: 'none' }}>
                        <Box as='span' flex='1' textAlign='left' fontWeight={'500'}>
                            Upload Avatar
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <Card mb="20px" align="center" p="20px">
                        <Box w={"100%"}>
                            <FormControl cursor="pointer">
                                <Text fontSize={"12px"}>{uploadFileUrl}</Text>
                                <Text mb={4} fontSize={"12px"}>
                                    {uploadFile && `${uploadFile.name} - ${uploadFile.type}`}
                                </Text>
                                <Box
                                    position="relative"
                                    height="200px"
                                    width="100%"
                                    borderWidth="2px"
                                    borderColor={brandColor}
                                    borderRadius={"10px"}
                                    cursor="pointer"
                                >
                                    <Box
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        height="100%"
                                        width="100%"
                                        display="flex"
                                        flexDirection="column"
                                        cursor="pointer"
                                    >
                                        <Stack
                                            height="100%"
                                            width="100%"
                                            display="flex"
                                            alignItems="center"
                                            justify="center"
                                            spacing="4"
                                            cursor="pointer"
                                        >
                                            <Box textAlign="center">
                                                <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
                                                <Flex justify="center" mx="auto" mb="12px">
                                                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                                                        Upload Files
                                                    </Text>
                                                </Flex>
                                                <Text
                                                    fontSize="sm"
                                                    fontWeight="500"
                                                    color="secondaryGray.500"
                                                >
                                                    PNG, JPG and GIF files are allowed
                                                </Text>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Input
                                        type="file"
                                        height="100%"
                                        width="100%"
                                        position="absolute"
                                        top="0"
                                        left="0"
                                        opacity="0"
                                        aria-hidden="true"
                                        accept=".png, .jpg, .jpeg" // Include .pdf here to accept PDF files
                                        cursor="pointer"
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                        {uploadFile ? (
                            <Box
                                w="100%"
                                mb="30px"
                                mt={{ base: "20px", "2xl": "auto" }}
                                alignItems="center"
                            >
                                <Button
                                    me="auto"
                                    mb="2"
                                    variant="brand"
                                    fontWeight="500"
                                    onClick={uploadAccountData}
                                    size="sm"
                                    mt="2"
                                    isDisabled={uploadStatusLoading ? true : false}
                                    isLoading={uploadStatusLoading ? true : false}
                                >
                                    Upload {uploadValue} Image
                                </Button>
                                <Box w={"100%"}>
                                    <Text color="green">{uploadStatus}</Text>
                                </Box>
                            </Box>
                        ) : null}
                    </Card>
                </AccordionPanel>
            </AccordionItem>

        </Accordion>
    )
}

export default LocationUpload
