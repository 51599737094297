import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getEmailId } from "../../../utils/localStorageIndex";

// Chakra Imports
import {
  Text,
  Box,
  Flex,
  Button,
  Select,
  Spinner,
  useToast,
  FormLabel,
  SimpleGrid,
  FormControl,
  useColorModeValue,
} from "@chakra-ui/react";

// Component
import Card from "../../../components/card/Card";
import InputField from "../../../components/fields/InputField";

// Api call
import {
  getBusinessRole,
  inviteNewLanguageAssociate,
} from "../../../services/businessServices";

function InviteLanguageAssociate(props) {
  const toast = useToast();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [associateArr, setAssociateArr] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [associateForm, setAssociateForm] = useState({
    email: getEmailId(),
    first_name: "",
    last_name: "",
    associate_email: "",
    associate_role: "",
  });
  const [retype_associatemail, setRetype_associatemail] = useState("");
  const [retype_associatemailErr, setRetype_associatemailErr] = useState("");

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  useEffect(() => {
    fetchRoleDetails();
  }, []);

  // Todo:Fetch Role Details
  const fetchRoleDetails = () => {
    setSpinnerLoading(true);
    getBusinessRole()
      .then((res) => {
        setSpinnerLoading(false);
        isLoading(false);
        if (res?.data[0]?.associate_roles.length) {
          let parseData = JSON.parse(res?.data[0]?.associate_roles);
          renderAssociateData(parseData);
        }
      })
      .catch((err) => {
        setSpinnerLoading(false);

        isLoading(false);
      });
  };

  const renderAssociateData = (data) => {
    const optionArr = [
      ...(data?.default_roles || []).filter((role) => role?.selected),
      ...(data?.new_roles || []).filter((role) => role?.selected),
    ];
    setAssociateArr(optionArr);
  };

  const onUpdateField = (e) => {
    const nextFormState = {
      ...associateForm,
      [e.target.name]: e.target.value,
    };
    setAssociateForm(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = associateForm;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*First Name is required.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Last Name is required.";
    }
    if (!fields["associate_email"]) {
      formIsValid = false;
      errors["associate_email"] = "*Associate Email is required.";
    } else if (!emailPattern.test(fields["associate_email"])) {
      formIsValid = false;
      errors["associate_email"] = "*Please enter valid email address.";
    }
    if (!fields["associate_role"]) {
      formIsValid = false;
      errors["associate_role"] = "*Associate Role is required.";
    }

    if (!retype_associatemail) {
      formIsValid = false;
      setRetype_associatemailErr("*You must retype the same email.");
    } else if (fields["associate_email"] !== retype_associatemail) {
      formIsValid = false;
      setRetype_associatemailErr("*Email didn't match.");
    }
    setError(errors);
    return formIsValid;
  };

  const submitAssociateData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      inviteNewLanguageAssociate(associateForm)
        .then((res) => {
          isLoading(false);
          if (res.result) {
            toast({
              title: res.message,
              status: "success",
              isClosable: true,
              duration: 3000,
            });
            props?.associateFlag(true);
          } else {
            isLoading(false);
            toast({
              title: res.message,
              status: "error",
              isClosable: true,
              duration: 3000,
            });
            props?.associateFlag(true);
          }
          setRetype_associatemailErr("");
        })
        .catch((err) => {
          isLoading(false);
          if (err)
            toast({
              title:
                "Unable to invite associate due to system error, please contact Lingolet support!!",
              status: "error",
              isClosable: true,
              duration: 3000,
            });
        });
    }
  };

  return (
    <FormControl>
      <Card pb="100px">
        <Flex direction="column" mb="40px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
          Language Associate Information
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Please provide language associate information to join Lingolet platform.
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Flex flexWrap="wrap">
            <InputField
              mb="0"
              me="30px"
              id="first_name"
              label="First Name"
              value={associateForm.first_name}
              name="first_name"
              onChange={onUpdateField}
              placeholder="Enter First Name"
              w="100%"
            />
            <Text fontSize="sm" color={"red"} mb="5" mt="1">
              {error?.first_name}
            </Text>
          </Flex>
          <Flex flexWrap={"wrap"}>
            <InputField
              mb="0px"
              id="last_name"
              label="Last Name"
              value={associateForm.last_name}
              name="last_name"
              onChange={onUpdateField}
              placeholder="Enter Last Name"
              w="100%"
            />
            <Text fontSize="sm" color="red" mb="5" mt="1">
              {error?.last_name}
            </Text>
          </Flex>
          <Flex flexWrap={"wrap"}>
            <InputField
              mb="0px"
              id="associate_email"
              label="Associate Email"
              value={associateForm.associate_email}
              name="associate_email"
              onChange={onUpdateField}
              placeholder="Enter Associate Email"
              w="100%"
            />
            <Text fontSize="sm" color="red" mb="5" mt="1">
              {error?.associate_email}
            </Text>
          </Flex>
          <Flex flexWrap={"wrap"}>
            <InputField
              mb="0px"
              id="retype_associatemail"
              label="Retype Associate Email"
              value={retype_associatemail}
              name="retype_associatemail"
              placeholder="Retype Associate Email"
              onChange={(e) => {
                setRetype_associatemail(e.target.value);
              }}
              w="100%"
            />
            <Text fontSize="sm" color="red" mb="5" mt="1">
              {retype_associatemailErr}
            </Text>
          </Flex>
          {spinnerLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner size="sm" />
            </Flex>
          ) : associateArr?.length ? (
            <Flex direction="column">
              <FormLabel
                ms="10px"
                htmlFor="given_role"
                fontSize="sm"
                color={textColorPrimary}
                fontWeight="bold"
                _hover={{ cursor: "pointer" }}
              >
                Select Associate Role
              </FormLabel>
              <Select
                fontSize="sm"
                id="associate_role"
                name="associate_role"
                variant="main"
                h="44px"
                maxh="44px"
                me="20px"
                color={"GrayText"}
                onChange={onUpdateField}
                onSelect={onUpdateField}
                value={associateForm.associate_role}
                defaultValue={associateForm.associate_role}
                disabled={spinnerLoading}
              >
                <option selected disable>
                  Please select role{" "}
                </option>
                {associateArr?.length ? (
                  associateArr?.map((data, index) => (
                    <option key={index} value={data?.role}>
                      {data?.role}
                    </option>
                  ))
                ) : (
                  <option disabled>No Role Found</option>
                )}
              </Select>
              <Text fontSize="sm" color="red" mb="5" mt="1">
                {error?.associate_role}
              </Text>
            </Flex>
          ) : (
            <Flex direction="column">
              <Text fontWeight={"bold"} mb="3">
                Please click the button below to add Role first, and then you
                can add new associate.
              </Text>
              <Button
                colorScheme="green"
                w="30%"
                size="sm"
                borderRadius={"10px"}
                onClick={() => {
                  navigate("/business/configuration");
                }}
              >
                Add/Update Role
              </Button>
            </Flex>
          )}
        </SimpleGrid>
        <Box w={'100%'} align={'center'} alignItems={'center'}>
            <Button
              colorScheme="blue"
              borderRadius={"10px"}
              minW="183px"
              fontSize="sm"
              fontWeight="500"
              ms="auto"
              onClick={(e) => {
                submitAssociateData(e);
              }}
              isDisabled={associateArr?.length ? false : true}
              isLoading={loading ? true : false}
            >
              Send Invitation
            </Button>
            <Text fontSize={'sm'}>Note: You and above language associate will get an email with password to login and setup their account.</Text>
          </Box>

      </Card>
    </FormControl>
  );
}

export default InviteLanguageAssociate;