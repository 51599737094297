/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// Chakra imports
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Text,
	useToast,
	useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import { HSeparator } from "../../../components/separator/Separator";
import CenteredAuth from "../../../layouts/auth/types/Centered";

// Assets
import illustration from "../../../assets/img/auth/auth.png";

// Theme Colors
import GoogleSignInButton from "../google/GoogleSignIn";

import { registerNewBusinessUser } from "../../../services/businessServices";
import { getPageConfig } from "../../../services/sharedServices";

function RegisterBusinessUser() {
	// Chakra color mode
	const textLinkColor = useColorModeValue("navy", "blue.200");
	const textInvColor = useColorModeValue("white", "white");
	const textColor = useColorModeValue("navy.700", "white");
	const brandStars = useColorModeValue("brand.500", "brand.400");
	const toast = useToast();
	const [error, setError] = React.useState({});
	const [loading, isLoading] = React.useState(false);
	const [policyAccepted, isPolicyAccepted] = React.useState(false);
	const navigate = useNavigate();

	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);

	const [businessUserObj, setBusinessUserObj] = React.useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		business_name: "",
		business_url: "",
		accepted_policy: policyAccepted,
	});
	const [verificationEmail, setVerificationEmail] = React.useState("");
	const [verificationErrMsg, setVerificationErrMsg] = React.useState("");

	const [retype_password, setRetypePassword] = useState("");
	const [retypePasswordErrMsg, setRetypePasswordErrMsg] = useState("");

	const [pageLogo, setPageLogo] = React.useState(null);
	const [pageBackgound, setPageBackground] = React.useState(null);

	const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;

	const pageBackgroundImages = [
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/gray-bg.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_11.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_12.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_13.jpg'
	]

	const errorColor = "red";

	//   Todo:Fetch Business Details
	useEffect(() => {
		if (subdomain != null) {
			let subdomain_val = subdomain.replace(window.location.protocol + "//", "");
			fetchConfDetails(subdomain_val);
		}
	}, []);

	const fetchConfDetails = sub_domain => {
		let paramObj = {
			subdomain: sub_domain, //'fostercity' //
		};
		getPageConfig(paramObj)
			.then(res => {
				if (res?.data?.length > 0) {
					if (res?.data[0]?.logo) {
						setPageLogo(res?.data[0]?.logo);
					}
					if (res?.data[0]?.background) {
						setPageBackground(res?.data[0]?.background);
					}
				}
			})
			.catch(err => {
				// Do Nothing
			});
	};

	const onUpdateField = e => {
		const nextFormState = {
			...businessUserObj,
			[e.target.name]: e.target.value,
		};
		setBusinessUserObj(nextFormState);
	};

	const validateFieldForm = () => {
		let fields = businessUserObj;
		let errors = {};
		let formIsValid = true;

		const checkWhiteSpace = /^\S*$/;
		const checkLoerCase = /^(?=.*[a-z]).*$/;
		const checkUpperCase = /^(?=.*[A-Z]).*$/;
		const checkNumber = /.*[0-9].*/;
		const isValidLength = /^.{8,}$/;

		let emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*New Password Cannot Be Empty.";
		} else if (!checkWhiteSpace.test(fields["new_password"])) {
			formIsValid = false;
			errors["password"] = "*Password must not contain Whitespaces";
		} else if (!checkLoerCase.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must have at least one Lowercase Character";
		} else if (!checkUpperCase.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must have at least one Uppercase Character";
		} else if (!checkNumber.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must have at least one Number";
		} else if (!isValidLength.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must be minimum 8 characters long";
		}

		if (!retype_password) {
			formIsValid = false;
			setRetypePasswordErrMsg("*Confirm Password Cannot Be Empty.");
		} else if (fields["password"] !== retype_password) {
			formIsValid = false;
			setRetypePasswordErrMsg("*New Password didn't match.");
		} else if (fields["password"] === retype_password) {
			setRetypePasswordErrMsg();
		}

		if (!fields["first_name"]) {
			formIsValid = false;
			errors["first_name"] = "*First Name is required.";
		}
		if (!fields["last_name"]) {
			formIsValid = false;
			errors["last_name"] = "*Last Name is required.";
		}
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Email is required.";
		} else if (!emailPattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter valid email address.";
		}
		if (!verificationEmail) {
			formIsValid = false;
			setVerificationErrMsg("*Email is required.");
		} else if (fields["email"] !== verificationEmail) {
			formIsValid = false;
			setVerificationErrMsg("*Email does not match.");
		} else if (verificationEmail) {
			setVerificationErrMsg("");
		}

		if (!fields["business_name"]) {
			formIsValid = false;
			errors["business_name"] = "*Business or Company name is required.";
		}
		if (!fields["business_url"]) {
			formIsValid = false;
			errors["business_url"] = "*Your business url is required.";
		}
		setError(errors);
		return formIsValid;
	};

	const submitBusinessUserData = event => {
		event.preventDefault();
		if (validateFieldForm()) {
			isLoading(true);
			setVerificationErrMsg("");
			var localObj = businessUserObj;
			localObj["accepted_policy"] = policyAccepted;
			registerNewBusinessUser(businessUserObj)
				.then(res => {
					isLoading(false);
					if (res.result) {
						toast({
							title: res?.message,
							status: "success",
							isClosable: true,
							duration: 3000,
						});
						navigate("/verifyEmail", {
							state: {
								user_emailId: businessUserObj?.email,
							},
						});
					} else {
						isLoading(false);
						toast({
							title: res?.message,
							status: "error",
							isClosable: true,
							duration: 3000,
						});
					}
				})
				.catch(err => {
					isLoading(false);
					if (err)
						toast({
							title: err?.response?.data?.detail,
							status: "error",
							isClosable: true,
							duration: 3000,
						});
				});
		}
	};

	const handleClick2 = () => setShow2(!show2);
	const handleClick3 = () => setShow3(!show3);

	return (
		<CenteredAuth 
			illustrationBackground={illustration} image={illustration} 
			cardTop={1}
			pageLogo={pageLogo ? pageLogo: null}
			pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[Math.floor(Math.random() * 4)]}
			>
			<Flex
				maxW={{ base: "100%", md: "max-content" }}
				w="100%"
				mx={{ base: "auto", lg: "0px" }}
				me="auto"
				h="100%"
				alignItems="start"
				justifyContent="center"
				mb={{ base: "30px", md: "60px" }}
				px={{ base: "25px", md: "0px" }}
				mt={{ base: "1px", md: "1vh" }}
				flexDirection="column">
				<Box me="auto">
					<Heading color={textColor} fontSize="36px" mb="10px">
						Register Your Business
					</Heading>
				</Box>
				<Flex
					zIndex="2"
					direction="column"
					w={{ base: "100%", md: "420px" }}
					maxW="100%"
					background="transparent"
					borderRadius="15px"
					mx={{ base: "auto", lg: "unset" }}
					me="auto"
					mb={{ base: "20px", md: "auto" }}>

					{/* <GoogleSignInButton selectedButton={"register"} />
					<Flex align="center" mb="25px" mt="25px">
						<HSeparator />
						<Text color="gray.400" mx="14px">
							or
						</Text>
						<HSeparator />
					</Flex> */}

					<FormControl>
						<Flex>
							<Box w={"100%"}>
								<FormLabel
									display="flex"
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									mb="8px">
									First Name<Text color={brandStars}>*</Text>
								</FormLabel>
								<Input
									isRequired={true}
									variant="auth"
									fontSize="sm"
									ms={{ base: "0px", md: "0px" }}
									type="text"
									fontWeight="500"
									size="md"
									value={businessUserObj.first_name}
									name="first_name"
									onChange={onUpdateField}
									placeholder="First Name"
								/>
								<Text fontSize="sm" color="red" mt="1" mb="4">
									{error?.first_name}
								</Text>
							</Box>
							<Box w={"100%"}>
								<FormLabel
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									isRequired={true}
									display="flex">
									Last Name<Text color={brandStars}>*</Text>
								</FormLabel>

								<InputGroup size="md">
									<Input
										isRequired={true}
										fontSize="sm"
										size="md"
										variant="auth"
										value={businessUserObj.last_name}
										name="last_name"
										onChange={onUpdateField}
										placeholder="Last Name"
									/>
								</InputGroup>
								<Text fontSize="sm" color="red" mt="1" mb="4">
									{error?.last_name}
								</Text>
							</Box>
						</Flex>
						<FormLabel
							ms="4px"
							fontSize="sm"
							fontWeight="500"
							color={textColor}
							isRequired={true}
							display="flex">
							Business Email<Text color={brandStars}>*</Text>
						</FormLabel>
						<InputGroup size="md">
							<Input
								isRequired={true}
								fontSize="sm"
								size="md"
								variant="auth"
								value={businessUserObj.email}
								name="email"
								onChange={onUpdateField}
								placeholder="newuser@userbusinessname.com"
							/>
						</InputGroup>
						<Text fontSize="sm" color="red" mt="1" mb="4">
							{error?.email}
						</Text>
						<FormLabel
							ms="4px"
							fontSize="sm"
							fontWeight="500"
							color={textColor}
							isRequired={true}
							display="flex">
							Confirm Business Email<Text color={brandStars}>*</Text>
						</FormLabel>
						<InputGroup size="md">
							<Input
								isRequired={true}
								fontSize="sm"
								size="md"
								variant="auth"
								value={verificationEmail}
								name="verificationEmail"
								onChange={e => {
									setVerificationEmail(e.target.value);
								}}
								placeholder="Re-Enter Your Business Email"
							/>
						</InputGroup>
						<Text fontSize="sm" color="red" mt="1" mb="4">
							{verificationErrMsg}
						</Text>

						{/*  Business Name */}
						<FormLabel
							ms="4px"
							fontSize="sm"
							fontWeight="500"
							color={textColor}
							isRequired={true}
							display="flex">
							Business Name<Text color={brandStars}>*</Text>
						</FormLabel>
						<InputGroup size="md">
							<Input
								isRequired={true}
								fontSize="sm"
								size="md"
								variant="auth"
								value={businessUserObj.business_name}
								name="business_name"
								onChange={onUpdateField}
								placeholder="Enter Your Business Name"
							/>
						</InputGroup>
						<Text fontSize="sm" color="red" mt="1" mb="4">
							{error?.business_name}
						</Text>
						<FormLabel
							ms="4px"
							fontSize="sm"
							fontWeight="500"
							color={textColor}
							isRequired={true}
							display="flex">
							Your Business Url<Text color={brandStars}>*</Text>
						</FormLabel>
						<InputGroup size="md">
							<Input
								isRequired={true}
								fontSize="sm"
								size="md"
								variant="auth"
								value={businessUserObj.business_url}
								name="business_url"
								id="business_url"
								onChange={onUpdateField}
								placeholder="https://business-url.com"
							/>
						</InputGroup>
						<Text fontSize="sm" color="red" mt="1" mb="4">
							{error?.business_url}
						</Text>
						<Flex w={"100%"}>
							{/* Enter Password  */}
							<Box w={"50%"}>
								<FormLabel
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									isRequired={true}
									display="flex">
									Your Password<Text color={brandStars}>*</Text>
								</FormLabel>
								<InputGroup size={"md"}>
									<Input
										fontSize="sm"
										size="md"
										variant="auth"
										type={show2 ? "text" : "password"}
										value={businessUserObj.password}
										name="password"
										onChange={onUpdateField}
										placeholder="Enter Password"
									/>
									<InputRightElement width="4.5rem">
										<Button
											h="1.75rem"
											size="sm"
											onClick={handleClick2}
											bg="none"
											_hover={{ bg: "none" }}>
											{show2 ? (
												<AiOutlineEye size="sm" color="grey" />
											) : (
												<AiOutlineEyeInvisible size="sm" color="grey" />
											)}
										</Button>
									</InputRightElement>
								</InputGroup>
								<Text fontSize="sm" color={errorColor} mt="2">
									{error?.password}
								</Text>
							</Box>
							{/* ReType Password  */}
							<Box w={"50%"}>
								<FormLabel
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									isRequired={true}
									display="flex">
									Re-Type Password<Text color={brandStars}>*</Text>
								</FormLabel>
								<InputGroup size={"md"}>
									<Input
										fontSize="sm"
										size="md"
										variant="auth"
										type={show3 ? "text" : "password"}
										value={retype_password}
										name="retype_password"
										onChange={e => {
											setRetypePassword(e.target.value);
										}}
										placeholder="Confirm Password"
									/>
									<InputRightElement width="4.5rem">
										<Button
											h="1.75rem"
											size="sm"
											onClick={handleClick3}
											bg="none"
											_hover={{ bg: "none" }}>
											{show3 ? (
												<AiOutlineEye size="sm" color="grey" />
											) : (
												<AiOutlineEyeInvisible size="sm" color="grey" />
											)}
										</Button>
									</InputRightElement>
								</InputGroup>
								<Text fontSize="sm" color={errorColor} mt="1">
									{retypePasswordErrMsg}
								</Text>
							</Box>
						</Flex>

						<Flex justifyContent="space-between" align="center" mb="24px">
							<FormControl display="flex" alignItems="center">
								<Checkbox
									onChange={() => isPolicyAccepted(!policyAccepted)}
									isChecked={policyAccepted}
									id="policy"
									colorScheme="brand"
									me="10px"
								/>
								{/* <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel> */}
								<Text fontSize={"xs"}>
									By checking this box you agree to and have read the{" "}
									<Link
										to="https://lingolet.ai/terms-of-service"
										color={textLinkColor}
										fontWeight={"underline"}
										target="_open">
										<b>Terms of Use</b>
									</Link>{" "}
									and our{" "}
									<Link
										href="https://.ai/privacy-policy"
										isExternal
										color={textLinkColor}
										target="_open">
										<b>Privacy Policy</b>
									</Link>
									.{" "}
								</Text>
							</FormControl>
						</Flex>
						{policyAccepted ? (
							<Button
								fontSize="sm"
								variant="brand"
								fontWeight="500"
								w="100%"
								h="50"
								mb="24px"
								onClick={e => {
									submitBusinessUserData(e);
								}}
								isDisabled={loading ? true : false}
								isLoading={loading ? true : false}>
								Register
							</Button>
						) : (
							<Button
								fontSize="sm"
								borderWidth={"0.5px"}
								borderColor={"gray.500"}
								variant="brand"
								fontWeight="500"
								color={textInvColor}
								isDisabled={true}
								_hover={{
									color: textInvColor,
								}}
								w="100%"
								h="50"
								mb="24px">
								Register
							</Button>
						)}
					</FormControl>
					<Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px"></Flex>
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default RegisterBusinessUser;
