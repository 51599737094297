import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { saveSessionId, saveEmailId, saveAuthToken, savegroupId } from "../../../utils/localStorageIndex";
import { login, getToken, getPageConfig } from "../../../services/sharedServices";
// Chakra imports
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	useToast,
	InputGroup,
	InputRightElement,
	useColorModeValue,
	Text,
} from "@chakra-ui/react";
// Assets
import { FcGoogle } from "react-icons/fc";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

// Custom components
import { HSeparator } from "../../../components/separator/Separator";
import CenteredAuth from "../../../layouts/auth/types/Centered";

function LspLogin() {
	// Defined variables
	const toast = useToast();
	const navigate = useNavigate();

	const [pageLogo, setPageLogo] = React.useState(null);
	const [pageBackgound, setPageBackground] = React.useState(null);

	const [error, setError] = React.useState({});
	const [loading, isLoading] = React.useState(false);
	const [loginForm, setLoginForm] = React.useState({
		email: "",
		password: "",
	});

	const pageBackgroundImages = [
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/gray-bg.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_11.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_12.jpg',
		'https://lingoletpub.s3.us-west-1.amazonaws.com/open/abs_lines_13.jpg'
	]

	const subdomain = window.location.origin.split('.')[0] ? window.location.origin.split('.')[0] : null;
	// Chakra color mode
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = "gray.400";
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
	const textColorBrand = useColorModeValue("brand.500", "white");
	const brandStars = useColorModeValue("brand.500", "brand.400");
	const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
	const buttonBg = useColorModeValue("blue.200", "gray.600");
	const googleText = useColorModeValue("navy.700", "white");
	const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.300" });
	const googleActive = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.200" });

	const [show, setShow] = React.useState(false);
	const handleClick = () => setShow(!show);

	//   Todo:Fetch Business Details
	useEffect(() => {
		if (subdomain != null) {
			let subdomain_val = subdomain.replace(window.location.protocol + "//", '');
			fetchConfDetails(subdomain_val);
		}
	}, []);

	const fetchConfDetails = (sub_domain) => {
		let paramObj = {
			subdomain: sub_domain, //'fostercity' //
		};
		getPageConfig(paramObj)
			.then((res) => {
				if (res?.data?.length > 0) {
					if (res?.data[0]?.logo) {
						setPageLogo(res?.data[0]?.logo);
					}
					if (res?.data[0]?.background) {
						setPageBackground(res?.data[0]?.background)
					}
				}
			})
			.catch((err) => {
				// Do Nothing
			});
	};

	// Todo:Set form value
	const onUpdateField = e => {
		const nextFormState = {
			...loginForm,
			[e.target.name]: e.target.value,
		};
		setLoginForm(nextFormState);
	};

	// Todo:Validation Function
	const validateFieldForm = () => {
		let errors = {};
		let formIsValid = true;
		let fields = loginForm;
		let emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter email.";
		} else if (!emailPattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter valid email address.";
		}
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter password.";
		}
		setError(errors);
		return formIsValid;
	};

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			handleSubmit();
		}
	};

	const handleSubmit = async () => {
		if (validateFieldForm()) {
			isLoading(true);
			try {
				const res = await login(loginForm);
				isLoading(false);
				if (res?.result) {
					if (res?.data[0].email_verified === false) {
						const emailId = res?.data[0].email;
						toast({
							title: "Sorry, the email verification is not completed.. Please check your email for the email verification code or you can resend the email verification code also.",
							status: "success",
							isClosable: true,
							duration: 3000,
						});
						navigate("/verifyEmail", {
							state: {
								user_emailId: emailId,
							},
						});
					} else if (res?.data[0].is_dummy_password === false) {
						const emailId = res?.data[0].email;
						saveEmailId(emailId);

						// Move the token retrieval logic here and await the token
						const formData = new FormData();
						formData.append("grant_type", "");
						formData.append("username", loginForm.email);
						formData.append("password", loginForm.password);
						formData.append("scope", "");
						formData.append("client_id", "");
						formData.append("client_secret", "");
						const tokenRes = await getToken(formData);
						if (tokenRes.access_token != null && tokenRes.access_token.length > 0) {
							saveAuthToken(tokenRes.access_token);
						}

						savegroupId(res?.data[0].group);
						saveSessionId(res?.data[0].user_uuid);
						toast({
							title: "Logged In Successfully",
							status: "success",
							isClosable: true,
							duration: 3000,
						});
						if (res?.data[0].group === "100") {
							navigate("/admin/dashboard");
						}
						if (res?.data[0].group === "200" || res?.data[0].group === "300") {
							navigate("/business/stats");
						}
						if (res?.data[0].group === "500") {
							navigate("/languageassociate/stats");
						}
						if (res?.data[0].group === "600") {
							navigate("/account/dashboard");
						}
						if (res?.data[0].group === "700") {
							navigate("/subscriber/dashboard");
						}

					} else if (res?.data[0].is_dummy_password === true) {
						const emailId = res?.data[0].email;
						toast({
							title: "You have logged in successfully using default password. Please change default password with your own password",
							status: "success",
							isClosable: true,
							duration: 3000,
						});
						navigate("/changeDefaultPassword", {
							state: {
								email: emailId,
							},
						});
					}
				} else {
					toast({
						title: res?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			} catch (err) {
				isLoading(false);
				if (err?.response?.data?.detail?.message) {
					toast({
						title: err?.response?.data?.detail?.message,
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				} else {
					toast({
						title: "An error occurred",
						status: "error",
						isClosable: true,
						duration: 3000,
					});
				}
			}
		}
	};
	return (
		<CenteredAuth
			cardTop={{ base: "140px", md: "14vh" }}
			cardBottom={{ base: "50px", lg: "auto" }}
			pageLogo={pageLogo ? pageLogo : null}
			// pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[Math.floor(Math.random() * 4)]}
			pageBackground={pageBackgound ? pageBackgound : pageBackgroundImages[3]}
		// mx="0px"
		>
			<Box w="100%" className="lsp">
				<Flex
					maxW={{ base: "100%", md: "max-content" }}
					w="100%"
					mx={{ base: "auto", lg: "0px" }}
					me="auto"
					justifyContent="center"
					px={{ base: "20px", md: "0px" }}
					flexDirection="column">
					<Box me="auto">
						<Heading color={textColor} fontSize="36px" mb="10px">
							Sign In
						</Heading>
						<Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
							Enter your email and password to sign in!
						</Text>
					</Box>
					<Flex
						zIndex="2"
						direction="column"
						w={{ base: "100%", md: "420px" }}
						maxW="100%"
						background="transparent"
						borderRadius="15px"
						mx={{ base: "auto", lg: "unset" }}
						me="auto"
						mb={{ base: "20px", md: "auto" }}>

						{/* <Button
							fontSize="sm"
							me="0px"
							mb="26px"
							py="15px"
							h="50px"
							borderRadius="16px"
							bg={googleBg}
							color={googleText}
							fontWeight="500"
							_hover={googleHover}
							_active={googleActive}
							_focus={googleActive}>
							<Icon as={FcGoogle} w="20px" h="20px" me="10px" />
							Sign in with Google
						</Button>
						<Flex align="center" mb="25px">
							<HSeparator />
							<Text color="gray.400" mx="14px">
								or
							</Text>
							<HSeparator />
						</Flex> */}

						<FormControl>
							<FormLabel
								display="flex"
								ms="4px"
								fontSize="sm"
								fontWeight="500"
								color={textColor}
								mb="8px">
								Email<Text color={brandStars}>*</Text>
							</FormLabel>
							<Input
								isRequired={true}
								variant="auth"
								fontSize="sm"
								ms={{ base: "0px", md: "0px" }}
								type="email"
								placeholder="mail@simmmple.com"
								mb="14px"
								fontWeight="500"
								size="lg"
								name="email"
								onChange={onUpdateField}
								value={loginForm.email}
							/>
							<Text color="red" mb="2">
								{error?.email}
							</Text>
							<FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
								Password<Text color={brandStars}>*</Text>
							</FormLabel>
							<InputGroup size="md">
								<Input
									isRequired={true}
									fontSize="sm"
									ms={{ base: "0px", md: "4px" }}
									placeholder="Min. 8 characters"
									mb="14px"
									size="lg"
									type={show ? "text" : "password"}
									variant="auth"
									name="password"
									value={loginForm.password}
									onChange={onUpdateField}
									onKeyPress={handleKeyPress}
								/>
								<InputRightElement display="flex" alignItems="center" mt="4px">
									<Icon
										color={textColorSecondary}
										_hover={{ cursor: "pointer" }}
										as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
										onClick={handleClick}
									/>
								</InputRightElement>
							</InputGroup>
							<Text color="red" mb="2">
								{error?.password}
							</Text>
							<Flex justifyContent="space-between" align="center" mb="24px">
								<FormControl display="flex" alignItems="center">
									<Checkbox id="remember-login" colorScheme="brand" me="10px" />
									<FormLabel
										htmlFor="remember-login"
										mb="0"
										fontWeight="normal"
										color={textColor}
										fontSize="sm">
										Keep me logged in
									</FormLabel>
								</FormControl>
								<NavLink to="/forgot-password">
									<Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
										Forgot password?
									</Text>
								</NavLink>
							</Flex>
							<Button
								fontSize="sm"
								// variant="brand.100"
								bg={buttonBg}
								color={googleText}
								fontWeight="500"
								w="100%"
								h="50"
								mb="24px"
								onClick={() => {
									handleSubmit();
								}}
								isDisabled={loading ? true : false}
								isLoading={loading ? true : false}>
								Sign In
							</Button>
						</FormControl>
						<Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
							<Text color={textColorDetails} fontWeight="400" fontSize="14px">
								Not registered yet?
								<NavLink to="/register">
									<Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
										Create an Account
									</Text>
								</NavLink>
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Box>
		</CenteredAuth>
	);
}

export default LspLogin;
