import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Image,
  Heading,
  Flex,
  Text,
  useDisclosure,
  useColorModeValue
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import { setParentLogo } from "../../../store/actions/topNotificationActions";

// Api Call Imports
import { fetchLocationInfo} from "../../../services/accountServices";

const AccountDashboard = (props) => {
    const dispatch = useDispatch();
    const brandBg = useColorModeValue("blue.50", "navy.900");
	const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const [loading, isLoading] = useState(true);
    const [accountUuid, setAccountUuid] = useState(null);
    const [accountInfo, setAccountInfo] = useState([]);

    //Fetch Parent Account details
    useEffect(() => {
        collectLocationInfo();
    }, [accountUuid]);

    const collectLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'location',
        };
        fetchLocationInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setAccountUuid(res?.data[0]?.uuid);
                    setAccountInfo(res?.data[0]?.info);
                    dispatch(setParentLogo(res?.data[0]?.info[0]?.avatar));
                } else {
                    isLoading(false);
                    setAccountUuid([]);
                    dispatch(setParentLogo(''));

                }
            })
            .catch(err => {
                isLoading(false);
                setAccountUuid([]);
                dispatch(setParentLogo(''));
            });
    };

    const renderAccountInfo =
    accountInfo !== null && accountInfo?.length > 0 ? (
        accountInfo?.map((accountData, index) => (
            <Flex w={'100%'} bg={brandBg} rounded={'md'} h={'100%'} align={'center'}>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                        <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                    </Box>
                    {accountData?.link_account_logo
                        ? <Image src={accountData?.link_account_logo} w={100} h={100} /> : null}
                </Flex>
                <Flex w={"110px"}>
                    <Text bg='blue.100' p={1} rounded={'md'} as="span" ml="3">{accountData?.type}</Text>
                </Flex>
                <Flex w={'100%'} direction={'column'}>
                    <Text ml={3} fontSize={"xs"}>
                        Account Details
                    </Text>
                    <Text as="span" ml="3">{accountData?.name}</Text>
                    <Text as="span" ml="3">{accountData?.title}</Text>
                    <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                </Flex>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                    {accountData?.logo ? <Image src={accountData?.logo} h={100} /> : null}
                    </Box>
                </Flex>

            </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to collect Account Info
                </Heading>
            </Flex>
        );

    const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box h="150px" w="49%" mb="3">
                <Image cursor={'pointer'} key={index} mr="2" borderRadius="10px" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  
        else return;
	};
    
    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1} h={'100px'}>
                {renderAccountInfo}
            </Card>
            <Card w={'100%'}  mx={3}  mt={1} p={1}>
                <Flex h="76vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "70%"}
                        bg={brandBg}
                        p="1"
                        borderRadius={"10px"}
                        overflowY="scroll"
                    >
                        <Text>Account Dashboard Home</Text>
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="30%"
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%">
                                <Flex w={'100%'} direction={'column'} p={5}>
                                    <Text fontSize={'lg'} mb="2">
                                        Accessible LingoLink Apps 
                                    </Text>
                                    <Text fontSize={'xs'} mb="2">
                                        The following Lingolink apps are accessible for you to assign with your 
                                        subscribers specific to the locations or sites you will add. 
                                    </Text>
                                    {accountInfo[0]?.apps != null
                                    ? <Flex justifyContent={'space-between'} flexWrap={'wrap'}> 
                                        {renderAppData(accountInfo[0]?.apps)} 
                                    </Flex>
                                    : <Text>No apps</Text>}
                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                >
                                    Expand Help Topics
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default AccountDashboard;
