import React, { useEffect, useState } from 'react';
import { Text,Spacer,Flex} from '@chakra-ui/react';
import moment from "moment";

// Api Call Imports
import {
    getDbClientLinkCount, getDbClientProjectCount
} from "../../../services/businessServices";

// Component Imports
import CardMetrics from './components/CardMetrics';
import LinkMetrics from './components/LinkMetrics';
import DeviceMetrics from './components/DeviceMetrics';
import WorkflowsMetrics from './components/WorkflowsMetrics';
import { usePillButtonBg, usePillButtonBgSelected, useTextColor } from '../../../theme/globalColorTheme';


function BusinessDashboard() {
    const boostlingoExp = process.env.REACT_APP_BOOSTBOOST_EXP;
    const textColor = useTextColor();
    const pillButtonBg = usePillButtonBg;
	const pillButtonBgSelected = usePillButtonBgSelected;
    const [loading, isLoading] = useState(true);
    const [currentSelection, setCurrentSelection] = useState('links');
    const [linkStats, setLinkStats] = useState([]);
    const [projectStats, setProjectStats] = useState([]);

    //   Todo:Fetch all projects
    useEffect(() => {
        collectLinkCount();
        // collectProjectsCount();
    }, []);

    // const collectAllCounts = () => {
    //     collectLinkCount();
    //     collectProjectsCount();
    // }

    const collectLinkCount = () => {
        isLoading(true);
        let paramObj = {
            time:'',
        };
        getDbClientLinkCount(paramObj)
        .then((res) => {
            isLoading(false);
            if (res?.data?.length > 0) {
                isLoading(false);
                setLinkStats(res?.data);
            } else {
                isLoading(false);
                setLinkStats([]);
            }
        })
        .catch((err) => {
            isLoading(false);
        });
    };

    const collectProjectsCount = () => {
        isLoading(true);
        let paramObj = {
            time:'',
        };
        getDbClientProjectCount(paramObj)
        .then((res) => {
            isLoading(false);
            if (res?.data?.length > 0) {
                isLoading(false);
                setProjectStats(res?.data);
            } else {
                isLoading(false);
                setProjectStats([]);
            }
        })
        .catch((err) => {
            isLoading(false);
        });
    };

    const showExpDate = () => {
        let result = 'error';
        if (boostlingoExp != null && boostlingoExp != undefined){
            var expDate = JSON.parse(boostlingoExp);
            if (moment(expDate.expiresAt).isValid()){
                result = moment(expDate.expiresAt).format("yyyy-MM-DD'T'HH:mm:ss'Z'");
            }
        } 
        return result;
    }

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
            <Flex w={'100%'}  direction={'column'}>
                <Flex
                    w={'100%'}
                    py="1"
                    alignItems="center"
                    borderBottomWidth="1px"
                    justifyContent={"space-between"}
                >
                    <Flex w={'100%'} direction={'column'}>
                        <Text fontWeight={"500"} ml={1} fontSize={"18px"}>
                            Dashboard 
                        </Text>
                        <Text fontSize={'xs'} ml={1}>{showExpDate()}</Text>
                    </Flex>
                    <Spacer />
                    <Flex minW={'420px'} maxW={'420px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
                        <Text 
                            _hover={{cursor:'pointer'}}
                            onClick={() => {
                                collectLinkCount();
                                setCurrentSelection('links');
                            }}
                            p={2} roundedLeft={'md'} 
                            color={currentSelection === 'links' ? 'white' : textColor}
                            bg={currentSelection === 'links' ? pillButtonBgSelected : pillButtonBg}>LingoLink</Text>
                        <Text 
                            _hover={{cursor:'pointer'}}
                            onClick={() => {
                                collectLinkCount();
                                setCurrentSelection('cards');
                            }}
                            color={currentSelection === 'cards' ? 'white' : textColor}
                            p={2} bg={currentSelection === 'cards' ? pillButtonBgSelected : pillButtonBg}pl={5} pr={5}>Lingo Card</Text>
                        <Text 
                            _hover={{cursor:'pointer'}}
                            onClick={() => {
                                setCurrentSelection('workflows');
                                collectProjectsCount();
                            }}
                            color={currentSelection === 'workflows' ? 'white' : textColor}
                            p={2}  bg={currentSelection === 'workflows' ? pillButtonBgSelected : pillButtonBg} pl={5} pr={5}>Document AI</Text>
                        <Text 
                            _hover={{cursor:'pointer'}}
                            onClick={() => {
                                setCurrentSelection('devices');
                            }}
                            color={currentSelection === 'devices' ? 'white' : textColor}
                            p={2} roundedRight={'md'} 
                            bg={currentSelection === 'devices' ? pillButtonBgSelected : pillButtonBg}>Devices</Text>
                    </Flex>
                    {/* <Button ml={1} bg="yellow.200" rounded={'md'} size="sm"
                        onClick={() => {
                            collectAllCounts();
                        }}>
                        <Icon as={AiOutlineSync} />
                    </Button> */}
                </Flex>
            </Flex>
            {currentSelection === 'links'
            ?<LinkMetrics linkStats={linkStats} />:null}
            {currentSelection === 'cards'
            ?<CardMetrics linkStats={linkStats} />:null}            
            {currentSelection === 'workflows'
            ?<WorkflowsMetrics projectStats={projectStats} />:null}
            {currentSelection === 'devices'
            ?<DeviceMetrics />:null}
        </Flex>
    )
}

export default BusinessDashboard;
