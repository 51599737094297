import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const fetchBusinessList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().businessList, data, config);
};

export const fetchBusinessUserList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().businessUserList, data, config);
};

export const fetchTeamListData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().teamList, data, config);
};

export const fetchModuleList = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().moduleList, data, config);
};

export const inviteAdminAssociate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminInviteAssociate, data, config);
};


// -------------
// Workflow Listing
// ------------
export const adminWorkflowJobs = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminWorkflowJobsApi, data, config);
};
export const adminWorkflowData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminWorkflowDataApi, data, config);
};
export const adminWorkflowPerform = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminWorkflowPerformApi, data, config);
};
export const adminWorkflowCollect = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminWorkflowCollectApi, data, config);
};
// -------------
// Automation Workflow Listing
// ------------
export const adminAutoJobsProgress = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminAutoJobsApi, data, config);
};
export const adminAutomationReport = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceAPIs().adminAutoReportApi, data, config);
};

// --------------------
// Device Services
// --------------------
export const addNewDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().addDeviceApi, data, config);
};
export const updateSelectedDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().updateDeviceApi, data, config);
};
export const getDeviceDetails = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getDeviceApi, data, config);
};
export const getDevBusinessList = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getDevBusinessApi, data, config);
};
export const getDevTeamList = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().getDevTeamApi, data, config);
};
export const addBulkDevices = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().addDevicesApi, data, config);
};
export const assignSingleDevice = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().assignDeviceApi, data, config);
};
export const adminDeviceManage = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().manageAdmDevApi, data, config);
};

export const getAllDevices = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().listDevicesApi, data, config);
};

// --------------------
// Lingo Call Services
// --------------------
export const getAllLingoCalls = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().lingolinkCallsList, data, config);
};

// --------------------
// Billing Services
// --------------------
export const getBillSettings = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminBillingSettings, data, config);
};
export const createBillEntry = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminBillingAdd, data, config);
};
export const updateBillEntry = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminBillingUpdate, data, config);
};
export const manageBillEntry = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminBillingManage, data, config);
};
export const pricingBillEntry = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminBillingPricing, data, config);
};
export const getBillServices = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().lingolinkCallsList, data, config);
};
export const getBillEntries = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceAPIs().adminBillingEntries, data, config);
};
