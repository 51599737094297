// Chakra React Imports
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    AiOutlineCheckCircle,
    AiOutlineBulb,
    AiOutlineBackward,
    AiOutlineUserAdd,
    AiOutlineBlock,
} from "react-icons/ai";
import {
    Box,
    Avatar,
    Image,
    Heading,
    Icon,
    Spacer,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    Flex,
} from "@chakra-ui/react";


// Common Components
import Card from "../../../components/card/Card";
import { defaultThemeColor } from "../../../constants";
import { useBrandBg } from "../../../theme/globalColorTheme";

// Component Imports
import LocationDetails from "./LocationDetails";
import SubscriberManagement from "./SubscriberManagement";


function LocationsSubscribers() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const brandBg = useBrandBg();

    const [activeTab, setActiveTab] = useState(0);
    const locationDetails = state?.locationDetails;
    const accountUuid = locationDetails?.link_account_uuid ? locationDetails?.link_account_uuid : null;
    const accountInfo =  state?.accountInfo ? state?.accountInfo : [];

    // Todo:Tab Change
    const handleTabChange = index => {
        setActiveTab(index);
    };

    const renderAccountInfo =
    accountInfo !== null && accountInfo?.length > 0 ? (
        accountInfo?.map((accountData, index) => (
            <Flex w={'100%'} bg={brandBg} rounded={'md'}  h={'100%'} align={'center'}>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                        <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                    </Box>
                    {accountData?.link_account_logo
                        ? <Image src={accountData?.link_account_logo} w={100} h={100} /> : null}
                </Flex>
                <Flex w={"110px"}>
                    <Text bg='blue.100' p={1} rounded={'md'} as="span" ml="3">{accountData?.type}</Text>
                </Flex>
                <Flex w={'50%'} direction={'column'}>
                    <Text ml={3} fontSize={"xs"}>
                        Account Details
                    </Text>
                    <Text as="span" ml="3">{accountData?.name}</Text>
                    <Text as="span" ml="3">{accountData?.title}</Text>
                    <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                </Flex>
                <Flex w={'50%'}>
                    {accountData?.apps != null
                    ?<Text>Supported Apps: {accountData?.apps}</Text>
                    :<Text>No Apps</Text>}
                </Flex>
                <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                    <Box minW={"100px"}>
                    {accountData?.logo ? <Image src={accountData?.logo} h={100} /> : null}
                    </Box>
                </Flex>

            </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to collect Account Info
                </Heading>
            </Flex>
        );


    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt={2} >
            <Card w={"100%"} rounded={"lg"} mt={1} p={1} h={'100px'}>
                {renderAccountInfo}
            </Card>
            <Card w={"100%"} rounded={"lg"}  p={1} mt={1}>
                <Tabs w="100%" borderBottom={"0px"} mx="auto" onChange={handleTabChange} index={activeTab}>
                    <TabList border="0px" w="100%" h="40px">
                        <Flex w="100%">
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineBlock} mr={1} />
                                Account Location or Sub Location Details
                            </Tab>
                            <Tab
                                _selected={{
                                    color: defaultThemeColor,
                                    fontWeight: 600,
                                    borderBottomColor: defaultThemeColor,
                                }}
                                fontSize="16px"
                                px="6"
                                py="2">
                                <Icon as={AiOutlineUserAdd} mr={1} />
                                Subcriber Management
                            </Tab>

                            <Spacer />
                            <Flex
                                p="1"
                                _hover={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate("/account/locations", {
                                    });
                                }}
                                rounded={"md"}
                                bg={"blue.100"}
                                align={"center"}
                                borderRadius={"10px"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                                <Icon color={defaultThemeColor} as={AiOutlineBackward} ml={1} />
                                <Text px={4} p={1} as="span" ml="3" color={defaultThemeColor} fontWeight={"600"}>
                                    Back to Locations List
                                </Text>
                            </Flex>
                            { }
                        </Flex>
                    </TabList>
                    <TabPanels h="94%" p={2}>
                        <TabPanel p={0} h="100%">
                            <LocationDetails locationDetails={locationDetails} />
                        </TabPanel>
                        <TabPanel p={0} h="100%">
                            {accountUuid ? (
                                <SubscriberManagement locationDetails={locationDetails} accountApp={accountInfo[0]?.apps?accountInfo[0]?.apps:[]}/>
                            ) : null}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Card>
        </Flex>
    );
}

export default LocationsSubscribers;
