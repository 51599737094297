// Chakra Import
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Box,
	Flex,
	Text,
	Icon,
	Button,
	Spacer,
	Image,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	useToast,
	Switch,
} from "@chakra-ui/react";
import {
	AiOutlineCheckCircle,
	AiFillCheckSquare,
	AiOutlineCheckSquare,
	AiOutlineCopy,
	AiOutlineLink,
	AiOutlineMail,
	AiOutlineStop,
	AiOutlineSync,
	AiOutlineHolder,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineClockCircle,
} from "react-icons/ai";
// Component Import
import UploadLogoBanner from "../../configuration/UploadLogoBanner";
import Card from "../../../../components/card/Card.js";
// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";

// Api Services
import { getLinkFullDesign, adjustLinkDesign } from "../../../../services/businessServices";

function LinkConfiguration() {
	const { state } = useLocation();
	const toast = useToast();
	const navigate = useNavigate();
	const [loading, isLoading] = useState(true);

	const linkUuid = state?.linkUuid ? state?.linkUuid : null;
	const linkKey = state?.linkKey ? state?.linkKey : null;

	const [linkDesignUuid, setLinkDesignUuid] = useState(null);
	const [linkLogo, setLinkLogo] = useState(null);
	const [linkBackground, setLinkBackground] = useState(null);
	const [linkEnabled, setLinkEnabled] = useState(false);

	//   Todo:Fetch all projects
	useEffect(() => {
		fetchLinkConfiguration();
	}, []);

	const fetchLinkConfiguration = () => {
		if (linkUuid != null && linkKey != null) {
			isLoading(true);
			let paramObj = {
				linkUuid: linkUuid,
				linkKey: linkKey,
			};
			getLinkFullDesign(paramObj)
				.then(res => {
					isLoading(false);
					if (res?.data?.length > 0) {
						if (res?.data[0]?.status) {
							setLinkDesignUuid(res?.data[0]?.uuid);
							setLinkEnabled(res?.data[0]?.enabled);
							if (res?.data[0]?.logo) {
								setLinkLogo(res?.data[0]?.logo);
							}
							if (res?.data[0]?.background) {
								setLinkBackground(res?.data[0]?.background);
							}
						}
					}
				})
				.catch(err => {});
		}
	};

	const manageDesignStatus = status => {
		if (linkDesignUuid != null) {
			let objBody = {
				linkDesignUuid: linkDesignUuid,
				status: status,
			};
			adjustLinkDesign(objBody)
				.then(res => {
					if (res?.result) {
						if (res?.data[0]?.status) {
							toast(toastFunctionToaster(res.message, "success"));
							setLinkEnabled(res?.data[0]?.enabled);
						}
					} else {
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
		}
	};

	const reRenderImageData = data => {
		if (data) {
			fetchLinkConfiguration();
		}
	};

	const launchLinkPrefix = () => {
		if (window.location.pathname.includes("/account")) {
			return "/account";
		} else if (window.location.pathname.includes("/business")) {
			return "/business";
		}
		return null;
	};

	return (
		<Box w={"100%"} direction="column" pt={{ sm: "125px", lg: "75px" }}>
			{linkUuid != null && linkKey != null ? (
				<Box w={"100%"}>
					<Card w={"100%"}>
						<Flex w={"100%"} direction={"column"}>
							<Flex
								w={"100%"}
								p="3"
								alignItems="center"
								borderBottomWidth="1px"
								justifyContent={"space-between"}>
								<Text fontWeight={"500"} fontSize={"18px"}>
									LingoLink Configuration (Design your link Delivery)
								</Text>
								<Spacer />
								<Button
									ml={1}
									bg="blue.100"
									_hover={{ bg: "blue.300" }}
									rounded={"md"}
									size="sm"
									onClick={() => {
										navigate(launchLinkPrefix() + "/linkmanager", {
											state: {
												linkUuid: linkUuid,
											},
										});
									}}>
									Back to Link Manager
								</Button>
							</Flex>
						</Flex>
					</Card>
					<Flex w={"100%"}>
						<Card w={"50%"} mt={1}>
							<Box p="3">
								<Tabs variant="soft-rounded" colorScheme="blue">
									<TabList>
										<Tab borderRadius={"10px"}>Upload Logo</Tab>
										<Tab borderRadius={"10px"}>Upload Background</Tab>
									</TabList>
									<TabPanels>
										<TabPanel w="100%">
											<UploadLogoBanner
												choice={"logo"}
												linkKey={linkKey}
												linkUuid={linkUuid}
												title={"Upload LingoLink Logo Image"}
												fileInfo={
													"The suggestive banner size size is 800x600 or multiple of this size."
												}
												renderLogoData={reRenderImageData}
											/>
										</TabPanel>
										<TabPanel w="100%">
											<UploadLogoBanner
												choice={"banner"}
												linkKey={linkKey}
												linkUuid={linkUuid}
												title={"Upload LingoLink Banner Image"}
												fileInfo={
													"The suggestive business logo size is 180x60 or multiple of this size."
												}
												renderLogoData={reRenderImageData}
											/>
										</TabPanel>
									</TabPanels>
								</Tabs>
							</Box>
						</Card>
						<Card w={"50%"} mt={1} ml={1}>
							{linkLogo || linkBackground ? (
								<Flex w={"100%"} p={2} rounded={"md"} bg={"gray.200"} align={"center"}>
									<Icon
										mr={2}
										h={6}
										w={6}
										color={linkEnabled ? "green" : "red.500"}
										as={linkEnabled ? AiFillCheckSquare : AiOutlineCheckSquare}
									/>
									<Text>
										Currently the design is set as{" "}
										{linkEnabled
											? "enabled, means link customer see this design"
											: "disabled. means it will not be applied"}
										, Change it
									</Text>
									<Switch
										ml={2}
										p={1}
										onChange={() => {
											manageDesignStatus(!linkEnabled);
										}}
										isChecked={linkEnabled ? true : false}
										colorScheme="green"
									/>
								</Flex>
							) : null}
							{linkLogo ? (
								<>
									<Text my="4" fontWeight={"600"}>
										Logo
									</Text>
									<Image src={linkLogo} w={"160px"} />
								</>
							) : (
								<Text bg={"red.100"} p={2}>
									You don't have LingoLink logo set.
								</Text>
							)}
							{linkBackground ? (
								<>
									<Text mt="4" fontWeight={"600"}>
										Background Image{" "}
									</Text>
									<Image mt="4" src={linkBackground} w={"50%"} />
								</>
							) : (
								<Text mt={1} bg={"red.100"} p={2}>
									You don't have LingoLink background set.
								</Text>
							)}
						</Card>
					</Flex>
				</Box>
			) : (
				<Flex w={"100%"} p={2} bg={"red.100"}>
					<Text>Something is not right. please go back to Link Manager</Text>
				</Flex>
			)}
		</Box>
	);
}

export default LinkConfiguration;
