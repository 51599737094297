import * as actionType from "../actionTypes";

const initialState = {
  headerName: "",
  statusColor: "",
  businessInfoStatus: false,
  sideMenuFlag: true,
  bannerFlag: false,
  profileLogoFlag: false,
  customBreadcumArr: [],
  accountUploadFlag: false,
  parentLogo: "",
  homeRoute: false
};

const notificationHeader = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        headerName: action.value,
      };
    }
    case actionType.SET_NOTIFICATION_STATUS_COLOR: {
      return {
        ...state,
        statusColor: action.value,
      };
    }
    case actionType.SET_BUSINESS_INFO_STATUS: {
      return {
        ...state,
        businessInfoStatus: action.value,
      };
    }
    case actionType.SET_EXPAND_MIN_SIDEMENU: {
      return {
        ...state,
        sideMenuFlag: action.value,
      };
    }
    case actionType.SET_BANNER_LOGO: {
      return {
        ...state,
        bannerFlag: action.value,
      };
    }
    case actionType.SET_PROFILE_LOGO: {
      return {
        ...state,
        profileLogoFlag: action.value,
      };
    }
    case actionType.CUSTOM_BREADCRUM: {
      return {
        ...state,
        customBreadcumArr: action.value,
      };
    }
    case actionType.SET_ACCOUNT_UPLOAD_FLAG: {
      return {
        ...state,
        accountUploadFlag: action.value,
      };
    }

    case actionType.SET_PARENT_LOGO: {
      return {
        ...state,
        parentLogo: action.value,
      };
    }

    case actionType.SET_HOME_ROUTE: {
      return {
        ...state,
        homeRoute: action.value,
      };
    }

    default:
      return state;
  }
};
export default notificationHeader;
