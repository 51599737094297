import React from "react";
import { BiUserCircle, BiMoney, BiDollarCircle } from "react-icons/bi";
import { Icon } from "@chakra-ui/react";
import {
  AiOutlineSetting,
  AiOutlineDashboard,
  AiOutlineMobile,
  AiOutlineSelect,
  AiOutlineLink,
  AiOutlineCar,
  AiFillCreditCard,
  AiOutlineGift,
  AiOutlineUngroup,
  AiOutlineSecurityScan,
  AiOutlineUserSwitch,
  AiOutlineUserAdd,
  AiOutlineOrderedList,
  AiOutlineAccountBook,
  AiOutlineMan,
  AiOutlineUser,
  AiOutlineMoneyCollect,
  AiOutlineDollarCircle,
} from "react-icons/ai";

// Business Component Imports
import AccountDashboard from "../komponents/accountmanagement/dashboard/AccountDashboard";
import AccountTeam from "../komponents/accountmanagement/management/AccountTeam";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";
import LocationsListing from "../komponents/accountmanagement/locations/LocationsListing";
import LocationsSubscribers from "../komponents/accountmanagement/locations/LocationSubscribers";

// Events
import LocationEvents from "../komponents/accountmanagement/events/LocationEvents";
import LocationBilling from "../komponents/accountmanagement/billing/LocationBilling";
import LocationCallHistory from "../komponents/accountmanagement/billing/LocationCallHistory";

// LingoLinks
import LinkListing from "../komponents/business/workspace/Links/LinkListing";
import LinkManager from "../komponents/business/workspace/Links/LinkManager";
import LinkSession from "../komponents/business/workspace/Links/LinkSession";
import LinkCalls from "../komponents/business/workspace/Links/LinkCalls";
import LinkConfiguration from "../komponents/business/workspace/Links/LinkConfiguration";


// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";


const accountRoutes = [
  {
    name: "Dashboard",
    path: "/account",
    icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Dashboard",
        layout: "/account",
        path: "/dashboard",
        component: <AccountDashboard />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
      },
    ],
  },
  {
    name: "Location+Subscriber",
    path: "/account",
    icon: <Icon as={AiOutlineAccountBook} width="25px" height="25px" />,
    collapse: true,
    items: [
        {
            name: "Manage",
            layout: "/account",
            path: "/locations",
            component: <LocationsListing />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineUserAdd} width="20px" height="20px" />,
        },
        {
          name: "Manage",
          layout: "/account",
          path: "/locations/subscribers",
          component: <LocationsSubscribers />,
          secondary: true,
          showToMenu: false,
          icon: <Icon as={AiOutlineUserAdd} width="20px" height="20px" />,
      },
      {
        name: "Events",
        layout: "/account",
        path: "/events",
        component: <LocationEvents />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineUngroup} width="20px" height="20px" />,
    },
    ]
  },
  {
    name: "LingoLink",
    path: "/account",
    icon: <Icon as={AiOutlineLink} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
				name: "LingoLink",
				layout: "/account",
				path: "/linklisting",
				component: <LinkListing />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "LingoLink",
				layout: "/account",
				path: "/linkmanager",
				component: <LinkManager />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
			{
				name: "Link Configuration",
				layout: "/account",
				path: "/linkmananger/configuration",
				component: <LinkConfiguration />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
			},
			{
				name: "Dynamic Links",
				layout: "/account",
				path: "/linkmananger/sessions",
				component: <LinkSession />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineLink} width="20px" height="20px" />,
			},
			{
				name: "Dynamic Links",
				layout: "/account",
				path: "/linkmananger/calls",
				component: <LinkCalls />,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSecurityScan} width="20px" height="20px" />,
			},
      ]
  },
  {
    name: "Billing & Payments",
    path: "/account",
    icon: <Icon as={AiOutlineMoneyCollect} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Call History",
        layout: "/account",
        path: "/callhistory",
        component: <LocationCallHistory />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineDollarCircle} width="20px" height="20px" />,
    },
        {
            name: "Billing",
            layout: "/account",
            path: "/billing",
            component: <LocationBilling />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineDollarCircle} width="20px" height="20px" />,
        },
    ],
},
  {
    name: "Settings",
    path: "/account",
    icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
    collapse: true,
    items: [
        {
            name: "Team Members",
            layout: "/account",
            path: "/team",
            component: <AccountTeam />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        },
        {
            name: "Profile",
            layout: "/account",
            path: "/profile",
            component: <BusinessProfile />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineUser} width="20px" height="20px" />,
        }        
    ],
},
  {
    name: "Help",
    path: "/account",
    icon: <Icon as={AiOutlineGift} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Get Help",
        layout: "/account",
        path: "/help",
        component: <Helper />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
      {
        name: "Trust in Platform",
        layout: "/account",
        path: "/trust",
        component: <PlatformTrust />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
    ],
  },
];

export default accountRoutes;
