import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// Chakra Import
import {
	Box,
	Flex,
	Text,
	Image,
	Button,
	Icon,
	Spacer,
	Alert,
	Drawer,
	Spinner,
	Tooltip,
	SimpleGrid,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	AlertIcon,
	FormLabel,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useToast,
	Checkbox,
	CheckboxGroup,
	useColorModeValue,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";
import Card from "../../../components/card/Card";
import {
	AiOutlineCheckCircle,
	AiOutlineStop,
	AiOutlinePlus,
} from "react-icons/ai";
// Api Call Imports
import { createSubLingoLink, fetchSubLingoList, disableSubLingoLink, resetSubLingoLinkCode } from "../../../services/accountServices";
// Component Imports
import { useBrandBg, useGradientColor, useBlueButton, useRedButton, useYellowButton } from "../../../theme/globalColorTheme";
import "react-responsive-pagination/themes/classic.css";

// Components
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";

import LinkEmailWidget from "./LinkEmailWidget";
import LinkItemWidget from "./LinkItemWidget";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function LinkCustomerLinksListing(props) {
	const toast = useToast();
	const btnRef = React.useRef();
	const accountUuid = props?.accountId ? props?.accountId : null
	const locationUuid = props?.locationId ? props?.locationId : null
	const subscriberDetails = props?.subscriberDetails ? props?.subscriberDetails : null;
	const subAppDetails = props?.subAppDetails?.length?props?.subAppDetails.split(','):[]
	const [loading, isLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const type = props?.type
	const [dynamicLinks, setDynamicLinks] = useState([]);
	const [linkChoice, setLinkChoice] = useState("active");
	const navigate = useNavigate();
	const [linkTitle, setLinkTitle] = useState(null);
	const [durationValue, setDurationValue] = useState(48);
	const [showTooltip, setShowTooltip] = React.useState(false);
	const [linkTitleFlag, setLinkTitleFLag] = useState(false);
	const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
	const [linkTrial, setLinkTrial] = useState(false);
	const [voiceCallValue, setVoiceCallValue] = useState(5);
	const [videoCallValue, setVideoCallValue] = useState(5);
	const [callTimeValue, setCallTimeValue] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const textColor = useColorModeValue("secondaryGray.700", "white");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgHover = useColorModeValue("blue.400", "red.200");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const pillButtonBgSelected = useColorModeValue("blue.300", "red.400");
	const pillButtonTextColorHover = useColorModeValue("secondaryGray.200", "gray.700");

	const [selectedValues, setSelectedValues] = useState(subAppDetails);
	const [linkMultichannel, setLinkMultichannel] = React.useState(false);
	const [multiPurposeFlag,setMultipurposeFlag] = useState(null);

	const [currentSelection, setCurrentSelection] = useState('Active');
	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blueButtonBg = useBlueButton();
	const redButtonBg = useRedButton();
	const yellowButtonBg = useYellowButton();

	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};

	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	// Todo:Differentiate based on type
	useEffect(() => {
		const buttonType = props?.button
		if (buttonType?.length) {
			switch (buttonType) {
				case 'active':
				case 'closed':
					getAllDynamicLinks(buttonType);
					break;
				case 'add':
					onOpen();
					break;
				default:
					break;
			}
			props?.resetButton('reset')
		}

	}, [props?.button])

	useEffect(() => {
        if (
            subAppDetails.includes('hi') ||
            subAppDetails.includes('bi') ||
            subAppDetails.includes('pi') ||
            subAppDetails.includes('di')
        ) {
            setMultipurposeFlag(true);
        } else {
            setMultipurposeFlag(false);
			setLinkMultichannel(true)
        }
    }, [subAppDetails]);

	//   Todo:Fetch all projects
	useEffect(() => {
		getAllDynamicLinks(linkChoice);
	}, [currentPage, accountUuid]);

	const getAllDynamicLinks = choice => {
		if (accountUuid != null && locationUuid !== null) {
			isLoading(true);
			let paramObj = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				filter_key: "string",
				filter_value: choice,
				records_per_page: 0,
				page_id: 0
			};
			setLinkChoice(choice);
			fetchSubLingoList(paramObj)
				.then(res => {
					isLoading(false);
					if (res?.data[0]?.records) {
						setDynamicLinks(res?.data[0]?.records);
						setTotalPages(res?.data[0]?.records?.length);
					} else {
						setDynamicLinks([]);
					}
				})
				.catch(err => {
					isLoading(false);
				});
		}
	};

	// Todo:Handle multi-channel value on tag change
	useEffect(() => {
		const filteredData = selectedValues.filter(
			item => linkMultichannel || item !== "mi"
		);
		setSelectedValues(filteredData);
	}, [linkMultichannel]);

	//   Todo:API to create new LingoLink
	const createDynaLink = () => {
		if (linkTitle?.length && accountUuid != null && locationUuid != null && selectedValues.length > 0) {
			let locDurationValue = durationValue;
			let locVideoCallValue = videoCallValue
			let locVoiceCallValue = voiceCallValue
			let locCallTimeValue = callTimeValue;
			if (linkTrial === false) {
				locDurationValue = 8760;
				locVideoCallValue = 1000;
				locVoiceCallValue = 10000;
				locCallTimeValue = 2920;
			}
			setLinkTitleFLag(false);
			let objBody = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				link_title: linkTitle,
				is_trail: linkTrial,
				active_hours: locDurationValue,
				videoCount: locVideoCallValue,
				voiceCount: locVoiceCallValue,
				callTime: locCallTimeValue,
				features: selectedValues.join(","),
				multichannel: linkMultichannel,
				start_time: "",
			};
			setSaveDetailsLoader(true);
			createSubLingoLink(objBody)
				.then(res => {
					setSaveDetailsLoader(false);
					if (res?.result) {
						onClose();
						resetProjectDetails();
						getAllDynamicLinks("active");
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						onClose();
						resetProjectDetails();
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					onClose();
					resetProjectDetails();
					setSaveDetailsLoader(false);
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
			setLinkTitleFLag(true);
		}
	};

	// Todo:Reset Access Code
	const changeLinkAccessCode = (linkUuidx) => {
		if (linkUuidx != null && accountUuid != null && locationUuid !== null) {
			let objBody = {
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
				link_uuid: linkUuidx
			};
			resetSubLingoLinkCode(objBody)
				.then((res) => {
					if (res?.result) {
						if (res?.data[0]?.status) {
							getAllDynamicLinks('active');
							toast(
								toastFunctionToaster(
									"Link access code is successfully refreshed",
									"success"
								)
							);
						} else {
							toast(
								toastFunctionToaster(
									"Failed to refresh the access code...",
									"error"
								)
							);
						}
					} else {
						toast(
							toastFunctionToaster(
								"Failed to refresh the access code...",
								"error"
							)
						);
					}
				})
				.catch((err) => {
					toast(
						toastFunctionToaster(
							"Failed to refresh the access code...",
							"error"
						)
					);
				});
		} else {
			toast(
				toastFunctionToaster("Failed to refresh the access code...", "error")
			);
		}
	};

	// Change Status Of LingoLink
	const disableSelectedLink = (linkUuid) => {
		if (accountUuid != null && locationUuid !== null) {
			let paramObj = {
				link_uuid: linkUuid,
				link_account_uuid: accountUuid,
				link_location_uuid: locationUuid,
			};
			disableSubLingoLink(paramObj)
				.then((res) => {
					if (res?.result && res?.data?.length > 0) {
						if (res?.data[0]?.status) {
							// Reload List
							getAllDynamicLinks("closed");
							setCurrentSelection('closed');
							toast(
								toastFunctionToaster(
									"Selected dynamic link is successfully disabled.",
									"success"
								)
							);
						} else {
							toast(
								toastFunctionToaster(
									"Unable to complete the selected request..",
									"error"
								)
							);
						}
					} else {
						toast(
							toastFunctionToaster(
								"Unable to complete the selected request..",
								"error"
							)
						);
					}
				})
				.catch((err) => {
					toast(
						toastFunctionToaster(
							"Unable to complete the selected request...",
							"error"
						)
					);
				});
		}
	};

	const handleLingoList = (data) => {
		if (data) {
			getAllDynamicLinks('active')
		}
	}

	// Todo:Route To Call History
	const routeToCallHistory = data => {
		navigate("/subscriber/callhistory", {
			state: {
				linkType: data?.active,
				linkUuid: data?.uuid,
				linkKey: data?.key,
			},
		});
	};

	const renderDynamicLinks =
		dynamicLinks !== null && dynamicLinks?.length > 0
			? dynamicLinks?.map((item, index) => (
				<Card
					bg={brandBg}
					key={index}
					w={"100%"}
					mb={2}
					p="0px"
					borderRadius='10px'
					boxShadow="none">
					<Flex w={"100%"}
						bg={item?.active ? "green.100" : "red.100"} 
						direction={"column"} borderWidth={'0.5px'} borderColor={gradientColor} roundedTop="10px" className="abc">
						<Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'} roundedTop={'md'} p={'10px'} borderBottomWidth={'0.5px'} borderColor={gradientColor}>
							{item?.multichannel
							?<Flex w={'50px'}>
								<Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
							</Flex>:null}
							<Flex alignItems={'center'} ml={1} rounded={"md"} color="#000" fontWeight={'500'} px={2}>
								{item?.dead ? (
									<Icon as={AiOutlineStop} color={"red.600"} mr={2} />
								) : (
									<Icon
										as={AiOutlineCheckCircle}
										color={"gray.600"}
										mr={2}
									/>
								)}
								{item?.title}
							</Flex>
							<Spacer />
							<Flex w={'220px'} align={'center'} bg={'blue.200'} pl={1} rounded={'lg'}>
								<Text mr={1}>{item?.code}</Text>
								<Button
									onClick={() => changeLinkAccessCode(item?.uuid)}
									size="sm" bg={blueButtonBg} borderRadius="md">
										
										Change Access Code
								</Button>
							</Flex>
							<LinkEmailWidget
								subscriberEmail={subscriberDetails?.email} linkItem={item} accountId={accountUuid} locationId={locationUuid} />
							<Button mx={1} size="sm" bg={blueButtonBg} borderRadius="md" onClick={() => routeToCallHistory(item)}>
								Call History
							</Button>
							<Button
								ml={1}
								onClick={() => disableSelectedLink(item?.uuid)}
								_hover={{ bg: pillButtonBgHighlight }}
								color={'pillButtonText'}
								bg={redButtonBg}
								size={"sm"}
								borderRadius={'md'}>
								Disable Link
							</Button>
						</Flex>
					</Flex>
					<LinkItemWidget linkItem={item} accountId={accountUuid} locationId={locationUuid} reRenderLingoList={handleLingoList} subAppDetails={subAppDetails}/>
				</Card>
			))
			: null;

	// Todo:Reset Project Details
	const resetProjectDetails = () => {
		setLinkTitle("");
		setDurationValue(48);
		setVoiceCallValue(5);
		setVoiceCallValue(5);
		setLinkTrial(false);
		setLinkTitleFLag(false);
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		resetProjectDetails();
	};

	// Function to handle changes in the CheckboxGroup
	const handleChange = (values) => {
		setSelectedValues(values);
	};

	return (
		<>
			<Box direction="column" pt={{ sm: "0px", lg: "0px" }}>
				<Card p="3" alignItems="center" rounded={'md'}>
					<Flex w={'100%'} direction={'row'} alignItems="center">
						<Text fontWeight={"600"} fontSize={"16px"} justifyContent={'center'}>
							LingoLink {dynamicLinks?.length > 0 ? dynamicLinks?.length : 0} ({linkChoice})
						</Text>
						<Spacer />
						<Flex minW={'440px'} maxW={'440px'} maxH={'50px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
							<Text
								minW={'70px'} maxW={'70px'}
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									setCurrentSelection('Active');
									getAllDynamicLinks("active")
								}}
								p={2} roundedLeft={'md'}
								color={currentSelection === 'Active' ? 'white' : textColor}
								bg={currentSelection === 'Active' ? pillButtonBgSelected : pillButtonBg}>
								Active
							</Text>
							<Text
								minW={'80px'} maxW={'80px'}
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									setCurrentSelection('closed');
									getAllDynamicLinks("closed")
								}}
								color={currentSelection === 'closed' ? 'white' : textColor}
								p={2}
								bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
								Closed
							</Text>
							<Text
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									getAllDynamicLinks("active");
									setCurrentSelection('refresh');
								}}
								color={currentSelection === 'refresh' ? 'white' : textColor}
								p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
								pr={5}>Refresh
							</Text>
							<Text
								_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
								onClick={() => {
									onOpen();
									// setCurrentSelection('new');
								}}
								roundedRight={'md'}
								color={currentSelection === 'new' ? 'white' : textColor}
								p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
								pr={5}>
								<Icon pt={1} as={AiOutlinePlus} mr={1} />
								Create New Link
							</Text>
						</Flex>
					</Flex>
				</Card>
				{/* : null} */}
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h={type === 'lingoCustomer' ? '10vh' : '50vh'}>
						<Spinner size="xl" />
					</Flex>
				) : dynamicLinks?.length ? (
					<Card w={"100%"} borderRadius='10px' mt={3} p={type === 'lingoCustomer' ? '0px' : '10px'}>
						<Flex w={"100%"} rounded={"md"} direction={"column"}>
							{renderDynamicLinks}
						</Flex>
					</Card>
				) : (
					<Alert status="warning" mt="4">
						<AlertIcon />
						<Text>You dont have any LingoLink yet... </Text>
						<Text ml={5}>
							<b>CREATE NEW LINGOLINK!!</b>
						</Text>
					</Alert>
				)}
			</Box>

			{/* {dynamicLinks?.length && linkChoice === "closed" ? (
				<Box mt="4">
					<ResponsivePagination
						current={currentPage}
						total={totalPages}
						onPageChange={pageNumber => {
							setCurrentPage(pageNumber);
						}}
					/>
				</Box>
			) : null} */}

			{/* // Add New Link Drawer */}
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					{subAppDetails?.length ? (
					<>
						<DrawerHeader borderBottomWidth={"1px"}>{"Create New LingoLink"}</DrawerHeader>
						<DrawerBody overflow="hidden" overflowY="scroll" mt="5">
							<Flex w={"100%"} direction={"column"}>
								<InputField
									mb="0"
									me="30px"
									id="link_title"
									label="Link Title"
									value={linkTitle}
									name="link_title"
									required="true"
									borderRadius="5px"
									onChange={e => {
										setLinkTitle(e.target.value);
									}}
									placeholder="Enter Link Title"
									w="100%"
									maxlength={100}
								/>
								<Flex alignItems={"center"} justifyContent={"space-between"}>
									{linkTitleFlag ? (
										<Text fontSize="sm" color={"red"} mt="1">
											Link Title is Required
										</Text>
									) : null}
									<Spacer />
									<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
										{linkTitle?.length}/100
									</Text>
								</Flex>
								
									<Flex w={'100%'} direction={'column'}>
										<Flex w={'100%'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
											{multiPurposeFlag ? (
												<Text
													minW={'50%'} maxW={'50%'}
													_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
													onClick={() => {
														setLinkMultichannel(false);
													}}
													p={2} roundedLeft={'md'}
													color={linkMultichannel === false ? 'white' : textColor}
													bg={linkMultichannel === false ? pillButtonBgSelected : pillButtonBg}>
													Multipurpose LingoLink
												</Text>
											):null}
											{subAppDetails.includes('mi')?(
												<Text
													minW={'50%'} maxW={'50%'}
													_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
													onClick={() => {
														setLinkMultichannel(true);
														setSelectedValues(['mi']);
													}}
													roundedRight={'md'}
													color={linkMultichannel === true ? 'white' : textColor}
													p={2}
													bg={linkMultichannel === true ? pillButtonBgSelected : pillButtonBg}>
													Multichannel LingoLink
												</Text>
											):null}
										</Flex>
									</Flex>
								
								{linkMultichannel === true && subAppDetails.includes('mi')
								?<Flex w={'100%'} bg={'red.50'} rounded={'lg'} mt={1} direction={'column'} p={2}>
									{/* <CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
										<Checkbox value='mi'> */}
											<Flex alignItem='center'>
												<Image h="100px" w="100px" mx="1" borderRadius="50px" src={MultichannelBg} />
												<Flex w={'100%'} direction={'column'}>
													<Text as="span">
														MultiChannel AI
													</Text>
													<Text as="span">
														Main Language:
													</Text>
													<Text as="span">
														<b>English</b>
													</Text>
													<Text as="span">
														Other Language: 
													</Text>
													<Text as="span">
														<b>Selected at Connecting Time</b>
													</Text>
												</Flex>
											</Flex>
										{/* </Checkbox>
									</CheckboxGroup> */}
								</Flex>
								:<Flex w={'100%'} bg={'blue.50'} rounded={'lg'} mt={1} direction={'column'}>
									<Flex
										w={"100%"}
										direction={"column"}
										p={1}
										rounded={"md"}
										borderWidth={"0.5px"}
										borderColor={"gray.200"} mt="3">
										<Flex w={"100%"} align={"center"}>
											<Text mt={1} mb="2" fontWeight={"bold"} fontSize={"sm"}>
												Is it a trial Link?
											</Text>
											<Checkbox
												w={"10px"}
												onChange={() => setLinkTrial(!linkTrial)}
												checked={linkTrial}
												ml={2}></Checkbox>
											<Spacer />
										</Flex>
										{linkTrial ? (
											<Flex w={"100%"} direction={"column"}>
												<FormLabel my="3" ml="2" required>
													<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
														Link Activity Duration (Hours)
													</Text>
													<Flex
														mt={10}
														h={"100px"}
														w={"100%"}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Slider
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={2}
															max={168}
															step={1}
															defaultValue={48}
															onChange={v => setDurationValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={24} {...labelStyles}>
																24
															</SliderMark>
															<SliderMark value={48} {...labelStyles}>
																48
															</SliderMark>
															<SliderMark value={72} {...labelStyles}>
																72
															</SliderMark>
															<SliderMark value={96} {...labelStyles}>
																96
															</SliderMark>
															<SliderMark value={120} {...labelStyles}>
																120
															</SliderMark>
															<SliderMark value={144} {...labelStyles}>
																144
															</SliderMark>
															<SliderMark value={168} {...labelStyles}>
																168
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${durationValue} Hours`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
												</FormLabel>
												<Flex w={"100%"} align={"center"}>
													<Flex
														w={"50%"}
														h={"100px"}
														direction={"column"}
														py={1}
														px={5}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Text>
															Voice Call Count: <b>{voiceCallValue}</b>
														</Text>
														<Slider
															mt={8}
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={1}
															max={50}
															step={1}
															defaultValue={5}
															onChange={v => setVoiceCallValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={1} {...labelStylesVoice}>
																1
															</SliderMark>
															<SliderMark value={25} {...labelStylesVoice}>
																25
															</SliderMark>
															<SliderMark value={50} {...labelStylesVoice}>
																50
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${voiceCallValue}`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
													<Flex
														ml={1}
														w={"50%"}
														h={"100px"}
														direction={"column"}
														py={1}
														px={5}
														rounded={"md"}
														borderWidth={"0.5px"}
														borderColor={"gray.200"}>
														<Text>
															Video Call Count: <b>{videoCallValue}</b>
														</Text>
														<Slider
															mt={8}
															aria-label="slider-ex-1"
															colorScheme="teal"
															min={1}
															max={50}
															step={1}
															defaultValue={5}
															onChange={v => setVideoCallValue(v)}
															onMouseEnter={() => setShowTooltip(true)}
															onMouseLeave={() => setShowTooltip(false)}>
															<SliderMark value={1} {...labelStylesVoice}>
																1
															</SliderMark>
															<SliderMark value={25} {...labelStylesVoice}>
																25
															</SliderMark>
															<SliderMark value={50} {...labelStylesVoice}>
																50
															</SliderMark>
															<SliderTrack>
																<SliderFilledTrack />
															</SliderTrack>
															<Tooltip
																hasArrow
																bg="teal.500"
																color="white"
																placement="top"
																isOpen={showTooltip}
																label={`${videoCallValue}`}>
																<SliderThumb />
															</Tooltip>
														</Slider>
													</Flex>
												</Flex>
												<Flex
													mt="3"
													w={"100%"}
													h={"100px"}
													direction={"column"}
													py={1}
													px={5}
													rounded={"md"}
													borderWidth={"0.5px"}
													borderColor={"gray.200"}>
													<Text>
														Maximum minutes available in this Trial call: <b>{callTimeValue}</b>{" "}
														minutes.
													</Text>
													<Slider
														mt={10}
														aria-label="slider-ex-1"
														colorScheme="teal"
														min={5}
														max={600}
														step={5}
														defaultValue={5}
														onChange={v => setCallTimeValue(v)}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}>
														<SliderMark value={5} {...labelStylesVoice}>
															5
														</SliderMark>
														<SliderMark value={100} {...labelStylesVoice}>
															100
														</SliderMark>
														<SliderMark value={200} {...labelStylesVoice}>
															200
														</SliderMark>
														<SliderMark value={300} {...labelStylesVoice}>
															300
														</SliderMark>
														<SliderMark value={400} {...labelStylesVoice}>
															400
														</SliderMark>
														<SliderMark value={500} {...labelStylesVoice}>
															500
														</SliderMark>
														<SliderMark value={600} {...labelStylesVoice}>
															600
														</SliderMark>
														<SliderTrack>
															<SliderFilledTrack />
														</SliderTrack>
														<Tooltip
															hasArrow
															bg="teal.500"
															color="white"
															placement="top"
															isOpen={showTooltip}
															label={`${callTimeValue}`}>
															<SliderThumb />
														</Tooltip>
													</Slider>
												</Flex>

											</Flex>
										) : null}
									</Flex>
									<Flex w={'100%'} rounded={'md'}
										mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
										<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
										<CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
											<SimpleGrid columns={2} gap="2px" mt="2" >
												<Checkbox value='hi' display={subAppDetails.includes('hi')?'flex':'none'}>
													<Flex alignItem='center'>
														<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={HumanAIBg} />
														<Text as="span">
															Human Interpretation
														</Text>
													</Flex>
												</Checkbox>
												<Checkbox value='bi' display={subAppDetails.includes('bi')?'flex':'none'} ml={2}>
													<Flex alignItem='center'>
														<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={BasicAIBg} />
														<Text as="span" >
															Basic AI
														</Text>
													</Flex>
												</Checkbox>
												<Checkbox value='pi' display={subAppDetails.includes('pi')?'flex':'none'}>
													<Flex alignItem='center'>
														<Image h="40px" w="40px" mx="1" borderRadius="50px" src={PremiumAIBg} />
														<Text as="span" >
															Premium AI
														</Text>
													</Flex>
												</Checkbox>
												<Checkbox value='di' display={subAppDetails.includes('di')?'flex':'none'} ml={2}>
													<Flex alignItem='center'>
														<Image h="40px" w="40px" mx="1" borderRadius="50px" src={DocumentAiLightBg} />
														<Text as="span">
															Document AI
														</Text>
													</Flex>
												</Checkbox>
											</SimpleGrid>
										</CheckboxGroup>
									</Flex>
								</Flex>}
							</Flex>
						</DrawerBody>
						<DrawerFooter>
							<Flex w={"100%"} direction={"row"}>
								<Button
									variant="outline"
									mr={3}
									onClick={() => {
										onClose();
										resetProjectDetails();
									}}>
									Cancel
								</Button>
								<Button
									colorScheme="blue"
									onClick={() => {
										resetProjectDetails();
										createDynaLink();
									}}
									isDisabled={
										!selectedValues.length || saveDetailsLoader ? true : false
									}
									isLoading={saveDetailsLoader ? true : false}>
									{"Create New Link"}
								</Button>
							</Flex>
						</DrawerFooter>
					</>	
				):(<Text p="2" bg="yellow.300"  fontWeight={'600'}>No Apps Available</Text>)}
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default LinkCustomerLinksListing;