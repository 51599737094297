// chakra imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
	Icon,
	Spinner,
	Box,
	Flex,
	Stack,
	Text,
	Image,
	useColorModeValue,
} from "@chakra-ui/react";
import klogo from "../../../media/images/Lingolet-2C-large.png";

//   Custom components
import { AiOutlineMenu } from "react-icons/ai";
import Links from "../../../components/sidebar/components/Links";
import { useCustomColorModeValues } from "../../../utils/ColorMode"
import avatar4 from "../../../assets/img/avatars/dummy-avatar.jpg";
import {
	clearLocalStorage,
	getEmailId,
} from "../../../utils/localStorageIndex";
import { fetchPersonalInfo, logout } from "../../../services/sharedServices";
import { fetchBusinessInformation } from "../../../services/businessServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBannerFlag, setParentLogo } from "../../../store/actions/topNotificationActions";

// FUNCTIONS

function SidebarContent(props) {
	const emaiId = getEmailId();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { borderColor } = useCustomColorModeValues();
	const [loading, isLoading] = useState(true);
	const [businessLogo, setBusinessLogo] = useState("");
	const [userDetails, setUserDetails] = useState({});
	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { profileLogoFlag, bannerFlag, parentLogo } = sideMenuStatus;
	
	useEffect(() => {
		if (parentLogo) {
			setBusinessLogo(parentLogo)
		}
	},[sideMenuStatus])

	useEffect(() => {
		isLoading(true);
		let objData = {
			email: emaiId,
		};
		fetchPersonalInfo(objData)
			.then(res => {
				isLoading(false);
				if (res.result) {
					setUserDetails(res.data[0]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	}, [profileLogoFlag]);

	useEffect(() => {
		let objData = {
			email: emaiId,
		};
		fetchBusinessInformation(objData)
			.then(res => {
				if (res?.result) {
					if (res?.data[0]?.business_logo_url != null) {
						if (res?.data[0]?.business_logo_url.length > 0) {
							setBusinessLogo(res?.data[0]?.business_logo_url);
							dispatch(setBannerFlag(false));
						} else {
							setBusinessLogo(klogo);
							dispatch(setBannerFlag(false));
						}
					} else {
						setBusinessLogo(klogo);
						dispatch(setBannerFlag(false));
					}
				} else {
					setBusinessLogo(klogo);
					dispatch(setBannerFlag(false));
				}
			})
			.catch(err => {
				console.log(err);
			});
	}, [bannerFlag]);

	const { routes, mini, hovered } = props;
	const textColor = useColorModeValue("navy.700", "white");

	const systemLogout = () => {
		var apiData = { email: emaiId };
		isLoading(true);
		logout(apiData)
			.then(res => {
				dispatch(setParentLogo(''));
				isLoading(false);
				if (res.result) {
					navigate("/login");
					clearLocalStorage();
				} else {
					isLoading(false);
				}
			})
			.catch(err => {
				dispatch(setParentLogo(''));
				isLoading(false);
			});
	};

	// SIDEBAR
	return (
		<Flex
			direction="column"
			height="100%"
			pt="25px"
			borderRadius="30px"
			mb="66px"
			w="100%"
		>
			<Box borderBottom="1px" borderColor={'#efefef'}>
				{mini ? (
					<Icon
						as={AiOutlineMenu}
						w="30px"
						h="30px"
						mt={{ base: "6px" }}
						ms={{ base: "32px" }}
						mb="20px"
						cursor="pointer"
					/>
				) : businessLogo ? (
					<Image
						h="60px"
						w="60%"
						src={businessLogo}
						objectFit={"contain"}
						mx="auto"
						mb="20px"
					/>
				) : (
					<Image
						h="60px"
						w="60%"
						mb="20px"
						src={klogo}
						objectFit={"contain"}
					/>
				)}
			</Box>

			<Stack h="60vh" overflow="hidden" overflowY={"auto"} direction="column" mb="auto" mt="8px">
				<Box
					pe={{ md: "16px", "2xl": "1px" }}
					ms={mini && hovered === false ? "-16px" : "unset"}
				>
					<Links mini={mini} hovered={hovered} routes={routes} />
				</Box>
			</Stack>

			<Text p="10px" bg={borderColor} borderRadius={'10px'} mb="2" mt="50px" color={textColor} fontSize="sm" fontWeight="700">
				{userDetails?.role}
			</Text>
			<Flex px="20px" alignItems="center">
				{loading ? (
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						color="#fff"
					>
						<Spinner size="sm" />
					</Flex>
				) : (
					<Image
						borderRadius="50px"
						me={
							mini === false
								? "20px"
								: mini === true && hovered === true
									? "20px"
									: "0px"
						}
						src={
							userDetails?.photo_url
								? userDetails?.photo_url
								: avatar4
						}
						h="48px"
						w="48px"
						objectFit={"cover"}
					></Image>
				)}
				<Box
					display={
						mini === false
							? "block"
							: mini === true && hovered === true
								? "block"
								: "none"
					}
					mb="4"
				>

					<Text color={textColor} fontSize="md" fontWeight="700">
						{userDetails?.first_name} {userDetails?.last_name}
					</Text>
					<Text color={textColor} fontSize="sm" fontWeight="700">
						{userDetails?.role}
					</Text>
					<Text
						color="red"
						fontSize="sm"
						fontWeight="700"
						cursor={"pointer"}
						onClick={() => {
							systemLogout();
						}}
					>
						Logout
					</Text>
				</Box>
			</Flex>
			<Box w={"100%"} bg={"gray.100"}></Box>
		</Flex>
	);
}

export default SidebarContent;
