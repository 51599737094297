import React, { useState, useEffect } from "react";
import "react-responsive-pagination/themes/classic.css";
import ResponsivePagination from "react-responsive-pagination";
import { useNavigate, } from "react-router-dom";
import QRCode from "react-qr-code";
// Chakra Import
import {
	Box,
	Flex,
	Text,
	Link,
	Button,
	Icon,
	Spacer,
	Alert,
	Drawer,
	Spinner,
	Image,
	Tooltip,
	CheckboxGroup,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
	AlertIcon,
	FormLabel,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	useToast,
	Checkbox,
	useColorModeValue,
	SimpleGrid
} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../../components/card/Card";
import {
	AiOutlineCheckCircle,
	AiOutlineCopy,
	AiOutlineLink,
	AiOutlineStop,
	AiTwotoneAudio,
	AiOutlineVideoCamera,
	AiOutlineClockCircle,
	AiOutlinePlus,
} from "react-icons/ai";
// Api Call Imports
import {
	addNewLink,
	getAllLinks,
	disableLink,
	shareLink,
	getLinkCustomerEnabled,
	shareLinkWithCustomer,
} from "../../../../services/businessServices";
import { formatSeconds } from "../../../../utils/TimeFunctions";
// Component Imports
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";

import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { useBlackWhiteBg, useBrandBg, useGradientColor, useBlueButton } from "../../../../theme/globalColorTheme";
import LinkApp from "./LinkApp";

function LinkListing(props) {
	const toast = useToast();
	const navigate = useNavigate();
	const btnRef = React.useRef();
	const [loading, isLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const type = props?.type
	const port_value = window.location.port ? ":" + window.location.port : '';
	const currentHost = window.location.hostname.includes("localhost")
		? "https://lingolet.ai/"
		: window.location.protocol + "//" + window.location.hostname + port_value + "/";

	// const currentHost = window.location.protocol + "//" + window.location.hostname + port_value + "/";

	const { isOpen: isOpenQr, onOpen: onOpenQr, onClose: onCloseQr } = useDisclosure();
	const qrRef = React.useRef();

	const [currentSelection, setCurrentSelection] = useState('Active');
	const [dynamicLinks, setDynamicLinks] = useState([]);
	const [linkChoice, setLinkChoice] = useState("active");
	const [selectedValues, setSelectedValues] = useState(['hi', 'bi']);
	const [linkTitle, setLinkTitle] = useState(null);
	const [durationValue, setDurationValue] = useState(48);
	const [showTooltip, setShowTooltip] = React.useState(false);
	const [linkTitleFlag, setLinkTitleFLag] = useState(false);
	const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);
	const [linkTrial, setLinkTrial] = useState(false);
	const [voiceCallValue, setVoiceCallValue] = useState(5);
	const [videoCallValue, setVideoCallValue] = useState(5);
	const [callTimeValue, setCallTimeValue] = useState(5);
	const [modalQrCode, setModalQrCode] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const [linkMultichannel, setLinkMultichannel] = React.useState(false);

	const textColor = useColorModeValue("secondaryGray.700", "white");
	const pillButtonBg = useColorModeValue("blue.100", "gray.600");
	const pillButtonBgSelected = useColorModeValue("blue.300", "red.400");
	const pillButtonBgHover = useColorModeValue("blue.400", "red.200");
	const pillButtonTextColorHover = useColorModeValue("secondaryGray.200", "gray.700");
	const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
	const pillButtonText = useColorModeValue("white.100", "gray.800");

	const brandBg = useBrandBg()
	const gradientColor = useGradientColor()
	const blackWhiteBg = useBlackWhiteBg()
	const blueButtonBg = useBlueButton()

	const labelStyles = {
		mt: "12",
		ml: "-2.5",
		fontSize: "sm",
	};
	const labelStylesVoice = {
		mt: "1",
		ml: "-1",
		mr: "2.0",
		fontSize: "sm",
	};

	// Todo:Differentiate based on type
	useEffect(() => {
		const buttonType = props?.button
		if (buttonType?.length) {
			switch (buttonType) {
				case 'active':
				case 'closed':
					getAllDynamicLinks(buttonType);
					break;
				case 'add':
					onOpen();
					break;
				default:
					break;
			}
			props?.resetButton('reset')
		}

	}, [props?.button])
	//   Todo:Fetch all projects
	useEffect(() => {
		getAllDynamicLinks(linkChoice);
	}, [currentPage]);

	const getAllDynamicLinks = choice => {
		isLoading(true);
		let paramObj = {
			filter_key: "string",
			filter_value: choice,
			records_per_page: 10,
			page_id: currentPage,
		};
		setLinkChoice(choice);
		getAllLinks(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.records) {
					setDynamicLinks(res?.data[0]?.records);
					setTotalPages(res?.data[0]?.records?.length);
				} else {
					setDynamicLinks([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	//   Todo:API to create new project
	const createDynaLink = () => {
		if (linkTitle?.length && selectedValues.length > 0) {
			let locDurationValue = durationValue;
			let locVideoCallValue = videoCallValue
			let locVoiceCallValue = voiceCallValue
			let locCallTimeValue = callTimeValue;
			if (linkTrial === false) {
				locDurationValue = 8760;
				locVideoCallValue = 1000;
				locVoiceCallValue = 10000;
				locCallTimeValue = 2920;
			}
			setLinkTitleFLag(false);
			let objBody = {
				link_title: linkTitle,
				is_trail: linkTrial,
				active_hours: locDurationValue,
				videoCount: locVideoCallValue,
				voiceCount: locVoiceCallValue,
				callTime: locCallTimeValue,
				features: selectedValues.join(","),
				multichannel: linkMultichannel,
				start_time: "",
			};
			setSaveDetailsLoader(true);
			addNewLink(objBody)
				.then(res => {
					setSaveDetailsLoader(false);
					if (res?.result) {
						onClose();
						resetProjectDetails();
						getAllDynamicLinks("active");
						toast(toastFunctionToaster(res.message, "success"));
					} else {
						onClose();
						resetProjectDetails();
						toast(toastFunctionToaster(res.message, "error"));
					}
				})
				.catch(err => {
					onClose();
					resetProjectDetails();
					setSaveDetailsLoader(false);
					toast(toastFunctionToaster(err.message, "error"));
				});
		} else {
			setLinkTitleFLag(true);
		}
	};

	const launchManageLink = () => {
		if (window.location.pathname.includes('/account')){
			return '/account/linkmanager';
		} else if (window.location.pathname.includes('/business')){
			return '/business/linkmanager';
		} 
		return null;
	}

	// Todo:Re-render list after updating the features
	const handleLink = (data) => {
		if(data) {
			getAllDynamicLinks("active")
		}
	}

	const renderDynamicLinks =
		dynamicLinks !== null && dynamicLinks?.length > 0
			? dynamicLinks?.map((item, index) => (
				<Card
					bg={brandBg}
					key={index}
					w={"100%"}
					mb={2}
					p="0px"
					borderRadius='10px'
					boxShadow="none">
					<Flex w={"100%"} direction={"column"} borderWidth={'0.5px'} borderColor={gradientColor} borderRadius="10px" className="abc">
						<Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'} roundedTop={'md'} p={'10px'} borderBottomWidth={'0.5px'} borderColor={gradientColor}>
							<Flex alignItems={'center'} ml={1} rounded={"md"} bg={item?.active ? "green.200" : "red.200"} color="#000" fontWeight={'500'} px={2}>
								{item?.dead ? (
									<Icon as={AiOutlineStop} color={"red.600"} mr={2} />
								) : (
									<Icon
										as={AiOutlineCheckCircle}
										color={"gray.600"}
										mr={2}
									/>
								)}
								{item?.title}
							</Flex>
							<Spacer />
							{type === 'lingoCustomer' ? <Button size="sm" bg={blueButtonBg} borderRadius="5px"> Send Email </Button> : (
								<Button
									_hover={{ bg: pillButtonBgHighlight }}
									color={pillButtonText}
									bg={pillButtonBg}
									onClick={() => {
										navigate(launchManageLink(), {
											state: {
												linkUuid: item?.uuid,
												linkCode: item?.code,
											},
										});
									}}
									size={"sm"}
									borderRadius={'10px'}>
									Manage Link
								</Button>
							)}
						</Flex>
						<Flex w={'100%'} >
							<Flex w={"100%"} direction={"column"} p={'10px'}>
								<Flex w={"100%"} >
									<Text>
										Link Start Time (UTC):{" "}
										{moment(item?.starttime).format("MMM DD,YYYY,HH:mm a")}
									</Text>
									<Text ml={2}>
										Link End Time (UTC): {moment(item?.endtime).format("MMM DD,YYYY,HH:mm a")}
									</Text>
								</Flex>
								<Flex w={"100%"} mb="10px">
									<Text>
										Link Assigned Time: <b>{item?.hours} Hours</b>
									</Text>
									<Text ml={3}>
										{" "}
										| Time Left: <b>{item?.time_left}</b>
									</Text>
								</Flex>
								<Flex
									w={"100%"}
									p={1}
									direction={"column"}
									bg={blackWhiteBg}
									rounded={"md"}>
									<Flex w={"100%"} direction={"column"}>
										<Flex w={"100%"} mt={1} mb={1}>
											<Flex w={"100%"}>
												<Text mt={2} mr={2} fontSize={"lg"} color={"blue.600"}>
													{item?.url ? currentHost + item?.url : "No Url"}
												</Text>
											</Flex>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Copy Url">
												<Box w={"35px"} ml={1}
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer"
													}}
													color={pillButtonText}
													bg={pillButtonBg}
													pr={2} rounded={"md"}
													mr={2}>
													<Icon
														onClick={() =>
															navigator.clipboard.writeText(currentHost + item?.url)
														}
														ml={2}
														mt={1}
														h={5}
														w={5}
														as={AiOutlineCopy}
													/>
												</Box>
											</Tooltip>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Open Url in new Tab">
												<Link
													p={1}
													w={"35px"}
													rounded={"md"}
													mr={2}
													align={"center"}
													href={currentHost + item?.url}
													target="_blank"
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer"
													}}
													color={pillButtonText}
													bg={pillButtonBg}>
													<Icon
														ml={2}
														mt={1}
														h={5}
														_hover={{ cursor: "pointer" }}
														w={5}
														as={AiOutlineLink}
													/>
												</Link>
											</Tooltip>
										</Flex>
										<Flex
											w={"100%"}
											mt={1}
											mb={1}
											borderTopColor={gradientColor}
											borderTopWidth={"0.5px"}>
											<Flex w={"100%"} mt={1}>
												<Text mt={2} mr={2} fontSize={"lg"} color={"blue.600"}>
													{item?.fullurl ? currentHost + item?.fullurl : "No Url"}
												</Text>
											</Flex>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Copy Url">
												<Box
													mt={1}
													w={"35px"}
													ml={1}
													pr={2}
													rounded={"md"}
													mr={2}
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer"
													}}
													color={pillButtonText}
													bg={pillButtonBg}>
													<Icon
														onClick={() =>
															navigator.clipboard.writeText(currentHost + item?.fullurl)
														}
														ml={2}
														mt={1}
														h={5}
														_hover={{ cursor: "pointer" }}
														w={5}
														as={AiOutlineCopy}
													/>
												</Box>
											</Tooltip>
											<Tooltip
												hasArrow
												placement={"top"}
												bg="teal.500"
												onMouseEnter={() => setShowTooltip(true)}
												onMouseLeave={() => setShowTooltip(false)}
												color="white"
												label="Open Url in new Tab">
												<Link
													mt={1}
													p={1}
													w={"35px"}
													rounded={"md"}
													mr={2}
													align={"center"}
													href={currentHost + item?.fullurl}
													target="_blank"
													_hover={{
														bg: pillButtonBgHighlight,
														cursor: "pointer"
													}}
													color={pillButtonText}
													bg={pillButtonBg}>
													<Icon
														ml={2}
														mt={1}
														h={5}
														_hover={{ cursor: "pointer" }}
														w={5}
														as={AiOutlineLink}
													/>
												</Link>
											</Tooltip>
										</Flex>
										<Flex w={"100%"}>
											{item?.trial ? (
												item?.calls?.expired ? (
													<Flex
														w={"100%"}
														// bg={"red.100"}
														bg={brandBg}
														align={"center"}
														rounded={"md"}
														p={1}
														borderWidth={"0.05px"}
														borderColor={"gray.400"}>
														<Box w={"250px"}>
															<Text color={"red.700"}>Expired Trial Link</Text>
														</Box>
														<Flex w={"100%"} direction={"column"}>
															<Box w={"100%"}>
																<Icon
																	as={AiOutlineClockCircle}
																	mr={1}
																	mt={1}
																	h={5}
																	w={5}
																/>{" "}
																Time:{" "}
																<b>{formatSeconds(item?.calls?.calls[6].toFixed(2))}</b>{" "}
																| Used:{" "}
																<b>{formatSeconds(item?.calls?.calls[7].toFixed(2))}</b>{" "}
																| Remaining:{" "}
																<b>
																	{item?.calls?.calls[8] > 0
																		? formatSeconds(
																			item?.calls?.calls[8].toFixed(2)
																		)
																		: "00:00:00"}
																</b>
															</Box>
															<Flex w={"100%"}>
																<Box w={"100%"}>
																	<Icon
																		as={AiTwotoneAudio}
																		mr={1}
																		mt={1}
																		h={5}
																		w={5}
																	/>{" "}
																	Calls:{" "}
																	<b>
																		{item?.calls?.calls[0]} | Made:{" "}
																		{item?.calls?.calls[1]} | Remaining:{" "}
																		{item?.calls?.calls[2]}
																	</b>
																</Box>
																<Box w={"100%"}>
																	<Icon
																		as={AiOutlineVideoCamera}
																		mr={1}
																		mt={1}
																		h={5}
																		w={5}
																	/>{" "}
																	Calls:{" "}
																	<b>
																		{item?.calls?.calls[3]} | Made:{" "}
																		{item?.calls?.calls[4]} | Remaining:{" "}
																		{item?.calls?.calls[5]}
																	</b>
																	)
																</Box>
															</Flex>
														</Flex>
													</Flex>
												) : (
													<Flex
														w={"100%"}
														bg={brandBg}
														align={"center"}
														rounded={"md"}
														p={1}
													>
														<Box w={"250px"}>
															<Text color={"green.700"}>Active Trial Link</Text>
														</Box>
														<Flex w={"100%"} direction={"column"} alignItems={'center'}>
															<Flex w={"100%"} alignItems={'center'}>
																<Icon
																	as={AiOutlineClockCircle}
																	mr={1}
																	mt={1}
																	h={5}
																	w={5}
																/>{" "}
																Time:{" "}
																<b>{formatSeconds(item?.calls?.calls[6].toFixed(2))}</b>{" "}
																| Used:{" "}
																<b>{formatSeconds(item?.calls?.calls[7].toFixed(2))}</b>{" "}
																| Remaining:{" "}
																<b>
																	{item?.calls?.calls[8] > 0
																		? formatSeconds(
																			item?.calls?.calls[8].toFixed(2)
																		)
																		: "00:00:00"}{" "}
																</b>
															</Flex>
															<Flex w={"100%"} alignItems={'center'}>
																<Flex w={"100%"} alignItems={'center'}>
																	<Icon
																		as={AiTwotoneAudio}
																		mr={1}
																		h={5}
																		w={5}
																	/>{" "}
																	Calls:{" "}
																	<b>
																		{item?.calls?.calls[0]} | Made:{" "}
																		{item?.calls?.calls[1]} | Remaining:{" "}
																		{item?.calls?.calls[2]}
																	</b>
																</Flex>
																<Flex w={"100%"} alignItems={'center'}>
																	<Icon
																		as={AiOutlineVideoCamera}
																		mr={1}
																		h={5}
																		w={5}
																	/>{" "}
																	Calls:{" "}
																	<b>
																		{item?.calls?.calls[3]} | Made:{" "}
																		{item?.calls?.calls[4]} | Remaining:{" "}
																		{item?.calls?.calls[5]}
																	</b>
																</Flex>
															</Flex>
														</Flex>
													</Flex>
												)
											) : (
												<Flex
													w={"100%"}
													bg={brandBg}
													align={"center"}
													rounded={"md"}
													px={2}
												>
													{item?.multichannel === false
													?<Text fontSize={"md"} color={"green.700"} fontWeight={'500'}>
														Not a trial/demo link.
													</Text>:null}
												</Flex>
											)}
										</Flex>
									</Flex>
								</Flex>
								{item?.multichannel
								?<Flex w={'100%'} align={'center'} py={1}> 
									<Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
									<Text ml={1} fontSize={'xl'}>Multichannel Lingolink</Text>
								</Flex>
								:<LinkApp linkItem={item} reListLink={handleLink}/>}
							</Flex>
							<Flex minW={'300px'} maxW={'300px'} >
								<Flex maxW={"150px"}>
									<Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
										<Box
											w={"100%"}
											onClick={() => {
												setModalQrCode(item?.url);
												onOpenQr();
											}}
											_hover={{ cursor: "pointer", borderColor: "red.600", borderWidth: "1px" }}
											p={1}
											borderColor={"gray.400"}
											borderWidth={"1px"}
											rounded={"md"}>
											<QRCode
												size={90}
												style={{ height: "auto", maxWidth: "100", width: "100" }}
												value={item?.url}
												viewBox={`0 0 90 90`}
											/>
										</Box>
										<Text>QR(Url)</Text>
									</Flex>
								</Flex>
								<Flex maxW={"150px"}>
									<Flex w={"100%"} direction={"column"} p={4} alignItems={'center'} justifyContent={'end'}>
										<Box
											w={"100%"}
											onClick={() => {
												setModalQrCode(item?.fullurl);
												onOpenQr();
											}}
											_hover={{ cursor: "pointer", borderColor: "red.600", borderWidth: "1px" }}
											p={1}
											borderColor={"gray.400"}
											borderWidth={"1px"}
											rounded={"md"}>
											<QRCode
												size={90}
												style={{ height: "auto", maxWidth: "100", width: "100" }}
												value={item?.fullurl}
												viewBox={`0 0 90 90`}
											/>
										</Box>
										<Text>QR(Url+Code)</Text>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Card>
			))
			: null;

	// Todo:Reset Project Details
	const resetProjectDetails = () => {
		setLinkTitle("");
		setDurationValue(48);
		setVoiceCallValue(5);
		setVoiceCallValue(5);
		setLinkTrial(false);
		setLinkTitleFLag(false);
	};

	// Todo:Reset details on drawer closed
	const closeDrawer = () => {
		onClose();
		resetProjectDetails();
	};

	// Function to handle changes in the CheckboxGroup
	const handleChange = (values) => {
		setSelectedValues(values);
	};

	return (
		<>
			<Box direction="column" pt={
				type === 'lingoCustomer' ? { sm: "0px", lg: "0px" } : { sm: '125px', lg: '75px' }}
				mt={type === 'lingoCustomer' ? '0' : 4}>
				{type === 'lingoCustomer' ? 'null' : (
					<Card p="3" alignItems="center" rounded={'md'}>
						<Flex w={'100%'} direction={'row'} >
							<Text fontWeight={"500"} fontSize={"18px"}>
								LingoLink {dynamicLinks?.length > 0 ? dynamicLinks?.length : 0} ({linkChoice})
							</Text>
							<Spacer />
							<Flex minW={'440px'} maxW={'440px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
								<Text
									minW={'70px'} maxW={'70px'}
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										setCurrentSelection('Active');
										getAllDynamicLinks("active")
									}}
									p={2} roundedLeft={'md'}
									color={currentSelection === 'Active' ? 'white' : textColor}
									bg={currentSelection === 'Active' ? pillButtonBgSelected : pillButtonBg}>
									Active
								</Text>
								<Text
									minW={'80px'} maxW={'80px'}
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										setCurrentSelection('closed');
										getAllDynamicLinks("closed")
									}}
									color={currentSelection === 'closed' ? 'white' : textColor}
									p={2}
									bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
									Closed
								</Text>
								<Text
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										getAllDynamicLinks("active");
										setCurrentSelection('refresh');
									}}
									color={currentSelection === 'refresh' ? 'white' : textColor}
									p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
									pr={5}>Refresh
								</Text>
								<Text
									_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
									onClick={() => {
										onOpen();
										// setCurrentSelection('new');
									}}
									roundedRight={'md'}
									color={currentSelection === 'new' ? 'white' : textColor}
									p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
									pr={5}>
									<Icon pt={1} as={AiOutlinePlus} mr={1} />
									Create New Link
								</Text>
							</Flex>
						</Flex>
					</Card>)}
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h={type === 'lingoCustomer' ? '10vh' : '50vh'}>
						<Spinner size="xl" />
					</Flex>
				) : dynamicLinks?.length ? (
					<Card w={"100%"} borderRadius='10px' mt={3} p={type === 'lingoCustomer' ? '0px' : '10px'}>
						<Flex w={"100%"} rounded={"md"} direction={"column"}>
							{renderDynamicLinks}
						</Flex>
					</Card>
				) : (
					<Alert status="warning" mt="4">
						<AlertIcon />
						<Text>You dont have any LingoLink yet... </Text>
						<Text ml={5}>
							<b>CREATE NEW LINGOLINK!!</b>
						</Text>
					</Alert>
				)}
			</Box>
			{dynamicLinks?.length && linkChoice === "closed" ? (
				<Box mt="4">
					<ResponsivePagination
						current={currentPage}
						total={totalPages}
						onPageChange={pageNumber => {
							setCurrentPage(pageNumber);
						}}
					/>
				</Box>
			) : null}
			{/* // Add Project Drawer */}
			<Drawer
				size="md"
				isOpen={isOpen}
				placement="right"
				onClose={() => {
					closeDrawer();
				}}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth={"1px"}>{"Create New Dynamic Link"}</DrawerHeader>
					<DrawerBody overflow="hidden" overflowY="scroll" mt="5">
						<Flex w={"100%"} direction={"column"}>
							<Flex w={'100%'} rounded={'md'} 
								mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
								<InputField
									mb="0"
									me="30px"
									id="link_title"
									label="Link Title"
									value={linkTitle}
									name="link_title"
									required="true"
									borderRadius="5px"
									onChange={e => {
										setLinkTitle(e.target.value);
									}}
									placeholder="Enter Link Title"
									w="100%"
									maxlength={100}
								/>
								<Flex alignItems={"center"} justifyContent={"space-between"}>
									{linkTitleFlag ? (
										<Text fontSize="sm" color={"red"} mt="1">
											Project Title is Required
										</Text>
									) : null}
									<Spacer />
									<Text textAlign={"right"} fontWeight={"500"} fontSize={"sm"} mt="2">
										{linkTitle?.length}/100
									</Text>
								</Flex>
							</Flex>
							<Flex w={'100%'} direction={'column'}>
								<Flex w={'100%'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
									<Text
										minW={'50%'} maxW={'50%'}
										_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
										onClick={() => {
											setLinkMultichannel(false);
										}}
										p={2} roundedLeft={'md'}
										color={linkMultichannel === false ? 'white' : textColor}
										bg={linkMultichannel === false ? pillButtonBgSelected : pillButtonBg}>
										Multipurpose LingoLink
									</Text>
									<Text
										minW={'50%'} maxW={'50%'}
										_hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
										onClick={() => {
											setLinkMultichannel(true);
											setSelectedValues(['mi']);
										}}
										roundedRight={'md'}
										color={linkMultichannel === true ? 'white' : textColor}
										p={2}
										bg={linkMultichannel === true ? pillButtonBgSelected : pillButtonBg}>
										Multichannel LingoLink
									</Text>
								</Flex>
							</Flex>
							{linkMultichannel === true
							?<Flex w={'100%'} bg={'red.50'} rounded={'lg'} mt={1} direction={'column'} p={2}>
								{/* <CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
									<Checkbox value='mi'> */}
										<Flex alignItem='center'>
											<Image h="100px" w="100px" mx="1" borderRadius="50px" src={MultichannelBg} />
											<Flex w={'100%'} direction={'column'}>
												<Text as="span">
													MultiChannel AI
												</Text>
												<Text as="span">
													Main Language:
												</Text>
												<Text as="span">
													<b>English</b>
												</Text>
												<Text as="span">
													Other Language: 
												</Text>
												<Text as="span">
													<b>Selected at Connecting Time</b>
												</Text>
											</Flex>
										</Flex>
									{/* </Checkbox>
								</CheckboxGroup> */}
							</Flex>
							:<Flex w={'100%'} bg={'blue.50'} rounded={'lg'} mt={1} direction={'column'}>
								<Flex
									w={"100%"}
									direction={"column"}
									p={1}
									rounded={"md"}
									borderWidth={"0.5px"}
									borderColor={"gray.200"}
									mt="3">
									<Flex w={"100%"} align={"center"}>
										<Text mt={1} mb="2" fontWeight={"bold"} fontSize={"sm"}>
											Is it a trial Link?
										</Text>
										<Checkbox
											w={"10px"}
											onChange={() => setLinkTrial(!linkTrial)}
											checked={linkTrial}
											ml={2}></Checkbox>
										<Spacer />
									</Flex>
									{linkTrial ? (
										<Flex w={"100%"} direction={"column"}>
											<FormLabel my="3" ml="2" required>
												<Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
													Link Activity Duration (Hours)
												</Text>
												<Flex
													mt={5}

													h={"100px"}
													w={"100%"}
													rounded={"md"}
													borderWidth={"0.5px"}
													borderColor={"gray.200"}>
													<Slider
														aria-label="slider-ex-1"
														colorScheme="teal"
														min={2}
														max={168}
														step={1}
														defaultValue={48}
														onChange={v => setDurationValue(v)}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}>
														<SliderMark value={24} {...labelStyles}>
															24
														</SliderMark>
														<SliderMark value={48} {...labelStyles}>
															48
														</SliderMark>
														<SliderMark value={72} {...labelStyles}>
															72
														</SliderMark>
														<SliderMark value={96} {...labelStyles}>
															96
														</SliderMark>
														<SliderMark value={120} {...labelStyles}>
															120
														</SliderMark>
														<SliderMark value={144} {...labelStyles}>
															144
														</SliderMark>
														<SliderMark value={168} {...labelStyles}>
															168
														</SliderMark>
														<SliderTrack>
															<SliderFilledTrack />
														</SliderTrack>
														<Tooltip
															hasArrow
															bg="teal.500"
															color="white"
															placement="top"
															isOpen={showTooltip}
															label={`${durationValue} Hours`}>
															<SliderThumb />
														</Tooltip>
													</Slider>
												</Flex>
											</FormLabel>
											<Flex w={"100%"} align={"center"}>
												<Flex
													w={"50%"}
													h={"100px"}
													direction={"column"}
													py={1}
													px={5}
													rounded={"md"}
													borderWidth={"0.5px"}
													borderColor={"gray.200"}>
													<Text>
														Voice Call Count: <b>{voiceCallValue}</b>
													</Text>
													<Slider
														mt={8}
														aria-label="slider-ex-1"
														colorScheme="teal"
														min={1}
														max={50}
														step={1}
														defaultValue={5}
														onChange={v => setVoiceCallValue(v)}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}>
														<SliderMark value={1} {...labelStylesVoice}>
															1
														</SliderMark>
														<SliderMark value={25} {...labelStylesVoice}>
															25
														</SliderMark>
														<SliderMark value={50} {...labelStylesVoice}>
															50
														</SliderMark>
														<SliderTrack>
															<SliderFilledTrack />
														</SliderTrack>
														<Tooltip
															hasArrow
															bg="teal.500"
															color="white"
															placement="top"
															isOpen={showTooltip}
															label={`${voiceCallValue}`}>
															<SliderThumb />
														</Tooltip>
													</Slider>
												</Flex>
												<Flex
													ml={1}
													w={"50%"}
													h={"100px"}
													direction={"column"}
													py={1}
													px={5}
													rounded={"md"}
													borderWidth={"0.5px"}
													borderColor={"gray.200"}>
													<Text>
														Video Call Count: <b>{videoCallValue}</b>
													</Text>
													<Slider
														mt={8}
														aria-label="slider-ex-1"
														colorScheme="teal"
														min={1}
														max={50}
														step={1}
														defaultValue={5}
														onChange={v => setVideoCallValue(v)}
														onMouseEnter={() => setShowTooltip(true)}
														onMouseLeave={() => setShowTooltip(false)}>
														<SliderMark value={1} {...labelStylesVoice}>
															1
														</SliderMark>
														<SliderMark value={25} {...labelStylesVoice}>
															25
														</SliderMark>
														<SliderMark value={50} {...labelStylesVoice}>
															50
														</SliderMark>
														<SliderTrack>
															<SliderFilledTrack />
														</SliderTrack>
														<Tooltip
															hasArrow
															bg="teal.500"
															color="white"
															placement="top"
															isOpen={showTooltip}
															label={`${videoCallValue}`}>
															<SliderThumb />
														</Tooltip>
													</Slider>
												</Flex>
											</Flex>
											<Flex
												mt="3"
												w={"100%"}
												h={"100px"}
												direction={"column"}
												py={1}
												px={5}
												rounded={"md"}
												borderWidth={"0.5px"}
												borderColor={"gray.200"}>
												<Text>
													Maximum minutes available in this Trial call: <b>{callTimeValue}</b>{" "}
													minutes.
												</Text>
												<Slider
													mt={10}
													aria-label="slider-ex-1"
													colorScheme="teal"
													min={5}
													max={600}
													step={5}
													defaultValue={5}
													onChange={v => setCallTimeValue(v)}
													onMouseEnter={() => setShowTooltip(true)}
													onMouseLeave={() => setShowTooltip(false)}>
													<SliderMark value={5} {...labelStylesVoice}>
														5
													</SliderMark>
													<SliderMark value={100} {...labelStylesVoice}>
														100
													</SliderMark>
													<SliderMark value={200} {...labelStylesVoice}>
														200
													</SliderMark>
													<SliderMark value={300} {...labelStylesVoice}>
														300
													</SliderMark>
													<SliderMark value={400} {...labelStylesVoice}>
														400
													</SliderMark>
													<SliderMark value={500} {...labelStylesVoice}>
														500
													</SliderMark>
													<SliderMark value={600} {...labelStylesVoice}>
														600
													</SliderMark>
													<SliderTrack>
														<SliderFilledTrack />
													</SliderTrack>
													<Tooltip
														hasArrow
														bg="teal.500"
														color="white"
														placement="top"
														isOpen={showTooltip}
														label={`${callTimeValue}`}>
														<SliderThumb />
													</Tooltip>
												</Slider>
											</Flex>
										</Flex>
									) : null}
								</Flex>
								<Flex w={'100%'} rounded={'md'} 
									mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
									<Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
									<CheckboxGroup colorScheme='blue' value={selectedValues} onChange={handleChange}>
										<SimpleGrid  columns={2} gap="2px" mt="2" >
											<Checkbox value='hi'>
												<Flex alignItem='center'>
													<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={HumanAIBg} />
													<Text as="span" >
														Human Interpretation
													</Text>
												</Flex>
											</Checkbox>
											<Checkbox value='bi'>
												<Flex alignItem='center'>
													<Image h="40px" w="40px" mx="1	" borderRadius="50px" src={BasicAIBg} />
													<Text as="span">
														Basic AI
													</Text>
												</Flex>
											</Checkbox>
											<Checkbox value='pi'>
												<Flex alignItem='center'>
													<Image h="40px" w="40px" mx="1" borderRadius="50px" src={PremiumAIBg} />
													<Text as="span">
														Premium AI
													</Text>
												</Flex>
											</Checkbox>
											<Checkbox value='di'>
												<Flex alignItem='center'>
													<Image h="40px" w="40px" mx="1" borderRadius="50px" src={DocumentAiLightBg} />
													<Text as="span">
														Document AI
													</Text>
												</Flex>
											</Checkbox>
										</SimpleGrid>
									</CheckboxGroup>
								</Flex>
							</Flex>}
						</Flex>
					</DrawerBody>
					<DrawerFooter>
						<Flex w={"100%"} direction={"row"}>
							<Button
								variant="outline"
								mr={3}
								onClick={() => {
									onClose();
									resetProjectDetails();
								}}>
								Cancel
							</Button>
							<Button
								colorScheme="blue"
								onClick={() => {
									resetProjectDetails();
									createDynaLink();
								}}
								isDisabled={
									!selectedValues.length || saveDetailsLoader ? true : false
								}
								isLoading={saveDetailsLoader ? true : false}>
								{"Create New Link"}
							</Button>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
			<Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>QR Code for the LingoLink</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{modalQrCode != null ? (
							<Flex w={"100%"} direction={"column"}>
								<QRCode
									title={currentHost + modalQrCode}
									size={90}
									style={{ height: "auto", maxWidth: "100%", width: "100%" }}
									value={currentHost + modalQrCode}
									viewBox={`0 0 90 90`}
								/>
								<Text p={1} bg={"blue.100"}>
									{currentHost + modalQrCode}
								</Text>
							</Flex>
						) : (
							<Text color={"red.400"}>There is some issue with selected URL, please try again..</Text>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							size={"sm"}
							colorScheme="blue"
							onClick={() => {
								setModalQrCode(null);
								onCloseQr();
							}}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default LinkListing;