import { useColorModeValue } from "@chakra-ui/react";

export const usePillButtonBg = () => {
	return useColorModeValue("blue.100", "gray.600");
};

export const usePillButtonBgSelected = () => {
	return useColorModeValue("blue.400", "red.500");
};

export const useTextColor = () => {
	return useColorModeValue("secondaryGray.700", "white");
};

export const useGrayTextColor = () => {
	return useColorModeValue("gray.500", "gray.700");
};

export const useGradientColor = () => {
	return useColorModeValue(
		"linear(to-b, blue.100, blue.300)",
		"linear(to-b, gray.100, gray.600)"
	);
};

export const useBrandBg = () => {
	return useColorModeValue("blue.50", "navy.900");
};

export const useBlackWhiteBg = () => {
	return useColorModeValue("white", "black");
};

export const useCardGradientTheme = () => {
	return useColorModeValue('linear(to-b, brand.400, brand.600)');
};

export const useBlueButton = () => {
	return useColorModeValue("blue.100", "blue.600");
};

export const useRedButton = () => {
	return useColorModeValue("red.200", "red.300");
};

export const useGreenButton = () => {
	return useColorModeValue("green.300", "green.600");
};

export const useYellowButton = () => {
	return useColorModeValue("yellow.300", "yellow.600");
};

export const useRedBg = () => {
	return useColorModeValue("red.100", "red.400");
};

export const useGreenBg = () => {
	return useColorModeValue("green.200", "green.500");
};


export const useListItemHoverBg = () => {
	return useColorModeValue("blue.100", "gray.600");
}