import React, { useState, useEffect } from "react";

// Chakra Import
import {
    Button,
    Flex,
    Text,
    Image,
    useToast,
    Checkbox,
} from "@chakra-ui/react";
import BasicAIBg from "../../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import { upgradeAccountAdminApps } from "../../../../services/businessServices";

function UpdateAccountAdminApp({accountDetails,appDetails,accountUuid}) {
    const toast = useToast();
    const [updateLoader, setUpdateLoader] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        if (appDetails?.length) {
            setSelectedValues(appDetails);
        }
        else {
            setSelectedValues([]);
        }
    }, [appDetails]);

    // Todo:Render Feature Name By Code
    const renderFeatureName = (name) => {
        if (name === 'hi') {
            return 'Human Interpretation';
        } else if (name === 'bi') {
            return 'Basic AI';
        } else if (name === 'pi') {
            return 'Premium AI';
        } else if (name === 'di'){
            return 'Document AI';
        } else if (name === 'mi'){
            return 'Multichannel';
        }
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
        } else if (name === "mi") {
			return MultichannelBg;
		} else return;
	};

    const updateAdminFeatureApp = () => {
        setUpdateLoader(false);
        let obj ={
            link_account_uuid:accountUuid,
            link_apps: selectedValues?.join(',')
            // useId:accountDetails?.uuid
        }
        upgradeAccountAdminApps(obj).then((res)=> {
            setUpdateLoader(false);
            if (res?.result) {
                toast(toastFunctionToaster(res.message, "success"));
            } else {
                toast(toastFunctionToaster(res.message, "error"));
            }
        })
        .catch(err => {
            setUpdateLoader(false);
            toast(toastFunctionToaster(err.message, "error"));
        });
    }

    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        setSelectedValues(prevValues =>
            prevValues.includes(value)
                ? prevValues.filter(val => val !== value)
                : [...prevValues, value]
        );
    };

  return (
    <>
        {appDetails?.map((appName, index) => {
             const isChecked = selectedValues.includes(appName);
            return (
                <Flex alignItems="center" px="2" py="1" borderWidth="1px" borderColor="#d7d7d7" borderRadius="5px" key={index} mr="2" my="1">
                    <Image mr="2" borderRadius="50px" h="20px" w="20px" src={renderFeatureImage(appName)} />
                    <Text mr="1" fontWeight="600" fontSize="14px">
                        {renderFeatureName(appName)}
                    </Text>
                    <Checkbox isChecked={isChecked} value={appName} onChange={handleCheckboxChange} />
                </Flex>
            );
        })}
        <Button isLoading={updateLoader ? true : false}  borderRadius="10px" colorScheme="green" size="sm" isDisabled={selectedValues.length === 0} onClick={() => updateAdminFeatureApp()}>
            Update
        </Button>
    </>
  )
}

export default UpdateAccountAdminApp