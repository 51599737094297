import React from "react";
import { BiUserCircle, BiMoney, BiDollarCircle } from "react-icons/bi";
import { Icon } from "@chakra-ui/react";
import {
  AiOutlineSetting,
  AiOutlineDashboard,
  AiOutlineMobile,
  AiOutlineSelect,
  AiOutlineLink,
  AiOutlineUser,
  AiOutlineCar,
  AiFillCreditCard,
  AiOutlineGift,
  AiOutlineSecurityScan,
  AiOutlineUserSwitch,
  AiOutlineUserAdd,
  AiOutlineOrderedList,
} from "react-icons/ai";

// Business Componenr Imports
import SubscriberDashboard from "../komponents/subscriber/dashboard/SubscriberDashboard";
import BusinessProfile from "../komponents/business/profile/BusinessProfile";

// LingoLinks
import LinkListing from "../komponents/business/workspace/Links/LinkListing";
import LinkManager from "../komponents/business/workspace/Links/LinkManager";
import LinkSession from "../komponents/business/workspace/Links/LinkSession";
import LinkCalls from "../komponents/business/workspace/Links/LinkCalls";
import LinkConfiguration from "../komponents/business/workspace/Links/LinkConfiguration";

// Subscriber LingoLinks
import LinkSubscriberManage from "../komponents/subscriber/links/LinkSubscriberManage";
import LinkSubscriberCalls from "../komponents/subscriber/links/LinkSubscriberCalls";
import SubscriberInfo from "../komponents/subscriber/SubscriberInfo";
// import LinkCustomers from "../komponents/business/customers/LinkCustomers";
// import LinkCustomerCalls from "../komponents/business/customers/LinkCustomerCalls";
// import LinkCustomerManage from "../komponents/business/customers/LinkCustomerManage";


// Common
import Helper from "../komponents/common/helper/Helper";
import PlatformTrust from "../komponents/common/helper/PlatformTrust";
import LinkSubscriberTranscription from "../komponents/subscriber/links/LinkSubcriberTranscription";


const subscriberRoutes = [
  {
    name: "Dashboard",
    path: "/subscriber",
    icon: <Icon as={AiOutlineDashboard} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Dashboard",
        layout: "/subscriber",
        path: "/dashboard",
        component: <SubscriberDashboard />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineDashboard} width="20px" height="20px" />,
      },
    ],
  },
  {
    name: "LingoLink",
    path: "/subscriber",
    icon: <Icon as={AiOutlineLink} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
				name: "Subscriber Info",
				layout: "/subscriber",
				path: "/subscriber",
				component: <SubscriberInfo />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "LingoLink",
				layout: "/subscriber",
				path: "/lingolinklisting",
				component: <LinkSubscriberManage />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Transcripts",
				layout: "/subscriber",
				path: "/transcripts",
				component: <LinkSubscriberTranscription/>,
				secondary: true,
				showToMenu: false,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
      {
				name: "Call History",
				layout: "/subscriber",
				path: "/callhistory",
				component: <LinkSubscriberCalls />,
				secondary: true,
				showToMenu: true,
				icon: <Icon as={AiOutlineSelect} width="20px" height="20px" />,
			},
    ]
  },
  {
    name: "Settings",
    path: "/subscriber",
    icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
    collapse: true,
    items: [
        // {
        //     name: "Team Members",
        //     layout: "/subscriber",
        //     path: "/team",
        //     component: <AccountTeam />,
        //     secondary: true,
        //     showToMenu: true,
        //     icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
        // },
        {
            name: "Profile",
            layout: "/subscriber",
            path: "/profile",
            component: <BusinessProfile />,
            secondary: true,
            showToMenu: true,
            icon: <Icon as={AiOutlineUser} width="20px" height="20px" />,
        }        
    ],
  },
  {
    name: "Help",
    path: "/subscriber",
    icon: <Icon as={AiOutlineSetting} width="25px" height="25px" />,
    collapse: true,
    items: [
      {
        name: "Get Help",
        layout: "/subscriber",
        path: "/help",
        component: <Helper />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
      {
        name: "Trust in Platform",
        layout: "/subscriber",
        path: "/trust",
        component: <PlatformTrust />,
        secondary: true,
        showToMenu: true,
        icon: <Icon as={AiOutlineSetting} width="20px" height="20px" />,
      },
    ],
  },
];

export default subscriberRoutes;
