// Chakra Imports
import { FaPhoneSlash, FaPhone } from "react-icons/fa";
import callingGif from "../../../assets/communicator/callingGif.gif";
import React, { useState, useRef, useEffect } from "react";
import {
	Box,
	Flex,
	Button,
	Icon,
	Textarea,
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Image,
	useMediaQuery,
} from "@chakra-ui/react";

// API
import { callStartedEx, callCompletedEx } from "../../../services/dynamicPageSvc";
import { AiOutlineAudio, AiOutlineVideoCamera, AiFillClockCircle } from "react-icons/ai";

const BLCommunicate = ({ launchKey, sessionKey, callReadyVal, callTrialVal, callDataVal, blObj, selectedSettings }) => {
	const callProviderCode = 11;

	const localVideoRef = useRef(null); // Ref for local video container
	const remoteVideoRef = useRef(null);
	const [callLog, setCalLog] = useState("Start of the log...");
	const [isLandscape] = useMediaQuery("(orientation: landscape)");
	// const callReadiness = callReadyVal ? callReadyVal : false;
	const [callReadiness, setCallReadiness] = useState(callReadyVal ? callReadyVal : false);
	const [callTrial, setCallTrial] = useState(callTrialVal ? callTrialVal : false);
	const [callData, setCallData] = useState(callDataVal ? callDataVal : [0, 0, 0, 0]);
	const [currentCall, setCurrentCall] = useState(null);
	const [agentDetails, setAgentDetails] = useState(null);
	const [callUuid, setCallUuid] = useState(null);
	const [hangupFlag, setHangupFlag] = useState(false);
	const [muteUnmuteFlag, setMuteUnmuteFlag] = useState(false);
	const [pauseUnpauseFlag, setPauseUnpauseFlag] = useState(false);
	const [videoEnable, setVideoEnable] = useState(true);
	const [micEnable, setMicEnable] = useState(false);
	const [callType, setCallType] = useState("audio");
	const [selectedValue, setSelectedValue] = useState(selectedSettings);
	const localVideoElement = document.getElementById("localVideo");
	const [pauseLayer, setPauseLayer] = useState(false);
	// Get local and remote video elements
	const remoteVideoElement = document.getElementById("remoteVideo");
	const [mediaStream, setMediaStream] = useState(null);
	const [callStarted, setCallStarted] = useState(null);
	const [demoHistoryTag,setDemoHistoryTag] = useState(true)

	// Timer UseEffect
	const [time, setTime] = useState(0);
	const [isRunning, setIsRunning] = useState(false);
	const intervalRef = useRef();

	useEffect(() => {
		setSelectedValue(selectedSettings);
		if (demoHistoryTag) {
			getMicAndVideoStream();
		}
		if(selectedSettings?.sourceLangCode === 1000 || selectedSettings?.targetLangCode === 1000) {
			setDemoHistoryTag(false)
		}
	}, [selectedSettings]);

	// Time Start Stop Function
	const startTimer = () => {
		resetTimer();
		if (!isRunning) {
			setIsRunning(true);
			intervalRef.current = setInterval(() => {
				setTime(prevTime => prevTime + 1);
			}, 1000);
		}
	};

	const stopTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		// Todo:Once call is hangup reset timer
	};

	const resetTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		setTime(0);
	};

	const formatTime = timeInSeconds => {
		console.log(timeInSeconds);
		const minutes = Math.floor(timeInSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
		console.log(minutes, seconds);
		return `${minutes}:${seconds}`;
	};

	const callStartedProcess = () => {
		if (launchKey != null && sessionKey != null && callType != null && callReadiness) {
			let paramObj = {
				feature: "hi",
				provider: callProviderCode,
				callType: callType,
				launchKey: launchKey,
				launchSession: sessionKey,
				source_language: selectedSettings?.sourceLangName,
				target_language: selectedSettings?.targetLangName,
				agentInfo: agentDetails != null ? JSON.stringify(agentDetails) : !demoHistoryTag ? 'Demo Call' : "{}",
			};
			callStartedEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setCallReadiness(res?.data[0]?.ready);
						if (res?.data[0]?.ready == false) {
							updateLogDetails("Call can not be completed as trials period is expired.");
						} else {
							updateLogDetails("Call Started successully with call registration..");
							if (callType === "audio") {
								setCallStarted(true);
							}
							// DO NOT DELETE THE FOLLOWING LINE (Avkash)
							handleCall();
						}
						setCallUuid(res?.data[0]?.uuid);
						setCallTrial(res?.data[0]?.calls?.trial);
						setCallData(res?.data[0]?.calls?.value);
					}
				})
				.catch(err => {
					updateLogDetails("Unable to get call started working...");
				});
		}
		else if(!demoHistoryTag) {
			setCallStarted(true);
			updateLogDetails("Call Started successully with call registration..");
			if (callType === "audio") {
				setCallStarted(true);
			}
		}
	};

	const callCompletedProcess = () => {
		if (launchKey != null && sessionKey != null && callUuid != null) {
			let paramObj = {
				feature: "hi",
				provider: callProviderCode,
				callUuid: callUuid,
				launchKey: launchKey,
				launchSession: sessionKey,
				callLog: "none",
				agentInfo: agentDetails != null ? JSON.stringify(agentDetails) : !demoHistoryTag ?		'Demo Call' : "{}",
			};
			callCompletedEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setCallUuid(null);
						setCallTrial(res?.data[0]?.calls?.trial);
						setCallData(res?.data[0]?.calls?.value);
						setCallReadiness(res?.data[0]?.ready);
						updateLogDetails("Call stopped successfully with call registration updated..");
						updateLogDetails(
							"You have completed " + callType + " call with total call time: " + formatTime(time) + "."
						);
						if (callType === "audio") {
							setCallStarted(false);
						}
					}
				})
				.catch(err => {
					updateLogDetails("Unable to get call stopped correctly...");
				});
		}
		else if(!demoHistoryTag) {
			updateLogDetails("Call stopped successfully with call registration updated..");
			updateLogDetails(
				"You have completed " + callType + " call with total call time: " + formatTime(time) + "."
			);
			if (callType === "audio") {
				setCallStarted(false);
			}
		}
	};

	const getMicAndVideoStream = async () => {
		const stream = await navigator.mediaDevices.getUserMedia({
			video: videoEnable,
			audio: micEnable,
		});
		console.log(stream);
		if (stream) {
			setMediaStream(stream);
			// Once the media stream is obtained, set it as the srcObject of localVideoElement
			if (localVideoElement) {
				localVideoElement.srcObject = stream;
			}
		} else {
			console.error("getUserMedia returned a null or undefined stream.");
			// Handle the error, such as displaying a message to the user
		}
		// setMediaStream(stream);
	};

	const validateCallAction = () => {
		// TODO - we are going to make a backend call.
		// if backend returns > goahead = True
		// Make the call otherwise show error message as sent by the back end.
	};

	const handleCall = async () => {
		if (videoEnable == false && micEnable == false) {
			console.log("Please enable either mic or video to complete the call");
		} else {
			try {
				console.log("callType: " + callType);
				// Open the camera
				const stream = await navigator.mediaDevices.getUserMedia({
					video: videoEnable,
					audio: micEnable,
				});

				// Get local and remote video elements
				// const localVideoElement = document.getElementById("localVideo");
				// const remoteVideoElement = document.getElementById("remoteVideo");

				// Set local video stream
				if (videoEnable) {
					localVideoElement.srcObject = stream;
				} else {
					localVideoElement.srcObject = null;
				}

				// Make video call using Boostlingo JS SDK

				const callRequest = {
					languageFromId: parseInt(selectedValue?.sourceLangCode), // Sample language IDs, replace with actual values
					languageToId: parseInt(selectedValue?.targetLangCode),
					serviceTypeId: parseInt(selectedValue?.serviceTypeCode),
					genderId: parseInt(selectedValue?.genderCode),
				};

				if (callType === "audio") {
					const callProgress = await blObj.makeVoiceCall(callRequest);
					setCurrentCall(callProgress);
					updateCallProgress(callProgress);
				} else {
					const callProgress = await blObj.makeVideoCall(
						callRequest,
						localVideoElement,
						remoteVideoRef.current
					);
					setCurrentCall(callProgress);
					updateCallProgress(callProgress);
				}
				console.log(callType + " call initiated successfully");
				updateLogDetails(callType + " call initiated successfully");
				console.log(blObj, "blObj");
			} catch (error) {
				if(error){
					updateLogDetails("Error initiating " + callType + " call:", error);
					console.error("Error initiating " + callType + " call:", error);
			}
			}
		}
	};

	const updateCallProgress = currentLocalCall => {
		if (currentLocalCall != null && currentLocalCall != undefined) {
			console.log("call: " + currentLocalCall);
			updateLogDetails("Call is successfully connected...");
			// currentLocalCall = call;
			console.log("currentLocalCall: " + currentLocalCall);
			// event that let's you know when remote participant has joined call
			currentLocalCall.on("callConnected", () => updateLogDetails("Interpreter joined call"));

			// event fires when call is completed or cancelled
			currentLocalCall.on("callCompleted", cancelled => {
				updateLogDetails("Call completed " + (cancelled ? "before" : "after") + " connect");

				currentLocalCall = null;
				resetCallButtons();
			});

			// event when the remote participant information is available
			// once available, can also be obtained with call.getInterlocutorInfo())
			currentLocalCall.on("interlocutorInfo", info => {
				updateLogDetails("Interpreter info available", info);
				updateLogDetails(
					"Name:" + info.requiredName + " Company:" + info.companyName + " Rating:" + info.rating
				);
				setAgentDetails({
					name: info.requiredName,
					company: info.companyName,
					rating: info.rating,
				});
			});

			// events for when local and remote audio is shared and/or attached to DOM
			currentLocalCall.on("localAudioShared", isEnabled => {
				updateLogDetails("Started sharing local audio", isEnabled);
				// should just be one button, but showing both for testing purposes
				// $("#button-mute").fadeIn();
				// $("#button-unmute").fadeIn();
			});

			currentLocalCall.on("remoteAudioShared", isEnabled =>
				updateLogDetails("Started sharing remote audio", isEnabled)
			);

			// events for when local and remote audio is unshared and/or removed from DOM
			currentLocalCall.on("localAudioUnshared", () => updateLogDetails("Local audio unshared"));
			currentLocalCall.on("remoteAudioUnshared", () => updateLogDetails("Remote audio unshared"));

			// events for when local and remote audio is enabled (unmuted)
			// does not fire initially if shared in isEnabled state
			currentLocalCall.on("localAudioEnabled", () => updateLogDetails("Local audio enabled")); // could toggle mute/unmute
			currentLocalCall.on("remoteAudioEnabled", () => updateLogDetails("Remote audio enabled"));

			// events for when local and remote audio is disabled (muted)
			currentLocalCall.on("localAudioDisabled", () => updateLogDetails("Local audio disabled"));
			currentLocalCall.on("remoteAudioDisabled", () => updateLogDetails("Remote audio disabled"));
		}
	};

	const updateLogDetails = logMessage => {
		let currentLog = callLog;
		logMessage = "> " + logMessage;
		currentLog = logMessage + "\n" + currentLog;
		setCalLog(currentLog);
	};

	const resetCallButtons = () => {};

	const handleHangupCall = async () => {
		updateLogDetails("Hanging up " + callType + " call...");
		// current call can also be obtained with blJS.getCurrentCall()
		// currentCall._completed == true >> Means the call is completed
		if (currentCall) {
			console.log(currentCall);
			const hangupStatus = await currentCall.hangup();
			updateLogDetails("The " + callType + " call is successfully closed.");
			callCompletedProcess();
		} else {
			updateLogDetails("There is no active " + callType + " call..");
		}
	};

	const handleMute = () => {
		if (mediaStream) {
			mediaStream.getAudioTracks().forEach(track => {
				track.enabled = false; // Disable the audio track
			});
			console.log("Audio muted.");
			updateLogDetails("Audio muted.");
		}
	};

	const handleUnmute = () => {
		// Unmute the audio
		if (mediaStream) {
			mediaStream.getAudioTracks().forEach(track => {
				track.enabled = true; // Enable the audio track
			});
			console.log("Audio unmuted.");
			updateLogDetails("Audio unmuted.");
		}
	};

	const handlePause = () => {
		// Implement pause logic
		setPauseLayer(true);
		updateLogDetails("Pause button clicked");
		if (localVideoRef.current) {
			localVideoRef.current.pause();
			console.log(currentCall);
			if (currentCall) {
				currentCall.pauseVideo();
			}
		}
	};

	const handleUnpause = () => {
		// Implement unpause logic
		setPauseLayer(false);
		updateLogDetails("Unpause button clicked");
		if (localVideoRef.current) {
			localVideoRef.current.play();
		}
	};

	const setCallDetails = e => {
		// updateLogDetails("localVideoElement: " + localVideoElement);
		//updateLogDetails("localVideoElement: " + mediaStream);
		if (e === "audio") {
			setMicEnable(true);
			setVideoEnable(false);
			localVideoElement.srcObject = null;
		}
		if (e === "video") {
			setVideoEnable(true);
			setMicEnable(true);
			localVideoElement.srcObject = mediaStream;
		}
	};

	return (
		<Box bg="#fff" h="100%">
			<Box>
				{selectedValue ? (
					<Flex w={"100%"} p={0} m={0}>
						{/* <Text>
              {selectedValue?.sourceLangCode}-{selectedValue?.sourceLangName}/
              {selectedValue?.targetLangCode}-{selectedValue?.targetLangName}/
              {selectedValue?.serviceTypeCode}-{selectedValue?.serviceName}/
              {selectedValue?.genderCode}
            </Text> */}
					</Flex>
				) : null}
			</Box>
			<Tabs variant="soft-rounded" colorScheme="blue" mt="1" p="1px">
				<TabList bg="#000" p="2" borderRadius="10px" w="fit-content" mx="auto">
					<Tab
						onClick={e => {
							setCallType("audio");
							setCallDetails("audio");
							setCallStarted(null);
							callCompletedProcess();
							setHangupFlag(false);
							resetTimer();
						}}
						borderRadius={"10px"}
						color="#fff"
						isDisabled={callTrial && !callReadiness ? true : false}>
						<Icon as={AiOutlineAudio} mr={1} /> Audio{" "}
						{callType === "audio" && time > 0 ? (
							<>
								<Flex
									ml={1}
									py={".5"}
									rounded={"md"}
									minW={"80px"}
									maxW={"80px"}
									bg={"blue.300"}
									align={"center"}>
									<Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
									{formatTime(time)}
								</Flex>
							</>
						) : null}
					</Tab>
					<Tab
						onClick={e => {
							setCallType("video");
							setCallDetails("video");
							setCallStarted(null);
							callCompletedProcess();
							setHangupFlag(false);
							resetTimer();
						}}
						borderRadius={"10px"}
						color="#fff"
						isDisabled={callTrial && !callReadiness ? true : false}>
						<Icon as={AiOutlineVideoCamera} mr={1} />
						Video
						{callType === "video" && time > 0 ? (
							<>
								<Flex
									ml={1}
									py={".5"}
									rounded={"md"}
									minW={"80px"}
									maxW={"80px"}
									bg={"blue.300"}
									align={"center"}>
									<Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
									{formatTime(time)}
								</Flex>
							</>
						) : null}
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel
						h={{
							base: isLandscape ? "auto" : "calc(100vh - 260px)", // "60vh",
							md: "calc(100vh - 260px)", // "50vh",
							xl: "calc(100vh - 250px)", // "50vh",
							"2xl": "calc(100vh - 200px)", // "50vh",
						}}
						w="100%">
						<Flex
							alignItems={"center"}
							justifyContent={"space-between"}
							borderWidth={"1px"}
							h="100%"
							w="100%"
							flexWrap={"wrap"}>
							<Flex
								alignItems={"center"}
								justifyContent={"center"}
								w={{
									base: "100%",
									md: "50%",
									xl: "50%",
									"2xl": "50%",
								}}
								h={{
									base: "50%",
									md: "100%",
									xl: "100%",
									"2xl": "100%",
								}}
								borderBottomWidth={{
									base: "1px",
									md: "0px",
									xl: "0px",
									"2xl": "0px",
								}}
								borderRightWidth={{
									base: "0px",
									md: "1px",
									xl: "1px",
									"2xl": "1px",
								}}>
								<Box>
									{callStarted === null ? (
										<Icon
											as={FaPhone}
											fontSize="10rem"
											borderWidth={"1px"}
											borderRadius={"50%"}
											p="2rem"
											color={"gray"}
										/>
									) : callStarted ? (
										// <Icon
										//   as={FaPhone}
										//   fontSize="10rem"
										//   borderWidth={"1px"}
										//   borderRadius={"50%"}
										//   borderColor={"green.500"}
										//   p="2rem"
										//   bg={"green.500"}
										// />
										<Image src={callingGif} w="50%" mx="auto" />
									) : (
										<Icon
											as={FaPhoneSlash}
											fontSize="10rem"
											borderWidth={"1px"}
											borderRadius={"50%"}
											borderColor={"red.500"}
											bg={"red.500"}
											p="2rem"
										/>
									)}
								</Box>
							</Flex>
							<Textarea
								value={callLog}
								color="#000"
								rows={10}
								border="0"
								p="1"
								w={{
									base: "100%",
									md: "50%",
									xl: "50%",
									"2xl": "50%",
								}}
								h={{
									base: "50%",
									md: "100%",
									xl: "100%",
									"2xl": "100%",
								}}
								borderBottom={{
									base: "1px",
									md: "0px",
									xl: "0px",
									"2xl": "0px",
								}}
								borderColor={"#d7d7d7"}
							/>
						</Flex>
					</TabPanel>
					<TabPanel
						h={{
							base: isLandscape ? "auto" : "calc(100vh - 260px)", // "60vh",
							md: "calc(100vh - 260px)", // "50vh",
							xl: "calc(100vh - 250px)", // "50vh",
							"2xl": "calc(100vh - 190px)", // "50vh",
						}}
						w="100%">
						<Flex
							alignItems={"center"}
							justifyContent={"space-between"}
							borderWidth={"1px"}
							h="100%"
							w="100%"
							flexWrap={"wrap"}>
							<Flex
								position={"relative"}
								w={{
									base: "100%",
									md: "50%",
									xl: "50%",
									"2xl": "50%",
								}}
								h={{
									base: "50%",
									md: "100%",
									xl: "100%",
									"2xl": "100%",
								}}
								borderBottom={{
									base: "1px",
									md: "0px",
									xl: "0px",
									"2xl": "0px",
								}}
								borderColor={"#d7d7d7"}>
								<Box
									w={{
										base: "50%",
										md: "100%",
										xl: "100%",
										"2xl": "100%",
									}}>
									<video
										ref={remoteVideoRef}
										autoPlay
										style={{
											width: "100%",
											height: "100%",
											borderRight: "1px solid #d7d7d7",
										}}
										id="remoteVideo"></video>
								</Box>
								<Box
									position={{
										base: "relative",
										md: "absolute",
										xl: "absolute",
										"2xl": "absolute",
									}}
									right="0"
									bottom="0"
									w={{
										base: "50%",
										md: "30%",
										xl: "30%",
										"2xl": "30%",
									}}>
									<video
										ref={localVideoRef}
										autoPlay
										muted
										id="localVideo"
										style={{
											width: "100%",
										}}></video>
									{/* {pauseLayer ? (
                    <Box
                      w="45%"
                      h="300px"
                      position="absolute"
                      bg="#2d3748"
                      opacity="0.5"
                    ></Box>
                  ) : null} */}
								</Box>
							</Flex>
							<Textarea
								color="#000"
								w={{
									base: "100%",
									md: "50%",
									xl: "50%",
									"2xl": "50%",
								}}
								h={{
									base: "50%",
									md: "100%",
									xl: "100%",
									"2xl": "100%",
								}}
								value={callLog}
								rows={10}
								border="0"
								p="3"
							/>
						</Flex>
					</TabPanel>
				</TabPanels>
			</Tabs>
			<Flex
				direction={"column"}
				rounded={"md"}
				borderColor={"gray.300"}
				borderWidth={"0.5px"}
				w={"100%"}
				h={"80px"}>
				{" "}
				{/* Start of Bottom Control box */}
				<Box w={"100%"} bg="#fff">
					{callTrial ? (
						callReadiness ? (
							<Box w={"100%"}>
								<Flex
									roundedTop={"md"}
									alignItems={"center"}
									bg="black"
									p="2px"
									justifyContent={"center"}>
									<Button size="sm" rounded="md" colorScheme="yellow">
										Trial
									</Button>
									<Text ml="2" color="#fff">
										<Text ml={1} as="span">
											Only {callData[1]}/{callData[0]} voice & {callData[3]}/{callData[2]} video
											calls left.
										</Text>
									</Text>
								</Flex>
							</Box>
						) : (
							<Flex w={"100%"} alignItems={"center"} bg="black" p="2px" justifyContent={"center"}>
								<Text color={"red.300"} as="span" ml="1">
									You can't make calls as your Trial is expired.
								</Text>
							</Flex>
						)
					) : (
						<Flex w={"100%"} alignItems={"center"} bg="black" p="2px" justifyContent={"center"}>
							<Text color="green.200">Enjoy Full version of Lingolink</Text>
						</Flex>
					)}
				</Box>
				{callReadiness ? (
					<Flex
						flexWrap="wrap"
						id="call-controls"
						mt="1px"
						align={"center"}
						justifyContent={"center"}
						bg="#fff">
						{!hangupFlag ? (
							<Button
								id="button-call"
								onClick={() => {
									callStartedProcess();
									setHangupFlag(true);
									startTimer();
								}}
								mr="2"
								colorScheme="green"
								size="sm"
								rounded="none"
								mt="2px">
								{callType === "audio" ? (
									<Icon as={AiOutlineAudio} mr={1} />
								) : (
									<Icon as={AiOutlineVideoCamera} mr={1} />
								)}{" "}
								Call
							</Button>
						) : null}
						{hangupFlag ? (
							<Button
								id="button-hangup"
								// onClick={handleHangupCall}
								onClick={() => {
									callCompletedProcess();
									setHangupFlag(false);
									stopTimer();
								}}
								mr="2"
								colorScheme="red"
								size="sm"
								rounded="none"
								mt="2px">
								Hangup
							</Button>
						) : null}
						{!muteUnmuteFlag ? (
							<Button
								id="button-mute"
								onClick={() => {
									handleMute();
									setMuteUnmuteFlag(true);
								}}
								mr="2"
								variant="outline"
								colorScheme="red"
								size="sm"
								rounded="none"
								mt="2px">
								Mute
							</Button>
						) : null}
						{muteUnmuteFlag ? (
							<Button
								id="button-unmute"
								onClick={() => {
									handleUnmute();
									setMuteUnmuteFlag(false);
								}}
								mr="2"
								variant="outline"
								colorScheme="green"
								size="sm"
								rounded="none"
								mt="2px">
								Unmute
							</Button>
						) : null}
						{callType === "video" ? (
							<>
								{!pauseUnpauseFlag ? (
									<Button
										id="button-pause"
										onClick={() => {
											handlePause();
											setPauseUnpauseFlag(true);
										}}
										mr="2"
										colorScheme="purple"
										size="sm"
										rounded="none"
										mt="2px">
										Pause
									</Button>
								) : null}
								{pauseUnpauseFlag ? (
									<Button
										id="button-unpause"
										onClick={() => {
											handleUnpause();
											setPauseUnpauseFlag(false);
										}}
										variant="outline"
										colorScheme="purple"
										size="sm"
										rounded="none"
										mt="2px">
										Unpause
									</Button>
								) : null}
							</>
						) : null}
					</Flex>
				) : null}
			</Flex>{" "}
			{/* End of Bottom Control Button and Trial message Rectangle */}
		</Box>
	);
};

export default BLCommunicate;
