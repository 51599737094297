import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { AiOutlineHome, AiOutlineInfoCircle, AiOutlineMonitor, AiOutlineSetting, AiOutlineAppstore } from "react-icons/ai";
import {
	Spacer,
	Icon,
	Box,
	Link,
	Button,
	Center,
	Spinner,
	Heading,
	Flex,
	Image,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useMediaQuery,
} from "@chakra-ui/react";
// Api Call Imports
import Iframe from "react-iframe";

// Component Imports
import HumanAIBg from "../../assets/communicator/HumanIcon.png";
import DocumentAiLightBg from "../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../assets/communicator/multichannelai.png";
import DocumentAiDarkBg from "../../assets/communicator/DocumentAIDark.png";
import AITranslateBg from "../../assets/communicator/AIIcon.png";
import BasicAIBg from "../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../assets/communicator/Premium_AIIcon.png";
import AIBgImage from "../../assets/communicator/HomeBackground.png";
import logo from "../../assets/landingPageImg/kb_logo.png";
import lsplogo from "../../assets/landingPageImg/lspcloud_logo-wide.png";
import { verifyPageEx, premiumCallStartedEx } from "../../services/dynamicPageSvc";
import BoostLingoSystem from "./components/BoostLingoSystem";
import TranslateSystem from "./components/TranslateSystem";

// Mixpanel - Page Load Event
// https://mixpanel.com/report/3254045/view/3762305/setup/
import mixpanel, { time_event } from "mixpanel-browser";
import BasicAISettingsComp from "./components/BasicAIComponent/BasicAISettingsComp";
import { setHomeRouteFlag } from "../../store/actions/topNotificationActions";
import MultiChannelSettings from "./components/MultiChannel/MultiChannelSettings";

const DynamicHome = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, isLoading] = useState(false);
	const [isLandscape] = useMediaQuery("(orientation: landscape)");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [bgImage, setBgImage] = useState("");
	const [featureData, setFeatureData] = useState([]);
	const [dynamicLogo, setDynamicLogo] = useState(logo);
	const boostlingoKey = process.env.REACT_APP_BOOSTLINGO_KEY;
	const launchKey = state?.launchKey ? state?.launchKey : null;
	const launchCode = state?.launchCode ? state?.launchCode : null;
	const sessionKey = state?.sessionKey ? state?.sessionKey : null;

	// Todo:Handle Home Route From Basic AI Function (Redux Store)
	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { homeRoute } = sideMenuStatus;

	useEffect(() => {
		if (homeRoute) {
			setCurrentSelection(null);
			dispatch(setHomeRouteFlag(false));
		}
	}, [homeRoute]);

	// // Mix Panel Integration
	// const mixpanelJsProductKey = process.env.REACT_APP_MIXPANEL_PRODUCT_KEY;
	// mixpanel.init(mixpanelJsProductKey, {
	//   debug: true,
	//   track_pageview: true,
	//   persistence: "localStorage",
	// });
	// // Set this to a unique identifier for the user performing the event.
	// mixpanel.identify("LingoletLink_Launch");
	// // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
	// mixpanel.track("LingolinkHome", {
	//   LinkType: "LingoletHome",
	//   Session: sessionKey,
	//   launchKey: launchKey,
	// });

	const [currentSelection, setCurrentSelection] = useState(null);
	const [pageValid, setPageValid] = useState(false);
	const [timeLeft, setTimeLeft] = useState(
		state?.timeLeft
			? state?.timeLeft[0] * 24 * 60 * 60 + state?.timeLeft[1] * 60 * 60 + state?.timeLeft[2] * 60 + state?.timeLeft[3]
			: [0, 0, 0, 0]
	);

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://s3-us-west-2.amazonaws.com/connect.boostlingo.com/sdk/boostlingo-js/1.0.4/dist/boostlingo.min.js";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const loadBl = () => {
		let boostInfo = JSON.parse(boostlingoKey);
		var blJS = new window.BoostLingo(boostInfo.token);
		return blJS;
	};

	// TODO: Validate the Key and get the latest Time available
	useEffect(() => {
		runPageValidation();
	}, []);

	const runPageValidation = () => {
		isLoading(true);
		if (launchKey != null && sessionKey != null) {
			let paramObj = {
				launchKey: launchKey,
				launchSession: sessionKey,
			};
			verifyPageEx(paramObj)
				.then(res => {
					isLoading(false);
					if (res?.data[0]?.status) {
						setBgImage(res?.data?.[0]?.config?.background ? res?.data?.[0]?.config?.background : AIBgImage);
						setDynamicLogo(res?.data?.[0]?.config?.logo ? res?.data?.[0]?.config?.logo : logo);
						setFeatureData(res?.data?.[0]?.features?.length ? res?.data?.[0]?.features.split(",") : []);
						setPageValid(true);
						setTimeLeft(
							res?.data[0]?.timeLeft[0] * 24 * 60 * 60 +
								res?.data[0]?.timeLeft[1] * 60 * 60 +
								res?.data[0]?.timeLeft[2] * 60 +
								res?.data[0]?.timeLeft[3]
						);
					} else {
						setBgImage(AIBgImage);
						setDynamicLogo(logo);
					}
				})
				.catch(err => {
					isLoading(false);
					setBgImage(AIBgImage);
					setDynamicLogo(logo);
				});
		} else {
			isLoading(false);
		}
	};

	const getBLObject = () => {
		return loadBl();
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [state?.timeLeft]);

	function formatTimeLeft(totalSeconds) {
		if (totalSeconds) {
			const remainingDays = Math.floor(totalSeconds / (24 * 60 * 60));
			const remainingHours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
			const remainingMinutes = Math.floor((totalSeconds % (60 * 60)) / 60);
			const remainingSeconds = totalSeconds % 60;

			return `${remainingDays} days, ${remainingHours}:${remainingMinutes}:${remainingSeconds}`;
		}
	}

	const registerPremiumAiCall = () => {
		isLoading(true);
		if (launchKey != null && sessionKey != null) {
			let paramObj = {
				feature: "pi",
				launchKey: launchKey,
				launchSession: sessionKey,
			};
			premiumCallStartedEx(paramObj)
				.then(res => {
					isLoading(false);
					if (res?.data[0]?.status) {
					}
				})
				.catch(err => {
					isLoading(false);
				});
		} else {
			isLoading(false);
		}
	};

	return (
		<>
			<Box direction="column" h="100vh">
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="90vh" color="#fff">
						<Spinner size="xl" color="#000" />
					</Flex>
				) : launchKey != null && sessionKey != null && pageValid ? (
					<Flex
						w={"100%"}
						h="100%"
						direction={"column"}
						align={"center"}
						backgroundImage={bgImage}
						backgroundPosition="center"
						backgroundRepeat="no-repeat"
						backgroundSize={"100% 100%"}
					>
						<Flex w={"100%"} borderBottomWidth={"2px"} bg={"gray.700"} py={2} align={"center"}>
							<Image
								maxH={"50px"}
								w={{
									base: "100px",
									md: "100px",
									xl: "100px",
									sm: "100px",
								}}
								src={dynamicLogo}
							/>
							<Spacer />
							{launchKey != null && launchCode != null && sessionKey != null ? (
								<Icon
									onClick={() => {
										navigate("/lingolink/management", {
											state: {
												linkKey: launchKey,
												linkCode: launchCode,
												linkSession: sessionKey,
											},
										});
									}}
									ml={2}
									mr={2}
									h={5}
									w={5}
									as={AiOutlineAppstore}
									color={"white"}
								/>
							) : null}
							<Icon onClick={() => setCurrentSelection(null)} ml={2} mr={2} h={5} w={5} as={AiOutlineHome} color={"white"} />
							<Flex w={"200px"} bg={"gray.800"} rounded={"md"} align={"center"}>
								<Text ml={1} p={1} rounded={"md"} bg={"gray.800"} color={"gray.100"}>
									{formatTimeLeft(timeLeft)}
									{" Left"}
								</Text>
							</Flex>
							<Icon onClick={onOpen} ml={2} h={5} w={5} as={AiOutlineInfoCircle} color={"white"} />
						</Flex>
						<Flex w="100%" h="100%" alignItems={"center"} justifyContent={"center"}>
							{currentSelection === null ? (
								// TODO - Check if multichannel == True 
								// Only show multichannel icon
								// otherwise show icon based on link
								<Flex
									w={"80%"}
									h={{
										base: isLandscape ? "100%" : "50%",
										md: "100%",
										xl: "100%",
										"2xl": "100%",
									}}
									flexWrap={"wrap"}
									alignItems={"center"}
									justifyContent={"center"}
								>
									<Flex
										mr="1%"
										display={featureData.includes("hi") ? "block" : "none"}
										w={{
											base: isLandscape ? "25%" : "49%",
											md: "24%",
											xl: "24%",
											"2xl": "24%",
										}}
										h={{
											base: isLandscape ? "80%" : "48%",
											md: "48%",
											xl: "50%",
											"2xl": "50%",
										}}
										// bg={"gray.300"}
										rounded={"lg"}
										backgroundImage={HumanAIBg}
										backgroundPosition="center"
										backgroundRepeat="no-repeat"
										backgroundSize={"contain"}
									>
										<Center
											w={"100%"}
											h={"100%"}
											_hover={{
												cursor: "pointer",
												// borderWidth:'1px',
												// borderColor:'gray.100'
											}}
											onClick={() => {
												setCurrentSelection("human");
											}}
										>
											{/* <Heading fontSize="24px">Human Interpretation</Heading> */}
										</Center>
									</Flex>
									<Flex
										mr="1%"
										display={featureData.includes("bi") ? "block" : "none"}
										w={{
											base: isLandscape ? "25%" : "49%",
											md: "24%",
											xl: "24%",
											"2xl": "24%",
										}}
										h={{
											base: isLandscape ? "80%" : "48%",
											md: "48%",
											xl: "50%",
											"2xl": "50%",
										}}
										rounded={"lg"}
										backgroundImage={BasicAIBg}
										backgroundPosition="center"
										backgroundRepeat="no-repeat"
										backgroundSize={"contain"}
									>
										<Center
											w={"100%"}
											h={"100%"}
											_hover={{
												cursor: "pointer",
												// borderWidth:'1px',
												// borderColor:'gray.100'
											}}
											onClick={() => {
												setCurrentSelection("basicai");
											}}
										>
											{/* <Heading fontSize="24px">Secure AI Translation </Heading> */}
										</Center>
									</Flex>
									<Flex
										mr="1%"
										display={featureData.includes("pi") ? "block" : "none"}
										w={{
											base: isLandscape ? "25%" : "49%",
											md: "24%",
											xl: "24%",
											"2xl": "24%",
										}}
										h={{
											base: isLandscape ? "80%" : "48%",
											md: "48%",
											xl: "50%",
											"2xl": "50%",
										}}
										rounded={"lg"}
										// bg={'gray.100'}
										backgroundImage={PremiumAIBg}
										backgroundPosition="center"
										backgroundRepeat="no-repeat"
										backgroundSize={"contain"}
									>
										<Center
											w={"100%"}
											h={"100%"}
											_hover={{
												cursor: "pointer",
												// borderWidth:'1px',
												// borderColor:'gray.100'
											}}
											onClick={() => {
												registerPremiumAiCall();
												setCurrentSelection("premiumai");
											}}
										>
											{/* <Heading fontSize="24px">Premium AI Interpretation</Heading> */}
										</Center>
									</Flex>
									<Flex
										display={featureData.includes("di") ? "block" : "none"}
										w={{
											base: isLandscape ? "25%" : "49%",
											md: "24%",
											xl: "24%",
											"2xl": "24%",
										}}
										h={{
											base: isLandscape ? "80%" : "48%",
											md: "48%",
											xl: "50%",
											"2xl": "50%",
										}}
										rounded={"lg"}
										// bg={'gray.100'}
										backgroundImage={DocumentAiLightBg}
										backgroundPosition="center"
										backgroundRepeat="no-repeat"
										backgroundSize={"contain"}
									>
										<Center
											w={"100%"}
											h={"100%"}
											_hover={{
												cursor: "pointer",
												// borderWidth:'1px',
												// borderColor:'gray.100'
											}}
											onClick={() => {
												setCurrentSelection("document");
											}}
										>
											{/* <Heading fontSize="24px">Document Translation</Heading> */}
										</Center>
									</Flex>
									<Flex
										display={featureData.includes("mi") ? "block" : "none"}
										w={{
											base: isLandscape ? "25%" : "49%",
											md: "24%",
											xl: "24%",
											"2xl": "24%",
										}}
										h={{
											base: isLandscape ? "80%" : "40%",
											md: "40%",
											xl: "40%",
											"2xl": "40%",
										}}
										rounded={"xl"}
										//bg={"gray.100"}
										backgroundImage={MultichannelBg}			
										backgroundPosition="center"
										backgroundRepeat="no-repeat"
										backgroundSize={"contain"}
									>
										<Center
											rounded={"xl"}
											w={"100%"}
											h={"100%"}
											_hover={{
												cursor: "pointer",
												// borderWidth:'1px',
												// borderColor:'gray.100'
											}}
											onClick={() => {
												setCurrentSelection("multiChannel");
											}}
										>
											{/* <Text fontWeight="600" fontSize={"20px"} color="red">
												Multichannel communicator
											</Text> */}
										</Center>
									</Flex>
								</Flex>
							) : currentSelection === "premiumai" ? (
								<Flex
									w={"100%"}
									h={"100%"}
									backgroundImage={bgImage}
									backgroundPosition="center"
									backgroundRepeat="no-repeat"
									backgroundSize={"100% 100%"}
									rounded={"lg"}
								>
									<Box w={"100%"} h={"100%"} rounded={"md"}>
										<Iframe
											url={"https://lingolet.onemeta.ai/?session=" + sessionKey}
											allow="camera; microphone"
											width="100%"
											height="100%"
											id=""
											className=""
											display="block"
											position="relative"
										/>
									</Box>
								</Flex>
							) : currentSelection === "basicai" ? (
								<Box w={"100%"} h={"100%"} rounded={"lg"}>
									<BasicAISettingsComp sessionKey={sessionKey} launchKey={launchKey} launchCode={launchCode} />
								</Box>
							) : currentSelection === "human" ? (
								<Flex w={"100%"} h={"100%"} rounded={"lg"}>
									<BoostLingoSystem sessionKey={sessionKey} launchKey={launchKey} blObj={getBLObject()} selectedOption={"human"} />
								</Flex>
							) : currentSelection === "document" ? (
								<Flex w={"100%"} h={"100%"} rounded={"lg"}>
									<TranslateSystem />
								</Flex>
							) : currentSelection === "multiChannel" ? (
								<Flex w={"100%"} h={"100%"} rounded={"lg"}>
									<MultiChannelSettings sessionKey={sessionKey} launchKey={launchKey} launchCode={launchCode} />
								</Flex>
							) : null}
						</Flex>
						<Flex w={"100%"} bg={"gray.700"} py={2} align={"top"} px={2} h={"50px"} justifyContent={"flex-end"}>
							<Text color={"gray.500"}>{sessionKey}</Text>
						</Flex>
					</Flex>
				) : (
					<Flex p={5} w={"100%"} direction={"column"} align={"center"} bg={"red.100"}>
						<Heading color="#gray.800" fontSize={"26px"} fontWeight={"normal"}>
							Hmm....{" "}
							<Text as="span" fontWeight={"600"} mr="1">
								Something is not right!!
							</Text>
						</Heading>
						<Text mt="3" fontSize={"17px"} color="gray.700">
							Looks like you don't have enough credentials to access this page..
						</Text>
						<Link
							_hover={{ cursor: "pointer" }}
							p={1}
							bg={"gray.200"}
							rounded={"md"}
							mt={2}
							href={"https://lingolet.ai"}
							w={{ base: "100%", "2xl": "unset" }}
						>
							<Text mt="1" fontSize={"17px"} color="blue.600" px={2}>
								Back to Lingolet Home Page
							</Text>
						</Link>
					</Flex>
				)}
			</Box>

			{/* Todo:Popup Modal */}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent h={"300px"}>
					<ModalHeader>Lingolet AI Platform</ModalHeader>
					<ModalCloseButton />
					<ModalBody align={"center"} p={5}>
						<Image
							mb={5}
							w={{
								base: "200px",
								md: "200px",
								xl: "200px",
								sm: "200px",
							}}
							src={logo}
						/>
						<Link href="https://lingolet.ai" target={"_blank"}>
							<Text>https://lingolet.ai</Text>
						</Link>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default DynamicHome;
