import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Chakra Import
import {
  Box,
  Flex,
  Text,
  Select,
  Button,
  Alert,
  Drawer,
  Spinner,
  AlertIcon,
  FormLabel,
  SimpleGrid,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorModeValue,
  useDisclosure,
  Textarea,
  useToast,
  Heading,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../components/card/Card";

// Api Call Imports
import {
  getDevBizList, getDevBizTeamList, assignBizDevice,
} from "../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

function DevicesList() {
    const toast = useToast();
    const state = useState();
    const navigate = useNavigate();
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const btnRef = React.useRef();
    const [loading, isLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [deviceList, setDeviceList] = useState([]);
    const [deviceUuid, setDeviceUuid] = useState(null);
    const [assignUuid, setAssignUuid] = useState(null);
    const [assignmentError, setAssignmentError] = useState(null);
    const [deviceReturnCustomer, setDeviceReturnCustomer] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    
    
    //   Todo:Fetch all projects
    useEffect(() => {
        fetchAllDevices();
        isLoading(false);
    }, []);
  
    const fetchAllDevices = () => {
        isLoading(true);
        let paramObj = {
          // filter_key: "string",
          // filter_value: "string",
          records_per_page: 0,
          page_id: 0,
        };
        getDevBizList(paramObj)
          .then((res) => {
            isLoading(false);
            if (res?.data?.length > 0) {
                setDeviceList(res?.data[0]?.records);
            } else {
              setDeviceList([]);
            }
          })
          .catch((err) => {
            isLoading(false);
          });
    };

    const fetchBusinessTeam = () => {
      let paramObj = {
        records_per_page: 0,
        page_id: 0,
      };
      getDevBizTeamList(paramObj)
        .then((res) => {
          if (res?.data?.length > 0) {
            setTeamList(res?.data[0]);
          } else {
            setTeamList([]);
          }
        })
        .catch((err) => {
          isLoading(false);
        });
   };

   const assignTeamMemberFunc = () => {
    let paramObj = {
      device_uuid: deviceUuid,
      user_uuid: selectedMember,
      assign_uuid: assignUuid
    };
    setAssignmentError(null);
    assignBizDevice(paramObj)
      .then((res) => {
        if (res?.data?.length > 0 && res?.data[0]?.status) {
          setAssignmentError(null);
          toast(toastFunctionToaster("Device assignment is completed successfully", "success"));
          fetchAllDevices();
          onClose();
        } else {
          setAssignmentError('Unable to assign the device to selected team member..');
        }
      })
      .catch((err) => {
        setAssignmentError('Unable to assign the device to selected team member..');
      });
   };

   const assignTeamMember = () => {
      assignTeamMemberFunc();
   }

    const renderDevicesList = deviceList !== null && deviceList?.length > 0
    ? deviceList?.map((item, index) => (
        <Flex key={index} 
            w={'100%'} mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                <Text rounded={'md'} bg={item?.deviceObj?.assigned ? 'green.200' : null} pl={2}>
                  {moment(item?.assigned_date).format("MMM DD,YYYY,HH:mm a")}
                </Text>
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.deviceObj?.type} ({item?.deviceObj?.class})
                <Text fontSize={'xs'}>Serial: {item?.deviceObj?.serial}</Text>
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.is_locked 
                ? <Text p={1} bg={'green.100'} rounded={'md'}>Yes</Text>
                : <Text p={1} bg={'red.100'} rounded={'md'}>No</Text>}
            </Box>
            <Box minW={'200px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.is_activated 
                ? <Flex w={'100%'} p={1} direction={'column'} bg={'green.100'} rounded={'md'}>
                    <Text>Yes</Text>
                    <Text  fontSize={'xs'}>On ({moment(item?.activation_date).format("MMM DD,YYYY,HH:mm a")})</Text>
                  </Flex>
                : <Text p={1} bg={'red.100'} rounded={'md'}>No</Text>}
            </Box>
            <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                {item?.activation_code}
            </Box>
            <Box w={'100%'} fontSize={'sm'} ml={1} pl={2}>
                {item?.email_assigned ? item?.email_assigned : 'No Info'}
            </Box>
            <Box minW={'200px'} fontSize={'sm'} pr={2}>
                <Button 
                    onClick={() => {
                        setSelectedMember(null);
                        setDeviceUuid(item?.deviceObj?.uuid);
                        setAssignUuid(item?.uuid);
                        setDeviceReturnCustomer(item?.customer_send);
                        fetchBusinessTeam();
                        onOpen();
                    }}
                    size={'sm'}>Manage Device
                </Button>
                <Button ml={1} size={'sm'}>Info</Button>
            </Box>
        </Flex>
        ))
    : null;

    const renderTeamData = teamList?.map((team, index) => (
      <option key={index} value={team.uuid} style={{ color: "black" }}>
        {team.email}
      </option>
    ));

    const closeDrawer = () => {
      onClose();
      setDeviceUuid(null);
    };

    return (
        <>
          <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Flex
              p="3"
              alignItems="center"
              borderBottomWidth="1px"
              justifyContent={"space-between"}
            >
              <Text fontWeight={"500"} fontSize={"18px"}>
                Assigned Devices to your organization
              </Text>
              <Button colorScheme="blue" size="sm" onClick={() => fetchAllDevices()}>
                Refresh
              </Button>
            </Flex>
            {loading ? (
              <Flex alignItems="center" justifyContent="center" h="50vh">
                <Spinner size="xl" />
              </Flex>
            ) : deviceList?.length ? (
              <Flex w={'100%'} direction={'column'}>
                  <Flex w={'100%'} bg={'gray.200'} py={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                      <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                          <Text fontSize={'xs'}>Assignment Date</Text>
                      </Box>
                      <Box minW={'150px'} fontSize={'xs'} ml={1} pl={2}>
                          <Text fontSize={'xs'}>Device</Text>
                      </Box>
                      <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                          <Text fontSize={'xs'}>Locked Status</Text>
                      </Box>
                      <Box minW={'200px'} fontSize={'sm'} ml={1} pl={2}>
                          <Text fontSize={'xs'}>Activation Status</Text>
                      </Box>
                      <Box minW={'150px'} fontSize={'sm'} ml={1} pl={2}>
                          <Text fontSize={'xs'}>Activation Code</Text>
                      </Box>
                      <Box w={'100%'} ml={1} pl={2}>
                          <Text fontSize={'xs'}>Internal Assignment</Text>
                      </Box>
                      <Box minW={'200px'} fontSize={'sm'} pr={5}  align={'end'}>
                          <Text fontSize={'xs'}>Action</Text>
                      </Box>
                  </Flex>
                  {renderDevicesList}
              </Flex>
            ) : (
              <Alert status="warning" mt="4">
                <AlertIcon />
                No Devices Found
              </Alert>
            )}
          </Box>
            {/* // Add Device Drawer */}
              <Drawer
                size="md"
                isOpen={isOpen}
                placement="right"
                onClose={() => {
                    closeDrawer();
                }}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerCloseButton bg={'gray.200'} />
                <DrawerHeader borderBottomWidth={"1px"}>
                    {deviceUuid ? "Manage" : "Create New"} Device
                </DrawerHeader>
                <DrawerBody mt="5">
                  {deviceUuid != null && assignUuid != null
                  ?<Flex w={'100%'} direction={'column'} mb={5} >
                    <Card w={'100%'} h={'300px'} p={5} borderWidth={'0.5px'} borderColor={'gray.300'}>
                      <Heading size={'md'} mb={5}>Assign Device to Team member</Heading>
                      <Text>Here you can assign this device to your team members:</Text>
                      {teamList?.length
                      ?<Flex w={'100%'} direction={'column'} 
                        p={2} rounded={'md'}
                        mb={5} mt={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                        <Select
                            value={selectedMember}
                            name="selectedMember"
                            id={'selectedMember'}
                            placeholder="Select Team member from the list"
                            size="md"
                            color={textColor}
                            borderRadius="16px"
                            onChange={e => {setSelectedMember(e.target.value)}}
                        >
                          {renderTeamData}                            
                        </Select>
                        {assignmentError != null
                        ?<Text mt={2} mb={2}>{assignmentError}</Text>:null}
                        {selectedMember != null
                        ?<Button mt={2}
                          onClick={() => assignTeamMember()}
                          size={'sm'} bg={'blue.100'}
                          >Assign Device to Selected Teammate</Button>:null}
                    </Flex>:null}
                    </Card>
                    {deviceReturnCustomer === false
                    ?<Card w={'100%'} mt={1} h={'300px'} p={5}  borderWidth={'0.5px'} borderColor={'gray.300'}>
                      <Heading size={'md'} mb={5}>Device Return Process</Heading>
                      <Text>If you have returned this device to Lingolet Team, please make sure you completed the return process by clicking the <b>"Complete Return Process"</b>:</Text>
                      <Button mt={5}
                          onClick={() => assignTeamMember()}
                          size={'sm'} bg={'blue.100'}
                          >Complete Device Return Process
                      </Button>
                      <Text mt={2} fontSize={'xs'}>Note: Please do not select above process if you haven't sent the device back to Lingolet Team</Text>
                    </Card>
                    :<Card w={'100%'} mt={1} h={'300px'} p={5}  borderWidth={'0.5px'} borderColor={'gray.300'}>
                      <Heading size={'md'} mb={5}>Device Return Process</Heading>
                      <Text>You have already completed the device return process. As soon as Lingolet get their device back, this device will be removed from your list.</Text>
                    </Card>}
                  </Flex>
                  :<Flex w={'100%'}><Text>Error with the device management</Text></Flex>}
                </DrawerBody>
                <DrawerFooter>
                    <Button mr={3} bg={'red.100'} onClick={onClose}>
                        Cancel
                    </Button>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default DevicesList;
