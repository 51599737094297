import React, { useEffect, useState } from "react";
import moment from "moment";
import {
    Box,
    Button,
    Input,
    Heading,
    Spinner,
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";

// Api Call Imports
import { useBrandBg, useGreenBg, useRedBg } from "../../../theme/globalColorTheme";
import { fetchLocationsAndSubs, fetchAccountEvents } from "../../../services/accountServices";

const LocationEvents = (props) => {
    const redBg = useRedBg()
    const greenBg = useGreenBg()
    const [loading, isLoading] = useState(false);
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const [eventValue, setEventValue] = useState("");
    const [subsId, setSubsId] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [eventsList, setEventsList] = useState([]);
    const [cloneEventList, setCloneEventList] = useState([])
    const [eventLoader, setEventLoader] = useState(false)



    useEffect(() => {
        collectLocationWithSubs();
        collectEventsData();
    }, []);


    const collectLocationWithSubs = (currentSelection) => {
        isLoading(true);
        let paramObj = {
            "account_uuid": null,
            key: currentSelection,
            records_per_page: 0,
            page_id: 0,
        };
        fetchLocationsAndSubs(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.locations) {
                    isLoading(false);
                    setLocationList(res?.data[0]?.locations);
                } else {
                    isLoading(false);
                    setLocationList([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setLocationList([]);
            });
    };


    const collectEventsData = (locationUuid, subscriberUuid) => {
        setEventLoader(true)
        let paramObj = {
            location_uuid: locationUuid,
            subscriber_uuid: subscriberUuid,
            "filter_key": "string",
            "filter_value": "string",
            "filter_choice": "today",
            "start_date": "string",
            "end_date": "string",
            "duration": "all",
            limit: 1000
        };
        fetchAccountEvents(paramObj)
            .then(res => {
                setEventLoader(false)
                if (res?.data?.length) {
                    isLoading(false);
                    setEventsList(res?.data);
                    setCloneEventList(res?.data[0]?.events)
                } else {
                    setEventsList([]);
                    setCloneEventList([]);
                }
            })
            .catch(err => {
                setEventLoader(false)
                setLocationList([]);
                setEventsList([]);
                setCloneEventList([]);
            });
    };

    useEffect(() => {
        if (eventValue?.length) {
            const filteredEvents = eventsList.filter(event => {
                return Object.values(event).some(value =>
                    String(value).toLowerCase().includes(eventValue.toLowerCase())
                );
            });
            setCloneEventList(filteredEvents)
        }
        else {
            setCloneEventList(eventsList)
        }
    }, [eventValue])

    const renderEventsList =
        cloneEventList !== null && cloneEventList?.length > 0 ? (
            cloneEventList?.map((eventItem, index) => (
                <Tr key={index + eventItem?.datetime_created}>
                    <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_caller}</Td>
                    <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_route}</Td>
                    <Td bg={eventItem?.event_status ? greenBg : redBg} borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{eventItem?.event_status ? 'Sucess' : 'Fail'}</Td>
                    <Td borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>{moment(eventItem?.datetime_created).format("MMM DD,YYYY,HH:mm a")}</Td>
                </Tr>
            ))
        ) : (
            null
        );

    // Todo:Render Location List
    const renderLocationList =
        locationList?.map((data, idx) => {
            return (
                <Box key={idx}
                    ml="10px"
                    position="relative"
                    _before={
                        data?.subs?.length ? {
                            content: "''",
                            width: '10px',
                            height: "1px",
                            bg: "#d7d7d7",
                            left: '-10px',
                            top: data?.subs?.length ? '36px' : null,
                            position: "absolute",
                            transition: "all .3s ease",
                        } : undefined}
                    _after={
                        data?.subs?.length ? {
                            content: "''",
                            width: '1px',
                            height: "87%",
                            bg: "#d7d7d7",
                            left: '-10px',
                            top: data?.subs?.length ? "37px" : null,
                            position: "absolute",
                            transition: "all .3s ease",
                        } : undefined}
                    mb="20px" >
                    <Box p="2" 
                        cursor={'pointer'}
                        _hover={{'bg': 'blue.100'}}
                        // bg={data?.user_uuid === subsId ? greenBg : null}
                        onClick={() => {
                            collectEventsData(data?.link_location_uuid)
                        }}
                        mb='2' border="1px" borderColor={'#d7d7d7'} borderRadius={'5px'}>
                        <Text fontWeight={'600'}>{data?.link_location_title}</Text>
                        <Text>{data?.link_location_name}</Text>
                    </Box>
                    <Box>
                        {
                            data?.subs?.length > 0 ? (
                                data.subs.map((subData, index) => (
                                    <Box
                                        position="relative"
                                        _before={{
                                            content: "''",
                                            width: '55px',
                                            height: "1px",
                                            bg: "#d7d7d7",
                                            left: '-55px',
                                            top: "36px",
                                            position: "absolute",
                                            transition: "all .3s ease",
                                        }}
                                        key={index}
                                        _hover={{'bg': 'blue.100'}}
                                        ml="45px" p="2" mb='2' border="1px"
                                        borderRadius={'5px'}
                                        // borderColor={subData?.disabled ? 'red.400' : greenBg}
                                        borderColor={'#d7d7d7'}
                                        cursor={'pointer'}
                                        bg={subData?.user_uuid === subsId ? greenBg : null}
                                        onClick={() => {
                                            collectEventsData(subData?.link_location_uuid, subData?.user_uuid)
                                            setSubsId(subData?.user_uuid)
                                        }}
                                    >
                                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                                            <Text fontWeight={'600'}>{subData?.first_name} {subData?.last_name}</Text>
                                            <Box h="5px" w="5px" borderRadius={'50px'} bg={subData?.disabled ? 'red.400' : 'green.400'}></Box>
                                        </Flex>
                                        <Text>{subData?.email}</Text>
                                    </Box>
                                ))
                            ) : null
                        }
                    </Box>
                </Box >
            );
        });

    return (
        <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card w={'100%'} mt={2}>
                <Flex h="80vh">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : "75%"}
                        bg={brandBg}
                        p="1"
                        borderRadius={"10px"}
                        overflowY="auto"
                    >
                        <Flex mb="3" alignItems="center" justifyContent={'space-between'}>
                            <Text fontWeight={'bold'} ml={1} >Events</Text>
                            {cloneEventList?.length ? (
                                <Input w="40%" placeholder='Search...' value={eventValue} onChange={(e) => { setEventValue(e.target.value) }} />
                            ) : null}
                        </Flex>
                        {eventLoader ? (
                            <Flex alignItems="center" justifyContent="center" h="50vh">
                                <Spinner size="lg" />
                            </Flex>
                        ) :
                            cloneEventList?.length ? (
                                <TableContainer border="1px solid #d7d7d7" borderRadius="5px">
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>Email</Th>
                                                <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>Route</Th>
                                                <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>Status</Th>
                                                <Th borderBottomWidth={'1px'} borderBottomColor={'#d7d7d7'}>Date</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>

                                            {renderEventsList}

                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            ) : <Flex w={"100%"} p={5} bg={redBg} borderLeftWidth={"5px"}
                                borderLeftColor={"red.500"}>
                                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                    No Event Data based on selection..
                                </Heading>
                            </Flex>}
                    </Box>
                    {configurationCollapseFlag ? (
                        <Box
                            w="25%"
                            position="relative"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2"
                        >
                            <Box
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="100%">
                                <Flex w={'100%'} direction={'column'}
                                    h="100%"
                                    overflow="auto"
                                    css={{
                                        '&::-webkit-scrollbar': {
                                            width: '4px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            width: '6px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            background: { brandBg },
                                            borderRadius: '24px',
                                        },
                                    }}>
                                    <Text fontSize={'lg'} fontWeight="600" mb="2" p={5} borderBottom={'1px'} borderColor={'#d7d7d7'}>
                                        Locations and Subscribers
                                    </Text>
                                    <Button onClick={() => collectEventsData()} bg={'blue.100'}>
                                        All Events
                                    </Button>
                                    {loading ? (
                                        <Flex alignItems="center" justifyContent="center" h="50vh">
                                            <Spinner size="lg" />
                                        </Flex>
                                    ) :
                                        locationList?.length ?
                                            <Box p="5">
                                                {renderLocationList}
                                            </Box> :
                                            (
                                                <Flex w={"100%"} p={5} bg={redBg} borderLeftWidth={"5px"} borderLeftColor={"red.500"}>
                                                    <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                                                        No Locations and Subscriber found..
                                                    </Heading>
                                                </Flex>
                                            )}

                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            w="5%"
                            p="3"
                            borderRadius="10px"
                            bg={brandBg}
                            h="100%"
                            ml="2"
                            pl="5"
                        >
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="3"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}
                                    fontWeight={'600'}
                                >
                                    Locations and Subscribers
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

export default LocationEvents;
