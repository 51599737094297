import React from "react"
import {
	Box,
	Flex,
	Image,
	Text,
	Button,
	Divider,
	useColorModeValue,
} from "@chakra-ui/react"
import FullScreenImage from "../../common/fullScreen/FullScreenImage"
import { usePillButtonBg, usePillButtonBgSelected, useTextColor } from '../../../theme/globalColorTheme';

function GettingStartedStatus() {
	const bgColor = useColorModeValue("gray.300", "#252628")

	const textColor = useTextColor();
    const pillButtonBg = usePillButtonBg;
	const pillButtonBgSelected = usePillButtonBgSelected;

	return (
		<Box>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%"></Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							1
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_profile.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Update Personal Profile
					</Text>
					<Text
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						You can add your personal information, headshot and can change login password.
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
			</Flex>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_biz_profile.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Update Business Profile
					</Text>
					<Text
						pl={10}
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						<ul>
							<li>Here you can add more details about your business.</li>
							<li>You can also add business domain i.e. acme.ourplatform.com where acme is your subdomain.</li>
							<li>You can also update your business logo, background image to your login and register page.</li>
						</ul>
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							2
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%"></Box>
			</Flex>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%"></Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							3
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_team_config.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Team Roles and Configuration 
					</Text>
					<Text
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						In this section you can add various roles for your team as these roles will be added to the team members you will add in next step.
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
			</Flex>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_add_teammembers.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Add & Manage Team Members
					</Text>
					<Text
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						In this section you will add various team members who will be able to perform most of the
						functions available here because some functions which are only for administrators. 
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							4
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%"></Box>
			</Flex>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%"></Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							5
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_add_customer.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Add Your Business Customers
					</Text>
					<Text
						pl={10}
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						<ul>
							<li>As language service provider you can add various customers here in details.</li>
							<li>Once these customers are added, you can assign various LingoLinks and LingoCards to these customers.</li>
						</ul>
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
			</Flex>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_add_lingocard.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Add LingoCard and Assign to Customers
					</Text>
					<Text
						ml={10}
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						<ul>
							<li>In this section you can first add a LingoCard with various configurations.</li>
							<li>After LingoCard is ready, you can assign a LingoCard to specific customer.</li>
							<li>Once card is only assigned to only one customers.</li>
						</ul>
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							6
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%"></Box>
			</Flex>
			<Flex justifyContent={"space-between"} mb="3">
				<Box w="50%"></Box>
				<Flex direction="column" alignItems="center" w="10%">
					<Flex
						alignItems="center"
						justifyContent="center"
						h="50px"
						w="50px"
						textAlign="center"
						borderRadius="50%"
						bgColor={bgColor}
					>
						<Text fontWeight="500" fontSize="18px">
							7
						</Text>
					</Flex>
					<Flex
						alignItems="center"
						justifyContent="center"
						width="100%"
						position="relative"
						h="90%"
					>
						<Divider
							orientation="vertical"
							borderColor="gray.300"
							borderRightWidth="1px"
							height="90%"
							position="absolute"
							left="50%"
							transform="translateX(-50%)"
							borderStyle="dotted"
						/>
					</Flex>
				</Flex>
				<Box w="50%">
					<Box mx="auto">
						<FullScreenImage 
							imageValue={'https://lingoletpub.s3.us-west-1.amazonaws.com/open/ll_add_lingolink.png'} 
							rounded={'md'}/>
					</Box>
					<Text fontSize={"1.5rem"} my="1" fontWeight={"bold"}>
						Add LingoLink and Assign to Customers
					</Text>
					<Text
						pl={10}
						fontSize={"1rem"}
						my="1"
						fontWeight={"600"}
						color="gray.400"
					>
						<ul>
							<li>In this section first you will create a LingoLink with various configurations.</li>
							<li>You can also test LingoLink in this section after it is active.</li>
							<li>Once LingoLink is ready you can assign it to a customer who can use it in any device</li>
							<li>You can also perform full scale LingoLink billing management in this section.</li>
						</ul>
					</Text>
					<Box my="1" pl={2}>
						<Button
							bg={pillButtonBg}
							rounded={"md"}
							size={"md"}
							_hover={{bg: pillButtonBgSelected}}>
							Configure
						</Button>
					</Box>
				</Box>
			</Flex>
		</Box>
	)
}

export default GettingStartedStatus
