import * as actionType from "../actionTypes";

export const setTopNotificationMessage = (value) => {
  return {
    type: actionType.SET_NOTIFICATION_MESSAGE,
    value: value,
  };
};

export const setTopNotoficationStatusColor = (value) => {
  return {
    type: actionType.SET_NOTIFICATION_STATUS_COLOR,
    value: value,
  };
};

export const setBusinessInfoStatus = (value) => {
  return {
    type: actionType.SET_BUSINESS_INFO_STATUS,
    value: value,
  };
};

export const setExpandMinMenu = (value) => {
  return {
    type: actionType.SET_EXPAND_MIN_SIDEMENU,
    value: value,
  };
};

export const setBannerFlag = (value) => {
  return {
    type: actionType.SET_BANNER_LOGO,
    value: value,
  };
};

export const setProfileLogoFlag = (value) => {
  return {
    type: actionType.SET_PROFILE_LOGO,
    value: value,
  };
};

export const setWizardLevel = (value) => {
  return {
    type: actionType.SET_BUSINESS_WIZARD_STEPPER,
    value: value,
  };
};

export const setJobUUId = (value) => {
  return {
    type: actionType.SET_JOB_UUID,
    value: value,
  };
};

export const setJobWizardStatusLevel = (value) => {
  return {
    type: actionType.SET_JOB_WIZARD_STATUS,
    value: value,
  };
};

export const setCustomBreadcumArr = (value) => {
  return {
    type: actionType.CUSTOM_BREADCRUM,
    value: value,
  };
};

export const setAccountUploadFlag = (value) => {
  return {
    type: actionType.SET_ACCOUNT_UPLOAD_FLAG,
    value: value,
  };
};

export const setParentLogo = (value) => {
  return {
    type: actionType.SET_PARENT_LOGO,
    value: value,
  };
};


export const setHomeRouteFlag = (value) => {
  return {
    type: actionType.SET_HOME_ROUTE,
    value: value,
  };
};