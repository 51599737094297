import React, { useEffect, useState } from 'react'
import {
    Box,
    Image,
    Button,
    Spacer, Avatar, Text, Flex, Heading, useToast
} from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { BiDotsHorizontal } from "react-icons/bi";

// Component Import
import Card from "../../../../components/card/Card";
import AccountUpload from './AccountUpload';
import { useBrandBg, useGreenBg, useRedBg, useBlackWhiteBg } from '../../../../theme/globalColorTheme';

// Component
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/Basic_AIIcon.png";
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";

import { toastFunctionToaster } from '../../../../utils/toastFunction';

// Api
import { saveAccountAppInfo } from '../../../../services/businessServices';

function AccountDetails(props) {
    const toast = useToast()
    const brandBg = useBrandBg();
    const blackWhiteBg = useBlackWhiteBg();
    const sourceFeatures = ['hi', 'bi', 'pi', 'di', 'mi'];
    const [updateLoader,setUploadLoader] = useState(false)
    const [selectedFeature, setSelectedFeature] = useState([]);
    const accountInfo = props.accountDetails ? props.accountDetails : null;
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

    useEffect(() => {
        setSelectedFeature(props?.accountDetails[0]?.link_apps?props?.accountDetails[0]?.link_apps.split(','):[])
    },[props?.accountDetails])

    const renderSelectedAccountDetails =
        accountInfo !== null && accountInfo?.length > 0 ? (
            accountInfo?.map((accountData, index) => (
                <Flex key={index} w={"100%"} rounded={"md"} direction={"column"} p={1} borderWidth={"0.05px"} >
                    <Flex w={"100%"} justifyContent={'space-between'} alignItems="center" p="5px">
                        <Box minW={"100px"}>
                            <Avatar h={100} w={100} src={accountData?.link_account_avatar} />
                        </Box>
                        {accountData?.link_account_logo
                            ? <Image src={accountData?.link_account_logo} w={100} h={100} /> : <Text fontSize={'xs'} fontWeight={'500'}>No logo image</Text>}
                    </Flex>
                    <Flex w={'100%'}>
                        <Flex w={'100%'} direction={"column"}>
                            <Text mt={5} fontWeight={600} fontSize={"lg"}>
                                Account Details
                            </Text>
                            <Text>Account Name:
                                <Text as="span" fontWeight={'600'} ml="3">{accountData?.link_account_name}</Text>
                            </Text>
                            <Text>Account Title:
                                <Text as="span" fontWeight={'600'} ml="3">{accountData?.link_account_title}</Text>
                            </Text>
                            <Text>Account Type:
                                <Text as="span" fontWeight={'600'} ml="3">{accountData?.link_account_type}</Text>
                            </Text>
                            <Text>Account Details:
                                <Text as="span" fontWeight={'600'} ml="3">{accountData?.link_account_details}</Text>
                            </Text>
                        </Flex>
                        <Box w={'200px'} align={'end'} direction={'column'}>
                            <Button size={'sm'}>
                                View Account Design
                            </Button>
                            <Box w={'100%'} mt={1} align={'end'}>
                                {accountData?.link_account_background
                                ? <Image src={accountData?.link_account_background} w={100} h={100} /> 
                                : <Text fontSize={'xs'}>No background image</Text>}
                            </Box>
                        </Box>
                    </Flex>
                </Flex>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to get selected account info...
                </Heading>
            </Flex>
        );
    
    const removeFeature = (value) => {
        setSelectedFeature((prevItems) => {
            const updatedItems = [...prevItems];
            const index = updatedItems.indexOf(value);
            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push(value);
            }
            return updatedItems;
        });
    };

    const addFeature = (value) => {
        setSelectedFeature((prevItems) => {
            const updatedItems = [...prevItems];
            const index = updatedItems.indexOf(value);
            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push(value);
            }
            return updatedItems;
        });
    };

    const renderLinkFeature = () => {
        return selectedFeature?.map((featureName, index) => (
            <Image onClick={()=>{removeFeature(featureName)}} cursor={'pointer'} key={index} mr="2" borderRadius="10px" h="150px" w="150px" src={renderFeatureImage(featureName)} />
        ))
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		} else if (name === "pi") {
			return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  
        else return;
	};

    const renderAppsSelection = (accountObj) => {
        return(
            <Flex w={'100%'} direction={'column'} mt="4" overflowY={'scroll'} h="300px"> 
                <Card w={'100%'} direction={'column'} rounded={'lg'} p={2}>
                    <Text fontWeight='600'>Available LingoLink Apps</Text>
                    {selectedFeature?.length > 0
                    ?<Flex w={'100%'} justifyContent={'space-between'}>
                      <Flex>{renderLinkFeature()}</Flex>
                        <Box w="200px" h="150px" verticalAlign={'center'}>
                            <Button size={'sm'} bg={'blue.100'} color="gray.600" onClick={()=>{updateAccountApp()}} isDisabled={updateLoader ? true : false} isLoading={updateLoader ? true : false}>
                                Update Account Apps
                            </Button>
                        </Box>
                    </Flex>
                    :<Flex w={'100%'} mt={2}>
                        <Text p={2} bg={'red.100'}>You do not have any LingoLink applications set for this account.</Text>
                    </Flex>}
                </Card>
                <Card w={'100%'} direction={'column'} mt={1}  rounded={'lg'} p={2}>
                    <Text>Available LingoLink Apps</Text>
                    <Text fontSize={'xs'}>Please select an application to assign with this accounts.</Text>
                    {sourceFeatures?.length > 0
                    ?<Flex w={'100%'} mt={2}>
                       <Flex w={'100%'} gap={2}>
                            <Box w="20%" display={selectedFeature.includes('hi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('hi')}>
                                <Image h="150px" w="100%" src={HumanAIBg} />
                            </Box>
                            <Box w="20%" display={selectedFeature.includes('bi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('bi')}>
                                <Image h="150px" w="100%" src={BasicAIBg} />
                            </Box>
                            <Box w="20%" display={selectedFeature.includes('pi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('pi')}>
                                <Image h="150px" w="100%" src={PremiumAIBg} />
                            </Box>
                            <Box w="20%" display={selectedFeature.includes('di') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('di')}>
                                <Image h="150px" w="100%" src={DocumentAiLightBg} />
                            </Box>
                            <Box w="20%" display={selectedFeature.includes('mi') ? 'none' : 'block'} cursor="pointer" onClick={() => addFeature('mi')}>
                                <Image h="150px" w="100%" src={MultichannelBg} />
                            </Box>
                        </Flex>
                    </Flex>:null}
                </Card>
            </Flex>
        )
    }

    // Todo:Update Selected App For Account
    const updateAccountApp = () => {
        setUploadLoader(true)
        let obj = {
            "link_account_uuid": accountInfo[0]?.link_account_uuid,
            "link_apps": selectedFeature?.length ?  selectedFeature.join(',') : []
        }
        saveAccountAppInfo(obj).then((res)=>{
            setUploadLoader(false)
            if (res?.result) {
                toast(toastFunctionToaster(res.message, "success"));
                props?.renderFeatureApp('render')
            } else {
                toast(toastFunctionToaster(res.message, "error"));
            }
        }).catch((err)=>{
            if(err) {
                setUploadLoader(false)
                toast(toastFunctionToaster('Something went wrong!!', "error"));
            }
        })
    }

    return (
        <Card w={"100%"} rounded={"md"} p={1}>
            <Flex h="75vh">
                <Box
                    w={!configurationCollapseFlag ? "95%" : "80%"}
                    bg={brandBg}
                    p="3"
                    borderRadius={"10px"}
                    overflow="hidden">
                    {accountInfo != null && accountInfo?.length > 0
                        ? <Flex w={'100%'} direction={'column'}>
                            {renderSelectedAccountDetails}
                            {renderAppsSelection(accountInfo)}
                        </Flex>
                        : <Text>Collecting...</Text>}
                </Box>
                {configurationCollapseFlag ? (
                    <Flex minW={"305px"} maxW={"605px"}>
                        <Box
                            minW="300px"
                            maxW={"300px"}
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2">
                            <Text fontWeight={'500'}>Upload Account Details</Text>
                            <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%" bg={blackWhiteBg} borderRadius={'10px'} mt="3" >
                                {accountInfo != null && accountInfo?.length > 0
                                    ? <AccountUpload accountDetails={accountInfo[0]} />
                                    : null}
                            </Box>
                        </Box>
                    </Flex>
                ) : (
                    <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                        <Box w={"100%"} h="100%">
                            <AiFillLeftCircle
                                cursor="pointer"
                                onClick={() => {
                                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                                }}
                                size="20px"
                            />
                            <Text
                                fontSize="16px"
                                fontWeight={'bold'}
                                mt="2"
                                css={{
                                    writingMode: "vertical-lr",
                                    textOrientation: "mixed",
                                }}>
                                Account Upload Details
                            </Text>
                        </Box>
                    </Box>
                )}
            </Flex>
        </Card>
    )
}

export default AccountDetails