import React, { useState } from 'react'
import { Box, Avatar, Text, Flex, Heading } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Import
import Card from "../../../components/card/Card";
import LocationUpload from './LocationUpload';
import { useBrandBg, useBlackWhiteBg } from '../../../theme/globalColorTheme';

function LocationDetails(props) {
    const brandBg = useBrandBg();
    const blackWhiteBg = useBlackWhiteBg();
    const locationInfo = props.locationDetails ? props.locationDetails : null;
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);

    const renderSelectedLocationInfo =
        Object.keys(locationInfo)?.length > 0 ? (
            <Flex w={"100%"} rounded={"md"} direction={"column"} p={1} borderWidth={"0.05px"}>
                <Box minW={"100px"}>
                    <Avatar h={100} w={100} src={locationInfo?.link_location_avatar} />
                </Box>
                <Text mt={5} fontWeight={600} fontSize={"xl"}>
                    {locationInfo?.subLoc ? 'Sub' : ''} Location Details
                </Text>
                <Text>Location Name:
                    <Text as="span" fontWeight={'600'} ml="3">{locationInfo?.link_location_name}</Text>
                </Text>
                <Text>Location Title:
                    <Text as="span" fontWeight={'600'} ml="3">{locationInfo?.link_location_title}</Text>
                </Text>
                <Text>Phone Number:
                    <Text as="span" fontWeight={'600'} ml="3">{locationInfo?.link_location_phone}</Text>
                </Text>
                <Text>Address:
                    <Text as="span" fontWeight={'600'} ml="3">{`${locationInfo?.link_location_address1},`}
                        {`${locationInfo?.link_location_address2},`}{`${locationInfo?.link_location_country},`}{`${locationInfo?.link_location_state},`}{`${locationInfo?.link_location_city},`}{locationInfo?.link_location_zip}</Text>
                </Text>
            </Flex>

        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"}>
                    Unable to get info...
                </Heading>
            </Flex>
        );

    return (
        <Card w={"100%"} rounded={"md"} p={1}>
            <Flex h="75vh">
                <Box
                    w={!configurationCollapseFlag ? "95%" : "80%"}
                    bg={brandBg}
                    rounded={'lg'}
                    p="1"
                    borderRadius={"10px"}
                    overflow="hidden">
                        <Card w={'100%'} rounded={'lg'} p={2}>
                            {Object?.keys(locationInfo)?.length > 0
                                ? <Flex w={'100%'} direction={'column'}>{renderSelectedLocationInfo}</Flex>
                                : <Text>Collecting...</Text>}
                        </Card>
                        <Card w={'100%'} rounded={'lg'} p={2} mt={1}>
                            <Text>Supported LingoLink Apps</Text>
                            <Text>App Icons Here...</Text>
                        </Card>
                </Box>
                {configurationCollapseFlag ? (
                    <Flex minW={"305px"} maxW={"605px"}>
                        <Box
                            minW="300px"
                            maxW={"300px"}
                            position="relative"
                            p="3"
                            borderRadius="15px"
                            bg={brandBg}
                            ml="2">
                            <Text fontWeight={'500'}>Upload Location Details</Text>
                            <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(!configurationCollapseFlag);
                                    }}
                                />
                            </Box>
                            <Box w="100%" h="95%" bg={blackWhiteBg} borderRadius={'10px'} mt="3" >
                                {Object.keys(locationInfo)?.length > 0
                                    ? <LocationUpload locationInfo={locationInfo} />
                                    : null}
                            </Box>
                        </Box>
                    </Flex>
                ) : (
                    <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                        <Box w={"100%"} h="100%">
                            <AiFillLeftCircle
                                cursor="pointer"
                                onClick={() => {
                                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                                }}
                                size="20px"
                            />
                            <Text
                                fontSize="16px"
                                fontWeight={'bold'}
                                mt="2"
                                css={{
                                    writingMode: "vertical-lr",
                                    textOrientation: "mixed",
                                }}>
                                Upload Location Details
                            </Text>
                        </Box>
                    </Box>
                )}
            </Flex>
        </Card>
    )
}

export default LocationDetails