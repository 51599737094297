import React, { useEffect, useState } from "react";
import { Box, Progress, Text, Flex, Spinner, Tooltip } from "@chakra-ui/react";

import PieChart from "../../components/charts/PieChart";

import { getSubDbClientEventsCount } from "../../services/accountServices";

function SubscriberInfoDash() {
	const [eventStats, setEventStats] = useState([]);
	const [loading, isLoading] = useState(false);

	//   Todo:Fetch all projects
	useEffect(() => {
		collectEventsCount();
	}, []);

	const collectEventsCount = () => {
		isLoading(true);
		let paramObj = {
			duration: "all",
		};
		getSubDbClientEventsCount(paramObj)
			.then(res => {
				isLoading(false);
				if (res?.data?.length > 0) {
					isLoading(false);
					setEventStats(res?.data[0]?.events_detail);
				} else {
					isLoading(false);
					setEventStats([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	const pieChartData = [25, 65];

	const pieChartOptions = {
		labels: ["Min", "Max"],
		colors: ["red", "blue"],
		chart: {
			width: "300px",
		},
		states: {
			hover: {
				filter: {
					type: "none",
				},
			},
		},
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: false,
		},
		hover: { mode: null },
		plotOptions: {
			donut: {
				expandOnClick: false,
				donut: {
					labels: {
						show: false,
					},
					borderRadius: 0,
				},
			},
		},
		fill: {
			colors: ["red", "blue"],
		},
		tooltip: {
			enabled: true,
			theme: "dark",
		},
	};

	const newOptions = {
		...pieChartOptions,
		colors: ["var(--chakra-colors-blue-500)", "var(--chakra-colors-red-500)"],
		fill: {
			colors: ["var(--chakra-colors-blue-500)", "var(--chakra-colors-red-500)"],
		},
	};

	// Remove prefix from keys and prepare the data array
	const formattedData = Object.keys(eventStats).map(key => {
		const formattedKey = key.replace("Subscriber_Link_", "").replace("SubscriberLink_", "");
		return { key: formattedKey, value: eventStats[key] };
	});

	return (
		<Box mt="3">
			<Flex w="100%" justifyContent={"space-between"} borderWidth="1px">
				<Box w="100%" borderRightWidth={"1px"} textAlign={"center"}>
					<Text p="10px" borderBottomWidth={"1px"} fontWeight={"bold"} fontSize={"18px"}>
						Human Interpretation
					</Text>
					<Flex w="100%" justifyContent={"space-between"}>
						<Box w="50%" borderRightWidth={"1px"}>
							<Text borderBottomWidth={"1px"} p="5px">
								Past Month
							</Text>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<PieChart h="100%" w="100%" chartData={pieChartData} chartOptions={newOptions} />
							</Flex>
						</Box>
						<Box w="50%">
							<Text borderBottomWidth={"1px"} p="5px">
								This Month
							</Text>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<PieChart h="100%" w="100%" chartData={pieChartData} chartOptions={newOptions} />
							</Flex>
						</Box>
					</Flex>
				</Box>
				<Box w="100%" borderRightWidth={"1px"} textAlign={"center"}>
					<Text p="10px" borderBottomWidth={"1px"} fontWeight={"bold"} fontSize={"18px"}>
						Basic AI
					</Text>
					<Flex w="100%" justifyContent={"space-between"}>
						<Box w="50%" borderRightWidth={"1px"}>
							<Text borderBottomWidth={"1px"} p="5px">
								Past Month
							</Text>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<PieChart h="100%" w="100%" chartData={pieChartData} chartOptions={newOptions} />
							</Flex>
						</Box>
						<Box w="50%">
							<Text borderBottomWidth={"1px"} p="5px">
								This Month
							</Text>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<PieChart h="100%" w="100%" chartData={pieChartData} chartOptions={newOptions} />
							</Flex>
						</Box>
					</Flex>
				</Box>
				<Box w="100%" borderRightWidth={"1px"} textAlign={"center"}>
					<Text p="10px" borderBottomWidth={"1px"} fontWeight={"bold"} fontSize={"18px"}>
						Premium AI
					</Text>
					<Flex w="100%" justifyContent={"space-between"}>
						<Box w="50%" borderRightWidth={"1px"}>
							<Text borderBottomWidth={"1px"} p="5px">
								Premium AI
							</Text>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<PieChart h="100%" w="100%" chartData={pieChartData} chartOptions={newOptions} />
							</Flex>
						</Box>
						<Box w="50%">
							<Text borderBottomWidth={"1px"} p="5px">
								This Month
							</Text>
							<Flex alignItems={"center"} justifyContent={"center"}>
								<PieChart h="100%" w="100%" chartData={pieChartData} chartOptions={newOptions} />
							</Flex>
						</Box>
					</Flex>
				</Box>
			</Flex>

			<Box overflowY={"auto"} h="290px"
				css={{
					"&::-webkit-scrollbar": {
						width: "4px",
					},
					"&::-webkit-scrollbar-track": {
						width: "6px",
					},
					"&::-webkit-scrollbar-thumb": {
						background: "transparent",
						borderRadius: "24px",
					},
				}}>
				<Text my="3" align="center" fontWeight={"bold"} fontSize={"18px"}>
					Your activity this month
				</Text>
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="30vh">
						<Spinner size="xl" color="#000" />
					</Flex>
				) : (
					<Box borderWidth="1px" p="10px">
						{formattedData.map((data, index) => (
							<Flex key={index} w="100%" alignItems={"center"} justifyContent={"space-between"} mb="4">
								<Text mr="3" fontSize={"16px"} fontWeight={"500"} textTransform={"capitalize"}>
									{data.key} Link
								</Text>
								<Tooltip label={`Subscriber ${data.key} Link: ${data.value}`} aria-label={`Value: ${data.value}`}>
									<Progress cursor={"pointer"} colorScheme="green" height="32px" value={data.value} borderRadius={0} w="80%" />
								</Tooltip>
							</Flex>
						))}
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default SubscriberInfoDash;
