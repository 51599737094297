// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

// Component Imports
import TabOneSubHelper from "./TabOneSubHelper";
import {useBrandBg} from "../../../../theme/globalColorTheme";

function TabOneHelper(props) {
	const brandBg = useBrandBg();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] =
		useState(false);

	return (
		<Flex h="70vh">
			<Box
				w={!configurationCollapseFlag ? "95%" : "70%"}
				bg={brandBg}
				p="3"
				borderRadius={"10px"}
				overflow="hidden"
				overflowY="scroll"
			>
				<Text>{props?.text?props?.text : 'Help Content'}</Text>
			</Box>
			{configurationCollapseFlag ? (
				<Box
					w="30%"
					position="relative"
					p="3"
					borderRadius="15px"
					bg={brandBg}
					ml="2"
				>
					<Box
						position="absolute"
						top="0"
						right="0"
						mr="2"
						mt="3"
						zIndex="999"
					>
						<AiFillRightCircle
							cursor="pointer"
							size="20px"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
						/>
					</Box>
					<Box w="100%" h="95%" bg={brandBg}>
						<TabOneSubHelper />
					</Box>
				</Box>
			) : (
				<Box
					w="5%"
					p="3"
					borderRadius="10px"
					bg={brandBg}
					h="100%"
					ml="2"
					pl="5"
				>
					<Box w={"100%"} h="100%">
						<AiFillLeftCircle
							cursor="pointer"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
							size="20px"
						/>
						<Text
							fontSize="16px"
							mt="2"
							css={{
								writingMode: "vertical-lr",
								textOrientation: "mixed",
							}}
						>
							Expand Help Settings
						</Text>
					</Box>
				</Box>
			)}
		</Flex>
	);
}

export default TabOneHelper;
