import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import store from "./store/index";
// import { Elements } from '@stripe/react-stripe-js';
import { ChakraProvider } from "@chakra-ui/react";
import initialTheme from "./theme/theme"; //  { themeGreen }
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
// This layout is related to the new Horizon UI based design
// import MainRouteComponent from "./routes/mainRoutes";
import OthersError from "./views/admin/main/others/404";
import { Elements } from "@stripe/react-stripe-js";
import "./assets/css/App.css";

import ModuleWidget from "./komponents/common/components/ModuleWidget";
import ChangeDummyPassword from "./komponents/userauth/changepassword/ChangeDummyPassword";
import ForgotPassword from "./views/auth/forgotPassword/ForgotPasswordCentered";
import CookiePolicy from "./komponents/landinghome/CookiePolicy";
import TermsOfUse from "./komponents/landinghome/TermsOfUse";
import EULA from "./komponents/landinghome/EULA";
import SignInCentered from "./komponents/userauth/signIn/SignInCentered";
import PrivacyPolicy from "./komponents/landinghome/PrivacyPolicy";
import DMCAPolicy from "./komponents/landinghome/DMCAPolicy";
import GPLCompliance from "./komponents/landinghome/GPLCompliance";
import VerificationCentered from "./komponents/userauth/verification/VerificationCentered";
import RegisterBusinessUser from "./komponents/userauth/register/RegisterBizUser";
import LandingPage from "./komponents/landinghome/LandingPage";
import LingoletOneMetaDemo from "./komponents/admin/demo/LingoletOneMetaDemo";

// Layout and Menu
import AdminLayouts from "./routes/AdminLayouts";
import BusinessLayouts from "./routes/BusinessLayouts";
import LanguageAssociateLayouts from "./routes/LanguageAssociateLayouts";
import AccountLayouts from "./routes/AccountLayouts";
import SubscriberLayouts from "./routes/SubscriberLayouts";

// Other Pages
import HybridAppPage from "./komponents/landinghome/HybridAppPage";
import TeamPage from "./komponents/landinghome/Team";
import DummyAppDemo from "./komponents/admin/demo/DummyAppDemo";
import EventFeedback from "./komponents/admin/demo/EventFeedback";
import WorkflowError from "./komponents/landinghome/WorkflowError";

// LingoLink Home
import DynamicLauncher from "./komponents/DynamicLink/DynamicLauncher";
import DynamicHome from "./komponents/DynamicLink/DynamicHome";
import LinkManagVerify from "./komponents/DynamicLink/management/LinkManageVerify";

// Media Kit
import CESMediaKit from "./komponents/mediakit/CESMediaKit";
import HomeLandingPage from "./komponents/landinghome/HomeLandingPage";
import Countdown from "./komponents/landinghome/Countdown";
import LspLogin from "./komponents/userauth/signIn/LspLogin";


export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
  const stripePromise = loadStripe(publishKey);

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ChakraProvider theme={currentTheme}>
          {/* <MainRouteComponent /> */}
          {/* { HorizonMenuComp } */}
          <Routes>
            <Route
              path="admin/*"
              element={
                <AdminLayouts theme={currentTheme} setTheme={setCurrentTheme} />
              }
            />
            <Route
              path="business/*"
              element={
                <BusinessLayouts
                  theme={currentTheme}
                  setTheme={setCurrentTheme}
                />
              }
            ></Route>
            <Route
              path="languageassociate/*"
              element={
                <LanguageAssociateLayouts
                  theme={currentTheme}
                  setTheme={setCurrentTheme}
                />
              }
            />
            <Route
              path="account/*"
              element={
                <AccountLayouts
                  theme={currentTheme}
                  setTheme={setCurrentTheme}
                />
              }
            />
            <Route
              path="subscriber/*"
              element={
                <SubscriberLayouts
                  theme={currentTheme}
                  setTheme={setCurrentTheme}
                />
              }
            />
            {/* <Route path="/module" element={<ModuleWidget />} /> */}
            <Route index path="/dmca-policy" element={<DMCAPolicy />} />
            <Route index path="/cookie-policy" element={<CookiePolicy />} />
            <Route index path="/terms-of-use" element={<TermsOfUse />} />
            <Route index path="/gpl-compliance" element={<GPLCompliance />} />
            <Route index path="/eula" element={<EULA />} />
            <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cesdemo" index element={<LingoletOneMetaDemo />} />
            <Route path="/aiapp" index element={<DummyAppDemo />} />
            <Route path="/eventfeedback" index element={<EventFeedback />} />
            <Route path="/WorkflowFailed" index element={<WorkflowError />} />
            <Route path="/lingolink" index element={<DynamicLauncher />} />
            <Route path="/lingolink/apps" index element={<DynamicHome />}/>
            <Route path="/lingolink/management" index element={<LinkManagVerify />}/>

            <Route path="/hybridapp" index element={<HybridAppPage />} />
            <Route path="/team" index element={<TeamPage />} />
            <Route path="/cesmediakit" index element={<CESMediaKit />} />
            {/* <Route path="/" index element={<LandingPage />} /> */}
            <Route path="/newpage" index element={<HomeLandingPage />} />
            <Route path="*" element={<OthersError />} />

            {/* Lingolet Route */}
            <Route path="/" index element={<Countdown />} />

            {/* LspCloud Route */}
            {/* <Route path="/" element={<LspLogin />} /> */}
            <Route path="/login" element={<LspLogin />} />
            {/* <Route path="/login" element={<SignInCentered />} /> */}
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<RegisterBusinessUser />} />
            <Route path="/verifyEmail" element={<VerificationCentered />} />
            <Route path="/changeDefaultPassword" element={<ChangeDummyPassword />}/>
          </Routes>
        </ChakraProvider>
      </Provider>
    </Elements>
  );
}
